import { FC } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { Parser } from '../../utils';
import { colors } from '../../themes';
import useAppSelector from '../../hooks/useAppSelector';

interface Props {
}

const GeneralDetailsCard: FC<Props> = () => {
    const { selectedOrder } = useAppSelector(state => state.orders);

    return (
        <Paper elevation={4}>
            <Box padding='1rem' display='flex' flexDirection={{ xs: 'column', sm: 'row' }} justifyContent='space-between'>
                {
                    selectedOrder
                        ? <Box marginBottom='0.5rem'>
                            <Typography fontWeight='700'>Dirección de Envío</Typography>
                            <Box padding='1rem 2rem' borderRadius={1} sx={{ backgroundColor: colors.cardBgAlt }}>
                                <Typography>{selectedOrder.addressName}</Typography>
                                {
                                    selectedOrder.addressCompany
                                        ? <Typography>{selectedOrder.addressCompany}</Typography>
                                        : null
                                }
                                <Typography>{selectedOrder.address1}</Typography>
                                {
                                    selectedOrder.address2
                                        ? <Typography>{selectedOrder.address2}</Typography>
                                        : null
                                }
                                <Typography>{`${selectedOrder.addressCity} ${selectedOrder.addressZipcode}`}</Typography>
                                <Typography textTransform='uppercase'>
                                    {Parser.parseToMunicipality(selectedOrder.addressMunicipality!)},
                                    Baja California Sur, México
                                </Typography>
                            </Box>
                        </Box>
                        : <Skeleton variant='rectangular' width={325} height={175} />
                }
                {
                    selectedOrder
                        ? <Box  marginBottom='0.5rem'>
                            <Typography fontWeight='700'>Método de Pago</Typography>
                            <Typography marginTop='0.25rem'>
                                {selectedOrder?.paymentType}
                            </Typography>
                        </Box>
                        : <Skeleton variant='rectangular' width={200} height={50} />
                }
                {
                    selectedOrder
                        ? <Box width={{ xs: '100%', sm: '30%'}}  marginBottom='0.5rem'>
                            <Typography fontWeight='700'>Resumen del Pedido</Typography>
                            <Box display='flex' justifyContent='space-between'>
                                <Typography>Productos:</Typography>
                                <Typography>{Parser.parseToPrice(selectedOrder?.subtotal!)}</Typography>
                            </Box>
                            <Box display='flex' justifyContent='space-between'>
                                <Typography>Envío:</Typography>
                                <Typography>{Parser.parseToPrice(selectedOrder?.shippingFee!)}</Typography>
                            </Box>
                            <Box display='flex' justifyContent='space-between'>
                                <Typography>Subtotal:</Typography>
                                <Typography>{Parser.parseToPrice(selectedOrder?.total!)}</Typography>
                            </Box>
                            <Box display='flex' justifyContent='space-between' alignItems='end'>
                                <Typography width='50%' fontWeight='700'>Total (IVA incluido, en caso de ser aplicable):</Typography>
                                <Typography width='50%' fontWeight='700' textAlign='right'>{Parser.parseToPrice(selectedOrder?.total!)}</Typography>
                            </Box>
                        </Box>
                        : <Skeleton variant='rectangular' width={325} height={175} />
                }
            </Box>
        </Paper>
    );
}

export default GeneralDetailsCard;
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks';
import { ShopPageLayout } from '../components/layouts';
import Box from '@mui/material/Box';
import { fetchInvoice, setSelectedInvoice } from '../redux/slices/invoicing';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';

interface Props {
}

const OrderInvoice: FC<Props> = () => {
    const { idOrder } = useParams();
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const { loading } = useAppSelector(state => state.ui);
    const { selectedInvoice } = useAppSelector(state => state.invoicing);

    useEffect(() => {
        dispatch(fetchInvoice(idOrder ?? '', () => {
            navigate('/cuenta/pedidos', { replace: true });
        }));
    }, []);

    useEffect(() => {
        return () => {
            dispatch(setSelectedInvoice(null));
        }
    }, [])

    const goBackToOrder = () => {
        navigate(`/cuenta/pedidos/${idOrder}`)
    }

    return (
        <ShopPageLayout
            paths={[{ name: 'Mi Cuenta', path: '/cuenta' }, { name: 'Mis Pedidos', path: '/cuenta/pedidos' }, { name: 'Detalles del Pedido', path: `/cuenta/pedidos/${idOrder}` }]}
            name='Factura'
            showSkeleton
        >
            <Box marginY='1rem'>
                <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom='1rem'>
                    {
                        selectedInvoice
                            ? <Typography>
                                Pedido el {moment(selectedInvoice?.orderDate).format('DD [de] MMMM [del] YYYY')}
                            </Typography>
                            : <Skeleton variant='text' width={250} />
                    }
                    {
                        selectedInvoice
                            ? <Typography>
                                Pedido #{selectedInvoice.idOrder.toUpperCase()}
                            </Typography>
                            : <Skeleton variant='text' width={250} />
                    }
                </Box>
                <Box marginBottom='1rem'>
                    <Slide in={true} direction='right' timeout={500}>
                        <Box>
                            <Paper>
                                <Box padding='1rem'>
                                    {
                                        loading
                                            ? <Box width='100%' display='flex' justifyContent='center'>
                                                <CircularProgress />
                                            </Box>
                                            : <Box marginBottom='1rem'>
                                                <Button variant='text' onClick={goBackToOrder}>
                                                    <KeyboardBackspaceOutlinedIcon fontSize='small' sx={{ marginRight: '0.5rem' }} />
                                                    Regresar a detalles
                                                </Button>
                                            </Box>
                                    }
                                    <Grid container spacing={3}>
                                        {
                                            selectedInvoice && selectedInvoice.pdf
                                                ? <Grid item xs={12} md={6}>
                                                    <Typography fontWeight='700' marginBottom='1rem'>
                                                        Archivo PDF
                                                    </Typography>
                                                    <Button href={selectedInvoice.pdf} variant='contained' fullWidth>
                                                        Descargar PDF
                                                    </Button>
                                                </Grid>
                                                : null
                                        }
                                        {
                                            selectedInvoice && selectedInvoice.xml
                                                ? <Grid item xs={12} md={6}>
                                                    <Typography fontWeight='700' marginBottom='1rem'>
                                                        Archivo XML
                                                    </Typography>
                                                    <Button href={selectedInvoice.xml} variant='contained' fullWidth>
                                                        Descargar XML
                                                    </Button>
                                                </Grid>
                                                : null
                                        }

                                    </Grid>
                                </Box>
                            </Paper>
                        </Box>
                    </Slide>
                </Box>
            </Box>
        </ShopPageLayout>
    );
}

export default OrderInvoice;
import { CSSProperties, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { colors } from '../../themes';
import { useBarsData } from '../../hooks';
import { SearchBar } from '../ui';

interface Props {
    open: boolean;
    onClose: VoidFunction;
}

const drawerStyles: CSSProperties = {
    backdropFilter: 'blur(4px)',
    transition: 'all 0.5s ease-out'
};

const MobileNavigationDrawer: FC<Props> = ({ open, onClose }) => {
    const navigate = useNavigate();

    const { navBarMobilePages } = useBarsData();

    const handleItemClick = (path: string) => {
        navigate(path);
        onClose();
    }

    return (
        <Drawer anchor='top' open={open} onClose={onClose} sx={drawerStyles}>
            <Box paddingTop='4.5rem'>
                <Box paddingX='0.5rem'>
                    <SearchBar />
                </Box>
                <List>
                    {
                        navBarMobilePages.map(({ title, path }, index) =>
                            <ListItem key={`drawer_page_${index}`} disablePadding>
                                <ListItemButton onClick={() => handleItemClick(path)}>
                                    <ListItemText
                                        primary={title}
                                        primaryTypographyProps={{
                                            component: 'p',
                                            color: colors.black,
                                            variant: 'h6',
                                            fontWeight: '400',
                                        }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        )
                    }
                </List>
            </Box>
        </Drawer>
    );
}

export default MobileNavigationDrawer;
import { FC, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { getShippingFee, setShipping, setShippingAddress, setStep } from '../../redux/slices/checkout';
import { getAddresses } from '../../redux/slices/account';
import ShippingAddressSelector from './ShippingAddressSelector';
import { AddressCard } from '../account';

interface Props {
}

const ShippingSettings: FC<Props> = () => {
    const dispatch = useAppDispatch();
    
    const { shippingAddress, subtotal } = useAppSelector(state => state.checkout);

    useEffect(() => {
        dispatch(getAddresses());
    }, []);

    useEffect(() => {
        if ((shippingAddress && subtotal < 3000) || (shippingAddress && shippingAddress.municipality === 1 && subtotal < 1000)) {
            dispatch(getShippingFee());
        } else {
            dispatch(setShipping(null));
        }
    }, [shippingAddress])

    const goBackToCheckoutCart = () => {
        dispatch(setStep(0));
        dispatch(setShippingAddress(null));
        dispatch(setShipping(null));
    }

    return (
        <Slide in={true} direction='right' timeout={500}>
            <Box>
                <Paper elevation={3}>
                    <Box padding='1rem'>
                        <Box display='flex' alignItems='center' paddingBottom='0.25rem'>
                            <Typography marginRight='0.5rem'>
                                Datos de Envío
                            </Typography>
                        </Box>
                        <Divider />
                        <Box marginTop='0.75rem'>
                            <Alert severity='info'>
                            Costos logísticos adicionales: Al elegir una zona de entrega foránea, los precios pueden aumentar debido a los gastos de transporte y gestión adicional. ¡Aseguramos la entrega segura y eficiente de tus productos dondequiera que te encuentres!
                            </Alert>
                        </Box>
                        {
                            shippingAddress
                                ? <Box marginTop='1rem'>
                                    <AddressCard data={shippingAddress} />
                                    <Box marginTop='0.5rem' display='flex' justifyContent='end'>
                                        <Button variant='contained' onClick={() => dispatch(setShippingAddress(null))}>
                                            Cambiar dirección de Envío
                                        </Button>
                                    </Box>
                                </Box>
                                : <ShippingAddressSelector />
                        }
                        
                    </Box>
                </Paper>
                <Box marginTop='1rem' display='flex'>
                    <Paper elevation={3}>
                        <Button variant='outlined' onClick={goBackToCheckoutCart}>
                            <KeyboardBackspaceIcon fontSize='small' />
                            Volver al Carrito
                        </Button>
                    </Paper>
                </Box>
            </Box>
        </Slide>
    );
}

export default ShippingSettings;
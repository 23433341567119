import { FC } from 'react';
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';

import { useAppSelector } from '../hooks';
import { LoadingCard } from '../components';

interface Props {
}

const PublicRoute: FC<Props> = () => {
    const [searchParams] = useSearchParams();

    const { user } = useAppSelector(state => state.session);

    if (user === undefined) {
        return <LoadingCard message='Verificando datos de sesión' />
    }

    return (
        <>
            {
                user
                    ? searchParams.get('prev')
                        ? <Navigate to={searchParams.get('prev') as string} replace />
                        : <Navigate to='/' replace />
                    : searchParams.get('prev') === '/cuenta/eliminacion' 
                        ? <Navigate to='/' replace />
                        : <Outlet />
            }
        </>
    );
}

export default PublicRoute;
import { FC, useRef } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';

import { Store } from '../../interfaces';
import { useOnView } from '../../hooks';
import Skeleton from '@mui/material/Skeleton';


interface Props {
    data: Store | null;
    direction?: 'left' | 'right';
    container: Element | null;
}

const HomeStoreCard: FC<Props> = ({ direction = 'right', data, container }) => {
    const ref = useRef<HTMLElement>(null)
    const isVisible = useOnView(ref);

    return (
        <Box ref={ref} width='100%'>
            <Slide direction={direction} in={isVisible} timeout={500} container={container}>
                <Card sx={{ backgroundColor: data?.backgroundColor, color: data?.color, width: '100%' }} elevation={6}>
                    <CardContent>
                        {
                            data
                                ? <Box display='flex' justifyContent='space-between'>
                                    <Typography variant='h5' component='h3' textTransform='capitalize'>
                                        {data.name}
                                    </Typography>
                                    {
                                        data.image !== 'N/A'
                                         ? <img src={data.image} style={{ width: '3rem', objectFit: 'contain' }}  />
                                         : null
                                    }
                                </Box>
                                : <Skeleton variant='rectangular' width='45%' height={30} />
                        }
                        <Box marginTop='1rem' >
                            {
                                data
                                    ? <Typography> {data.description}  </Typography>
                                    : <Skeleton variant='rectangular' width='100%' height={100} />
                            }
                        </Box>
                    </CardContent>
                </Card>
            </Slide>
        </Box>
    );
}

export default HomeStoreCard;
import { SxProps } from '@mui/material/styles';

const useDesignConstants = () => {
    const HomeContent: SxProps = {
        width: {
            xs: '92.5%',
            lg: '80%',
        },
        margin: '0 auto',
    };

    const ShopContent: SxProps = {
        width: {
            xs: '95%',
            sm: '90%',
        },
        margin: '0 auto',
    }

    const PaperContent: SxProps = {
        width: {
            xs: '92.5%',
            sm: '90%',
            md: '70%',
            lg: '60%'
        },
        borderRadius: 2
    }

    return {
        HomeContent,
        ShopContent,
        PaperContent,
    };
}

export default useDesignConstants;
import { FC, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import { colors } from '../../themes';
import { useAppSelector } from '../../hooks';
import { ProductVariant } from '../../interfaces';

interface Props {
    variant: ProductVariant | null;
}

const ImageDisplay: FC<Props> = ({ variant }) => {
    const { product } = useAppSelector(state => state.product);

    const [image, setImage] = useState('');

    useEffect(() => {
        if (product)
            setImage(product.image);
    }, [product]);

    useEffect(() => {
        if (variant && variant.images.length > 0) {
            setImage(variant.images[0])
        }
    }, [variant])

    const images = useMemo(() => {
        if (!product) return [];
        const allVariantImages: string[] = [];
        product.variants.forEach(({ images }) => {
            images.forEach(img => allVariantImages.push(img));
        });
        return [product.image, ...allVariantImages];
    }, [product]);

    if (!product) {
        return (
            <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }}>
                <Box width='3rem' marginRight={{ md: '1rem' }} marginBottom='0.25rem'>
                    <Skeleton variant='rectangular' width='3rem' height='3rem' />
                </Box>
                <Box flex={1}>
                    <Skeleton variant='rectangular' width='100%' height={400} />
                </Box>
            </Box>
        );
    }

    return (
        <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }}>
            <Box marginRight={{ md: '0.5rem' }} display='flex' flexDirection={{ xs: 'row', md: 'column' }}>
                {
                    images.map((img, index) =>
                        <Box key={`product_img_${index}`} width='3rem' height='3rem' marginBottom='0.25rem' marginRight={{ xs: '0.25rem', md: 0 }}>
                            <img
                                src={img}
                                alt={`${product.name} - no. ${index}`}
                                style={{
                                    border: `1px ${image === img ? colors.secondary : colors.primary} solid`,
                                    borderRadius: '5px',
                                    objectFit: 'contain',
                                    width: '100%',
                                    height: '100%',
                                    cursor: 'pointer'
                                }}
                                onClick={() => setImage(img)}
                            />
                        </Box>
                    )
                }
            </Box>
            <Box flex={1}>
                <img
                    src={image}
                    alt={product.name}
                    style={{
                        border: `1px ${colors.primary} solid`,
                        borderRadius: '5px',
                        objectFit: 'contain',
                        width: '100%',
                        maxHeight: '70vh'
                    }}
                />
            </Box>
        </Box >
    );
}

export default ImageDisplay;
import { FC, useEffect, useMemo, useRef } from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

import { GalleryColor } from '../../interfaces';
import { Formatter } from '../../utils';
import { useOnView } from '../../hooks';

interface Props {
    color: GalleryColor;
    onClick: (color: GalleryColor) => void;
}

const ColorBox: FC<Props> = ({ color, onClick }) => {
    const ref = useRef<HTMLElement>(null);

    const isVisible = useOnView(ref)

    const textColor = useMemo(() => {
        const [red, green, blue] = color.rgb.slice(1, -1).split(',').map(str => parseInt(str.trim()));
        const colorValue = Math.round(((red * 299) + (green * 587) + (blue * 114)) / 1000);
        return colorValue > 125 ? 'black' : 'white';
    }, [color]);

    return (
        <Box ref={ref} height='5rem' padding='0.35em' sx={{ backgroundColor: `rgb${color.rgb}`, cursor: 'pointer', border: '0.5px #C2C2C2 solid', borderRadius: 1 }} onClick={() => onClick(color)}>
            <Fade in={isVisible} timeout={500}>
                <Box>
                    <Typography variant='body2' color={textColor}>
                        {Formatter.capitalize(color.name)}
                    </Typography>
                    <Typography variant='body2' color={textColor}>
                        {color.code}
                    </Typography>
                </Box>
            </Fade>
        </Box>
    );
}

export default ColorBox;
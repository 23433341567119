import { FC } from 'react';
import Box from '@mui/material/Box';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { useBreakpoints } from '../../hooks';
import SearchEntry from './SearchEntry';
import { Banner } from '../../interfaces';

interface Props {
    banners: Banner[];
}

const placeholderBannerColors = ['#a4b0be', '#747d8c', '#57606f'];

const HomeCarousel: FC<Props> = ({ banners }) => {
    const { isMobile } = useBreakpoints();

    return (
        <Box>
            <Carousel autoPlay showArrows={false} showIndicators={false} showThumbs={false} showStatus={false} stopOnHover={false} interval={5000} swipeable emulateTouch infiniteLoop >
                {
                    banners.length > 0
                        ? banners.map(({ idBanner, mobileImage, desktopImage }) =>
                            <Box key={idBanner} height='calc( 80vh - 60px )' width='100%' sx={{ backgroundColor: '#57606f' }}>
                                <img
                                    src={isMobile ? mobileImage : desktopImage}
                                    alt={`Banner Image #${idBanner}`}
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                            </Box>
                        )
                        : placeholderBannerColors.map((backgroundColor, index) =>
                            <div
                                key={`banner_placeholder_${index}`}
                                style={{ backgroundColor, width: '100%', height: 'calc( 80vh - 60px )' }}
                            />
                        )
                }
            </Carousel>
            <SearchEntry />
        </Box>
    );
}

export default HomeCarousel;
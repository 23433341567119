import { FC, useState } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { useAppDispatch, useAppSelector } from '../../hooks';
import AddressCard from '../account/AddressCard';
import { UserAddress } from '../../interfaces';
import { deleteAddress } from '../../redux/slices/account';
import { LoadingOverlay, Notification } from '../ui';

interface Props {
    onAddressEdit?: (address: UserAddress) => void;
}

const AddressesList: FC<Props> = ({ onAddressEdit }) => {
    const dispatch = useAppDispatch();
    const { addresses } = useAppSelector(state => state.account);
    const { loading } = useAppSelector(state => state.ui);

    const [error, setError] = useState({
        active: false,
        message: '',
    });

    return (
        <Box>
            <LoadingOverlay open={loading} />
            <Notification
                type='error'
                open={error.active}
                onClose={() => setError({ active: false, message: '' })}
                message={error.message}
                vertical='bottom'
            />
            <Typography component='h2' variant='h5'>
                Todas las Direcciones
            </Typography>
            {
                addresses.length <= 0
                    ? <Alert severity='info' sx={{ marginBottom: '1rem' }}>
                        Aún no se ha registrado una dirección de envío. ¿Quieres añadir una nueva?
                    </Alert>
                    : addresses.map((address) =>
                        <Box key={address.idAddress} >
                            <Box width={{ xs: '90%', sm: '80%', md: '70%', lg: '50%' }}>
                                <AddressCard data={address} />
                                <Box>
                                    <Button
                                        color='error'
                                        onClick={
                                            () =>
                                                dispatch(deleteAddress(address.idAddress, undefined, () => {
                                                    setError({
                                                        active: true,
                                                        message: 'Ha ocurrido un error al intentar eliminar la dirección.',
                                                    });
                                                }))
                                        }
                                    >
                                        Eliminar
                                    </Button>
                                    <Button onClick={() => onAddressEdit ? onAddressEdit(address) : undefined}>
                                        Editar
                                    </Button>
                                </Box>
                            </Box>
                            <Divider sx={{ marginY: '1rem' }} />
                        </Box>
                    )
            }
        </Box>
    );
}

export default AddressesList;
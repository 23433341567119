import { FC } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { colors } from '../../themes';

interface Props {
    title: string;
    path: string;
}

const FooterLink: FC<Props> = ({ title, path }) => {
    return(
        <Link to={path}>
            <Typography sx={{ color: '#c2c2c2', transition: 'all ease-in 0.1s', '&:hover': { color: colors.white } }}>
                {title}
            </Typography>
        </Link>
    );
}

export default FooterLink;
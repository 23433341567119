import { FC } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import FailureSVG from '../assets/failure.svg';
import { useDesignConstants } from '../hooks';

interface Props {
}

const PaymentFailure: FC<Props> = () => {
    const { PaperContent } = useDesignConstants();

    return (
        <Box display='flex' justifyContent='center' marginTop='2.5rem'>
            <Paper sx={PaperContent} elevation={4}>
                <Box padding='2rem 5rem' display='flex' flexDirection='column' alignItems='center'>
                    <Box width={{ xs: '60%' , sm: '30%'}} margin='0 auto'>
                        <img src={FailureSVG} style={{ maxWidth: '100%' }} />
                    </Box>
                    <Typography variant='h6' component='p' marginY='1rem' fontWeight='400' textAlign='center'>
                        ¡Oops! Algo salió mal.
                    </Typography>
                    <Link to='/'>
                        <Button variant='contained'>
                            Volver al Inicio
                        </Button>
                    </Link>
                </Box>
            </Paper>
        </Box>
    );
}

export default PaymentFailure;
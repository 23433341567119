import { FC } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { useAppSelector } from '../../hooks';
import ProductCard from './ProductCard';

interface Props {
}

const ProductsList: FC<Props> = () => {
    const { cart } = useAppSelector(state => state.cart);

    return (
        <Paper elevation={4}>
            <Box padding='1rem'>
                <Box display='flex' justifyContent='end'>
                    <Typography>
                        Precio
                    </Typography>
                </Box>
                <Divider />
                {
                    cart.length > 0
                        ? cart.map((product, index) =>
                            <ProductCard key={product.variant.key + '_' + index} data={product} />
                        )
                        : <Box marginTop='1rem'>
                            <Alert severity='warning'>
                                No hay productos en el carrito de compra.
                            </Alert>
                        </Box>
                }
            </Box>
        </Paper>
    );
}

export default ProductsList;
import { FC } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { ShopPageLayout } from '../components';

interface Props {
}

const ShopInformation: FC<Props> = () => {
    return (
        <ShopPageLayout name='Información'>
            <div id='privacidad'>
                <Box marginTop='1rem' marginBottom='2.5rem'>
                    <Typography variant='h5' component='h2'>
                        Política de Privacidad
                    </Typography>
                    <Divider />
                    <Typography marginTop='1rem' textAlign='justify'>
                        Le informamos que PINTURAS FAMOSAS, S.A. de C.V. (Responsable), con
                        domicilio en Calle Mogote S/N Colonia Tabachines, La Paz, Baja California Sur. C.P.
                        23084, tratará sus datos personales para: i) Dar cumplimiento a obligaciones
                        comerciales contraídas; ii) Darlo de alta en los sistemas internos de seguimiento; iii)
                        Informarle sobre eventos, programas de lealtad, promociones de productos,
                        descuentos, capacitaciones, servicios, publicaciones y novedades relacionadas con
                        la red de productos PINTURAS FAMOSAS, S.A. de C.V.; iv) Realizar encuestas de
                        satisfacción y evaluación de la calidad de nuestro servicio; y/o v) Estudios internos
                        para el desarrollo comercial de nuevos productos y servicios de PINTURAS
                        FAMOSAS, S.A. de C.V.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        La manifestación libre y voluntaria de su información constituye su consentimiento
                        expreso para el tratamiento de sus datos personales en los términos y condiciones
                        del Aviso de Privacidad Completo PINTURAS FAMOSAS, S.A. de C.V.. Para dudas
                        y aclaraciones relacionadas con el presente aviso, por favor escríbanos a {' '}
                        <a href='mailto:famosas.contacto@pinturasfamosas.com.mx'> famosas.contacto@pinturasfamosas.com.mx
                        </a>
                    </Typography>
                </Box>
            </div>
            <div id='cancelacion'>
                <Box marginTop='1rem' marginBottom='2.5rem'>
                    <Typography variant='h5' component='h2'>
                        Política de Cancelación
                    </Typography>
                    <Divider />
                    <Typography marginTop='1rem' textAlign='justify'>
                        Agradecemos tu comprensión. Las cancelaciones de compras solo podrán realizarse dentro de las 24 horas posteriores a la compra inicial. Pasado ese período, no podremos aceptar solicitudes de cancelación debido a nuestro proceso de preparación y envío eficiente. Te recomendamos revisar cuidadosamente tus pedidos antes de confirmar la compra. ¡Estamos aquí para ayudarte en cualquier otra consulta o solicitud!
                    </Typography>
                </Box>
            </div>
            <div id='envio'>
                <Box marginTop='1rem' marginBottom='2.5rem'>
                    <Typography variant='h5' component='h2'>
                        Política de Envío
                    </Typography>
                    <Divider />
                    <Typography marginTop='1rem' textAlign='justify'>
                        La entrega de pedidos será sin costo en pedidos mayores a $3,000.00 (tres mil
                        pesos M.N.) más I.V.A.; siempre y cuando, el domicilio de entrega se encuentre en
                        la dirección previamente establecida.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        Una vez confirmada la aceptación del pago del pedido ingresado a través de la
                        “Plataforma”, PINTURAS FAMOSAS, S.A. de C.V. realizará la entrega en: (i) el
                        domicilio señalado por el “Usuario” dentro de los siguientes 3 a 5 días hábiles
                        siguientes.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        Para la entrega de los “Productos” será necesario que la persona que reciba el
                        mismo sea mayor de edad y presente una identificación oficial (con foto y firma), así
                        como la confirmación del pedido y número de pedido para que la entrega pueda
                        ejecutarse.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        La persona que reciba los “Productos” deberá de asentar nombre y firma (tal y como
                        aparece en la identificación oficial que presente) en la nota de remisión que sea
                        presentada por el personal de PINTURAS FAMOSAS, S.A. de C.V. que realice la
                        entrega correspondiente. En caso de que los “Productos” se presenten abiertos,
                        con abolladuras, derrames o con el etiquetado dañado, no deberán de recibirse los
                        productos y deberá de presentarse la reclamación de conformidad con lo
                        establecido en los presentes Términos y Condiciones.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        Por ningún motivo los pedidos podrán ser entregados a menores de edad, por lo
                        que es responsabilidad el “Usuario” asegurarse de que en el domicilio de entrega
                        haya una persona mayor de edad con identificación para poder recibir los
                        “Productos”.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        El Concesionario realizará dos intentos de entrega, en los horarios que previamente
                        acuerde el “Usuario” con el personal de PINTURAS FAMOSAS, S.A. de C.V.; sin
                        embargo, en caso de que no sea posible realizar la entrega en dos intentos,
                        será responsabilidad exclusiva del “Usuario” acudir a PINTURAS FAMOSAS, S.A.
                        de C.V. a recoger su pedido, en un plazo que no deberá exceder de 3 días hábiles
                        siguientes a la fecha de aceptación del pedido.
                    </Typography>
                </Box>
            </div>
            <div id='terminos'>
                <Box marginTop='1rem' marginBottom='2.5rem'>
                    <Typography variant='h5' component='h2'>
                        Términos y Condiciones
                    </Typography>
                    <Divider />
                    <Typography marginTop='1rem' textAlign='justify'>
                        Las presentes políticas regulan el uso de este sitio por parte del usuario, según se
                        define más adelante, por lo que la utilización del sitio por parte del usuario,
                        constituye la aceptación de los mismos en todos sus términos sin reserva alguna.
                        Por lo anterior, le invitamos a que lea cuidadosamente la presente Política y si tiene
                        cualquier duda, se ponga en contacto con nosotros por cualquiera de los siguiente
                        medios: correo electrónico: <a href='mailto:famosas.contacto@pinturasfamosas.com.mx'>famosas.contacto@pinturasfamosas.com.mx</a>
                    </Typography>
                    <Typography variant='h6' marginY='1.5rem' component='h3'>
                        Datos Personales
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        Todos los datos personales que sean recabados a través de la ”Plataforma” para
                        realizar la compra de productos en línea, se encuentran sujetos al Aviso de
                        Privacidad de PINTURAS FAMOSAS, S.A. de C.V. serán tratados en los términos
                        establecidos en la Política de Uso del sitio. PINTURAS FAMOSAS, S.A. de C.V.
                        sólo recabará su información con la finalidad de ubicar la sucursal más cercana y
                        realizar la entrega de los ”Productos”.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        Los datos proporcionados por el Usuario para ejecutar la compra, como son el
                        método de pago, número de tarjeta o código de seguridad, no serán guardados por
                        PINTURAS FAMOSAS, S.A. de C.V., únicamente serán tratados por el proveedor de
                        medios de pago de la plataforma, quien es un tercero ajeno a PINTURAS
                        FAMOSAS, S.A. de C.V..
                    </Typography>
                    <Typography variant='h6' marginY='1.5rem' component='h3'>
                        Precios y Promociones
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        Los precios de los “Productos” pueden variar dependiendo del código postal elegido
                        por el Usuario, por lo que el precio de los “Productos” será el que se muestre al
                        “Usuario” una vez que éste haya proporcionado su código postal y dirección de su
                        preferencia.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        Todos los precios de los productos son expresados en moneda nacional, incluyen el
                        Impuesto al Valor Agregado y estarán sujetos a cambio sin previo aviso, en el
                        entendido de que una vez ingresado y confirmado un pedido, se respetará el precio
                        ofrecido al momento de la selección de los “Productos”.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        Además del precio de los “Productos”, podrán generarse gastos de flete, en cuyo
                        caso los mismos serán desglosados en el resumen del pedido, previo a que el
                        “Usuario” acepte la orden de pedido.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        Las promociones válidas para compras a través de esta Plataforma, son única y
                        exclusivamente las publicadas en la misma, por lo anterior no será aplicable a los
                        productos adquiridos en esta Plataforma, aquellas promociones publicadas en las
                        sucursales de PINTURAS FAMOSAS, S.A. de C.V. o en sitios externos o ajenos a
                        esta plataforma.
                    </Typography>
                    <Typography variant='h6' marginY='1.5rem' component='h3'>
                        Del Pago y Facturación
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        1. Los únicos métodos de pago aceptables para comprar a través de la
                        “Plataforma” son tarjetas de crédito y débito bancarias.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        2. Los pagos realizados por los “Usuarios” serán recibidos exclusivamente
                        por el “Concesionario” responsable de PINTURAS FAMOSAS, S.A. de
                        C.V. elegida por el “Usuario”.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        3. El “Usuario” deberá solicitar factura al momento en que realice la compra.
                        En caso contrario, la facturación deberá solicitarse directamente por correo
                        electronico a famosas,contacto@pinturasfamosas.com.mx. Las facturas
                        son emitidas directamente por el “Concesionario” que haya suministrado el
                        pedido, por lo que será responsabilidad única y exclusiva del “Usuario”
                        cerciorarse de cumplir con los requisitos para solicitar facturas
                        establecidos por cada “Concesionario”.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        4. Como requisitos fiscales obligatorios, la facturación a través de la
                        “Plataforma” mostrará los campos: (i) Nombre, denominación o razón
                        social, (ii) Registro Federal de Contribuyentes (RFC) y (iii) uso de CFDI.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        5. No existe proceso de re-facturación, por lo que para el caso de que el
                        “Usuario” requiera una modificación en la factura una vez emitida, deberá
                        notificar por correo electronico a famosas.contacto@gmail.com.
                    </Typography>
                    <Typography variant='h6' marginY='1.5rem' component='h3'>
                        Política de Devoluciones y Cancelaciones
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        En caso de que el Cliente no reciba el producto dentro de los 5 (cinco) días hábiles
                        posteriores a la confirmación de su compra en línea, deberá comunicarse al correo
                        de contacto {' '}
                        <a href='mailto:famosas.contacto@pinturasfamosas.com.mx'>
                            famosas.contacto@pinturasfamosas.com.mx
                        </a>{' '}
                        para consultar el
                        estado de su pedido. Es indispensable proporcionar el número de pedido asignado
                        en la plataforma.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        En caso de que el Cliente requiera presentar alguna inconformidad de cualquier
                        producto adquirido por esta plataforma, deberá hacerlo directamente por correo
                        electrónico {' '}
                        <a href='mailto:famosas.contacto@pinturasfamosas.com.mx'>
                            famosas.contacto@pinturasfamosas.com.mx
                        </a>{' '} siempre y cuando la
                        inconformidad no derive de una mala aplicación del producto y se encuentre en
                        cualquiera de los siguientes casos:
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        1. Cuando el producto no corresponda al comprado por el Cliente, según la
                        descripción de su confirmación de su pedido; o bien, <br />
                        2. Cuando el producto suministrado de conformidad con la descripción del
                        pedido, presente deficiencias de calidad o alguna característica diferente a
                        la esperada por el cliente (color, olor, apariencia o acabado). El cliente
                        deberá notificarlo de inmediato en mostrador para aplicar la sustitución o
                        devolución del producto.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        Para hacerla efectiva, es necesario que el cliente presente: <br />
                        Ticket de compra (anotar al reverso nombre, teléfono y el motivo) <br />
                        Envase (mínimo con el 95% de contenido)
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        El nivel de brillo y composición de la pantalla del dispositivo a través del que se
                        realice la compra, puede provocar una diferencia visual respecto del color con
                        muestras físicas. De igual forma, es posible que pueda presentarse variación de
                        color dependiendo del producto que se adquiera, por lo que el Cliente deberá
                        verificar el color seleccionado, antes de confirmar la compra.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        En caso de que el Cliente no esté conforme con el color adquirido, podrá presentar
                        su queja siempre y cuando el color corresponda con el código de igualación
                        seleccionado. Si la igualación del color se realizó o alteró manualmente, no se
                        aceptará la devolución del producto.
                    </Typography>
                    <Typography variant='h6' marginY='1.5rem' component='h3'>
                        Lineamientos para la Atención de Devoluciones
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        El Procedimiento que deberá seguir el Cliente para poder realizar la Devolución de
                        un Producto, es el siguiente:
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        1. Solicitar la devolución por correo electrónico famosas.contacto@gmail.com
                        adjuntando número de orden, ticket o factura que acredite la compra.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        1. Al momento de solicitar la devolución, el Cliente debe asegurarse de que
                        el producto se encuentre en su envase original, sin abolladuras ni
                        derrames y no debe exceder la fecha de uso preferente o caducidad del
                        producto indicada en el envase del producto o en la Carta Técnica.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        1. El Cliente debe describir la falla del producto y el motivo de la
                        inconformidad.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        1. En caso de que la devolución sea procedente, PINTURAS FAMOSAS,
                        S.A. de C.V. será responsable hasta por el monto que el Cliente hubiere
                        pagado por dicho producto, pudiendo optar por: <br />
                        1. Sustituir el producto; o <br />
                        2. Reembolsar al precio de compra según el comprobante de compra
                        exhibido por el Cliente.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        1. Si la inconformidad versa sobre un producto de los denominados
                        “Complementos” o productos auxiliares para la aplicación de pintura o
                        preparación de superficies, la devolución sólo será procedente en caso de
                        que la compra se hubiere realizado en un plazo máximo de 2 meses
                        anteriores y que la falla atribuida al producto, no derive de un uso
                        inadecuado del mismo.
                    </Typography>
                    <Typography variant='h6' marginY='1.5rem' component='h3'>
                        Cancelación de pedidos por Ecommerce
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        Cualquier cancelación de los pedidos solicitados mediante la página
                        www.pinturasfamosas.com.mx, se deberá solicitar directo en la tienda asignada que
                        surtió el pedido.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        El reembolso se realizará a la misma tarjeta dónde se cargó el monto del pedido.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        Una vez solicitada la cancelación, la tienda tendrá un periodo de 72 horas hábiles
                        para realizar el trámite de cancelación del pedido y confirmar la cancelación.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        Una vez realizada la confirmación de cancelación de parte de la tienda, el
                        reembolso se verá reflejado en la cuenta de la tarjeta con la que se realizó el pago
                        en un plazo de 7 a 30 días hábiles, dependiendo del banco de la tarjeta.
                    </Typography>
                    <Typography variant='h6' marginY='1.5rem' component='h3'>
                        Validez de las Ofertas contenidas en este Sitio
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        Los precios de los productos y servicios disponibles o exhibidos en este sitio,
                        mientras aparezcan en él, sólo tendrán vigencia y aplicación para compras
                        efectuadas en el mismo, y no serán necesariamente aplicables a otros canales de
                        venta utilizados por la empresa, tales como tiendas físicas, catálogos u otros.
                        Asimismo, los precios y servicios exhibidos en otras tiendas pertenecientes a
                        PINTURAS FAMOSAS, S.A de C.V. no necesariamente tienen aplicación a las
                        transacciones efectuadas en este sitio.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        Las empresas podrán modificar cualquier información contenida en este sitio,
                        incluyendo las relacionadas con mercaderías, servicios, precios, existencias y
                        condiciones, en cualquier momento y sin previo aviso, hasta el momento de recibir
                        una aceptación de compra, la cual obligará a la empresa oferente, sujeto a las
                        condiciones de validación que se indican en el número 6, es decir, una vez que se
                        haya formado el consentimiento entre las partes de una determinada transacción.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        En el caso que un producto, por un error involuntario de PINTURAS FAMOSAS,
                        S.A. de C.V., se encuentre publicado a un valor muy inferior a su costo, o que
                        naturalmente carezca de toda lógica económica y comercial y se trate de un
                        evidente error, la empresa se reserva el derecho de dejar sin efecto la transacción.
                    </Typography>
                    <Typography variant='h6' marginY='1.5rem' component='h3'>
                        Promociones
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        Las promociones que se ofrezcan en este sitio web no son necesariamente las
                        mismas que ofrecen otros canales de venta utilizados por la empresa, tales como
                        tiendas físicas, catálogos u otros, a menos que se señale expresamente en este
                        sitio o en la publicidad que realice la empresa para cada promoción.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        En los casos que el sitio contenga promociones que consistan en la entrega gratuita
                        o rebajada de un producto por la compra de otro, entonces el despacho del bien que
                        se entregue gratuitamente o a precio rebajado, se hará en el mismo lugar al cual se
                        despacha el producto comprado, a menos que el adquirente manifieste, al hacer su
                        aceptación de oferta, que desea que los productos se remitan a direcciones
                        distintas, en cuyo caso deberá pagar el valor del despacho de ambos productos.
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        No se podrá participar en estas promociones sin adquirir conjuntamente todos los
                        productos comprendidos en ellas.
                    </Typography>
                    <Typography variant='h6' marginY='1.5rem' component='h3'>
                        Otros Sitios Web
                    </Typography>
                    <Typography marginTop='1rem' textAlign='justify'>
                        La empresa no tendrá responsabilidad sobre la información proporcionada por otros
                        sitios web ajenos y las consecuencias que de ello se deriven. La empresa no
                        garantiza, avala ni respalda de ninguna forma el acceso, información o contenido de
                        cualquier otro sitio web o portal ajeno a la empresa en los casos que dicho acceso
                        se efectúe desde o hacia éste, ya sea que dicho acceso se efectúe mediante link,
                        banner o mediante cualquier dispositivo disponible en la red.
                    </Typography>
                </Box>
            </div>
            <Box marginBottom='1rem'>
                <Typography variant='body2' component='p' textAlign='right'>
                    Documento completo de los términos y condiciones {' '} <a href='/Términos y condiciones.pdf' target='_blank'>aquí</a>.
                </Typography>
            </Box>
        </ShopPageLayout>
    );
}

export default ShopInformation;
import { FC, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Parser } from '../../utils';
import { useAppSelector } from '../../hooks';
import { CartProduct } from '../../interfaces';

interface Props {
    data: CartProduct;
}

const SummaryProductCard: FC<Props> = ({ data }) => {
    const { municipality } = useAppSelector(state => state.session);
    const { shippingAddress } = useAppSelector(state => state.checkout);

    const price = useMemo(() => {
        const { localPrice, generalPrice, foreignPrice } = data.variant.price;
        if (shippingAddress)
            return shippingAddress.municipality.toString() === '1' ? localPrice : foreignPrice;
        if (!municipality)
            return generalPrice;
        return municipality === '1' ? localPrice : foreignPrice;
    }, [municipality, shippingAddress, data]);

    const discount = useMemo(() => {
        if (!data.variant.discount)
            return 0;
        return (price * (data.variant.discount / 100));
    }, [data, price]);

    return (
        <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom='1rem'>
            <Box width='15%'>
                <img src={data.image} style={{ aspectRatio: '1 / 1', objectFit: 'contain' }} />
            </Box>
            <Box width='80%'>
                <Typography variant='body2' fontWeight='700' noWrap>
                    {data.name}
                </Typography>
                <Typography variant='body2' color='GrayText'>
                    {data.variant.measurement} {data.variant.color ? ` - Color: ${data.variant.color.code}` : ''} {data.variant.paintSheen ? ` - ${data.variant.paintSheen.name}` : ''}
                </Typography>
                <Box marginTop='0.35rem' display='flex'>
                    <Box width='30%'>
                        <Typography variant='body2' color={discount > 0 ? 'red' : undefined}>
                            {Parser.parseToPrice(price - discount)}
                        </Typography>
                    </Box>
                    <Typography variant='body2'>
                        Cantidad: {data.quantity}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default SummaryProductCard;
import { FC } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip, { chipClasses } from '@mui/material/Chip';
import CircleIcon from '@mui/icons-material/Circle';

import { DetailedOrderProductData } from '../../interfaces';

interface Props {
    data: DetailedOrderProductData;
}

const DetailedOrderProductCard: FC<Props> = ({ data }) => {
    return (
        <Box marginBottom='1rem' display='flex'>
            <Box width='18%'>
                <Link to={`/productos/${data.productSlug}`}>
                    <img
                        src={data.productImage}
                        style={{
                            aspectRatio: '1 / 1',
                            objectFit: 'contain',
                        }}
                    />
                </Link>
            </Box>
            <Box flex={1}>
                <Link to={`/productos/${data.productSlug}`}>
                    <Typography variant='h6' component='p' fontWeight='400' color='primary'>
                        {data.productName}
                    </Typography>
                </Link>
                <Chip label={data.measurement} variant='filled' color='primary' sx={{ marginRight: '0.5rem', marginBottom: '0.5rem' }} />
                {
                    data.colorName && data.colorCode && data.colorRgb
                        ? <Chip
                            sx={{ [`& .${chipClasses.icon}`]: { color: `rgb${data.colorRgb}` }, marginRight: '0.5rem', marginBottom: '0.5rem' }}
                            icon={<CircleIcon />}
                            label={`${data.colorName} (${data.colorCode})`}
                        />
                        : null
                }
                {
                    data.paintSheenName
                        ? <Chip label={data.paintSheenName} variant='filled' color='primary'  sx={{ marginRight: '0.5rem', marginBottom: '0.5rem' }} />
                        : null
                }
                <Typography>
                    Cantidad: {data.quantity}
                </Typography>
                {
                    data.notes
                     ? <Typography>
                            Observaciones: {data.notes}
                        </Typography>
                        : null
                }
            </Box>
        </Box>
    );
}

export default DetailedOrderProductCard;
import { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { setFilter, setSearch } from '../../redux/slices/orders';

interface Props {
}

const OrderSearch: FC<Props> = () => {
    const [value, setValue] = useState('');

    const dispatch = useAppDispatch();
    const { search } = useAppSelector(state => state.orders);

    useEffect(() => {
        if (search === null)
            setValue('');
    }, [search])

    const submitSearch = () => {
        if (value === '') {
            dispatch(setSearch(null));
        } else {
            dispatch(setFilter(null));
            dispatch(setSearch(value));
        }
    }

    const deleteSearch = () => {
        setValue('');
        dispatch(setFilter(null));
        dispatch(setSearch(null));
    }

    return (
        <Paper elevation={4}>
            <Box padding='1rem' display='flex' flexDirection={{ xs: 'column', md: 'row' }} justifyContent='space-between'>
                <Box flex={1} paddingRight='0.5rem'>
                    <TextField
                        size='small'
                        placeholder='Buscar en todos los pedidos'
                        autoComplete='off'
                        value={value}
                        onChange={({ target }) => setValue(target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: value.trim().length > 0
                                ? (
                                    <IconButton onClick={deleteSearch}>
                                        <CloseIcon />
                                    </IconButton>

                                )
                                : undefined
                        }}
                        fullWidth
                    />
                </Box>
                <Button size='small' variant='contained' sx={{ marginTop: { xs: '0.5rem', md: 0 } }} onClick={submitSearch}>
                    Buscar pedidos
                </Button>
            </Box>
        </Paper>
    );
}

export default OrderSearch;
import { FC, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

import { ShopPageLayout } from '../components/layouts';
import { OrderSearch, OrdersList } from '../components/orders';
import { LoadingOverlay } from '../components';
import { useAppDispatch, useAppSelector } from '../hooks';
import { fetchOrders, resetOrdersState } from '../redux/slices/orders';

interface Props {
}

const Orders: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { loading } = useAppSelector(state => state.ui);

    const { selectedRange, filter, search } = useAppSelector(state => state.orders);

    useEffect(() => {
        dispatch(fetchOrders());
    }, [filter, selectedRange, search]);

    useEffect(() => {
        return () => {
            dispatch(resetOrdersState());
        }
    }, [])

    return(
        <ShopPageLayout name='Mis Pedidos' paths={[{ name: 'Mi Cuenta', path: '/cuenta' }]}>
            <LoadingOverlay open={loading} />
            <Grid container flexDirection={{ xs: 'column-reverse', md: 'row' }} spacing='1rem' paddingY='1rem'>
                <Grid item xs={12} lg={8}>
                    <Slide in={true} direction='right' timeout={500}>
                        <Box>
                            <OrdersList />
                        </Box>
                    </Slide>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Grid container spacing='1rem'>
                        <Grid item xs={12} md={6} lg={12}>
                            <Fade in={true} timeout={500}>
                                <Box>
                                    <OrderSearch />
                                </Box>
                            </Fade>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ShopPageLayout>
    );
}

export default Orders;
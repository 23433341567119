import { FC, SyntheticEvent } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import OrderCard from '../ui/OrderCard';
import EmptyOrdersList from './EmptyOrdersList';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setFilter, setSearch, setSelectedRange } from '../../redux/slices/orders';

interface Props {
}

const OrdersList: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { orders, years, selectedRange, filter } = useAppSelector(state => state.orders);

    const handleTabChange = (e: SyntheticEvent, newValue: string | null) => {
        dispatch(setSearch(null))
        dispatch(setSelectedRange('0'));
        dispatch(setFilter(newValue));
    }

    const handleSelectRangeChange = ({ target: { value } }: SelectChangeEvent<string>) => {
        dispatch(setSelectedRange(value));
    }

    return (
        <Paper elevation={4}>
            <Box padding='1rem'>
                <Box>
                    <Tabs value={filter} onChange={handleTabChange} variant='scrollable'>
                        <Tab label="Pedidos" value={null} />
                        <Tab label="En camino" value='in-progress' />
                        <Tab label="Pendiente de envío" value='pending' />
                        <Tab label='Pedidos cancelados' value='cancelled' />
                    </Tabs>
                </Box>
                <Divider />
                <Box marginTop='1rem'>
                    {
                        orders.length <= 0
                            ? <EmptyOrdersList />
                            : <>
                                {
                                    filter
                                        ? <Box display='flex' marginBottom='1rem' alignItems='center'>
                                            <Typography variant='body1' marginRight='0.5rem'>
                                                <span style={{ fontWeight: 'bold' }}>{`${orders.length} pedido${orders.length > 1 ? 's' : ''} `}</span>
                                                {filter === 'in-progress' && 'en camino'}
                                                {filter === 'cancelled' && `cancelado${orders.length > 1 ? 's' : ''}`}
                                                {filter === 'pending' && `pendiente${orders.length > 1 ? 's' : ''}`}
                                            </Typography>
                                        </Box>
                                        : <Box display='flex' marginBottom='1rem' alignItems='center'>
                                            <Typography variant='body1' marginRight='0.5rem'>
                                                <span style={{ fontWeight: 'bold' }}>{`${orders.length} pedido${orders.length > 1 ? 's' : ''} `}</span>
                                                realizados en
                                            </Typography>
                                            <Select size='small' value={selectedRange} onChange={handleSelectRangeChange}>
                                                <MenuItem value='0'>últimos 30 días</MenuItem>
                                                <MenuItem value='1'>últimos 6 meses</MenuItem>
                                                <MenuItem value={moment().format('YYYY')}>{moment().format('YYYY')}</MenuItem>
                                                {
                                                    years.map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)
                                                }
                                            </Select>
                                        </Box>
                                }
                                <Box>
                                    {orders.map(order => <OrderCard key={order.idOrder} data={order} />)}
                                </Box>
                            </>

                    }
                </Box>
            </Box>
        </Paper>
    );
}

export default OrdersList;
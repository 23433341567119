import { CSSProperties, ChangeEvent, FC, FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { colors } from '../../themes';

interface Props {
    placeholder?: string;
    onSubmit?: (search: string) => void;
}

const searchBarStyle: CSSProperties = {
    backgroundColor: colors.white,
    minWidth: 250,
    borderRadius: 1,
}

const SearchBar: FC<Props> = ({ onSubmit = () => { }, placeholder = 'Encuentra productos...' }) => {
    const [search, setSearch] = useState('');

    const handleSearchChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        setSearch(target.value);
    }

    const handleFormSubmit = (event?: FormEvent<HTMLFormElement>) => {
        if (event)
            event.preventDefault();

        if (search.trim().length > 0) {
            onSubmit(search);
            setSearch('');
        }
    }

    const handleSearchIconClick = () => {
        handleFormSubmit();
    }

    return (
        <form onSubmit={handleFormSubmit}>
            <TextField
                variant='outlined'
                name='nav-bar-search'
                size='small'
                placeholder={placeholder}
                aria-label={placeholder}
                fullWidth
                value={search}
                onChange={handleSearchChange}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton size='small' onClick={handleSearchIconClick}>
                                <SearchOutlinedIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                sx={searchBarStyle}
            />
        </form>
    );
}

export default SearchBar;
export const capitalize = (string: string) => {
    return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}

export const truncate = (string: string, words: number) => {
    const content = string.split(' ').slice(0, words).join(' ');
    const lastChar = content.charAt(content.length - 1);
    switch(lastChar) {
        case '.':
            return content;
        case ',':
            return content.slice(0, content.length - 1) + '...';
        default: 
            return content + '...';
    };
}

export const formatPhoneNumber = (string: string) => {
    const totalLength = string.length;
    const prefixes = string.slice(0, totalLength - 10);
    const firstThreeDigits = string.slice(totalLength - 10, totalLength - 7);
    const secondThreeDigits = string.slice(totalLength - 7, totalLength - 4);
    const lastFourDigits = string.slice(totalLength - 4);
    return `${prefixes} ${firstThreeDigits} ${secondThreeDigits} ${lastFourDigits}`;
}
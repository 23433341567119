import { FC } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ColorBox from './ColorBox';
import { GalleryColor } from '../../interfaces';

interface Props {
    title: string;
    colors: GalleryColor[];
    onColorClick: (c: GalleryColor) => void;
}

const ColorGallery: FC<Props> = ({ title, colors, onColorClick }) => {
    return (
        <Box marginBottom='1.5rem'>
            <Typography variant='h6' fontWeight='normal' component='p' marginBottom='0.5rem'>
                {title}
            </Typography>
            <Grid container spacing={1 / 2} >
                {
                    colors.map(color =>
                        <Grid item xs={4} md={2} key={color.idColor}>
                            <ColorBox
                                color={color}
                                onClick={onColorClick}
                            />
                        </Grid>
                    )
                }
            </Grid>
        </Box>
    );
}

export default ColorGallery;
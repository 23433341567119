import { FC, useEffect, useMemo } from 'react';
import Paper from '@mui/material/Paper';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';

import StoreFilter from './StoreFilter';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getStoreProducts, setSelectedCategory, setSelectedLine, setSelectedStore } from '../../redux/slices/store';

interface Props {
}

const StoreSideBar: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { stores, categories, lines, selectedStore, selectedCategory, selectedLine }  = useAppSelector(state => state.store);

    const displayedCategories = useMemo(() => {
        if (selectedStore === 0)
            return categories;
        return categories.filter(c => stores.find(s => s.idStore === selectedStore)?.categories.includes(c.idCategory));
    }, [selectedStore, categories]);

    const displayedLines = useMemo(() => {
        if (selectedStore === 0 && selectedCategory === 0)
            return lines;
        if (selectedStore !== 0 && selectedCategory === 0)
            return lines.filter(l => stores.find(s => s.idStore === selectedStore)?.lines.includes(l.idLine));
        return lines.filter(l => categories.find(c => c.idCategory === selectedCategory)?.lines.includes(l.idLine));
    }, [selectedStore, selectedCategory, lines]);

    const changeStore = (idStore: number) => {
        dispatch(setSelectedLine(0));
        dispatch(setSelectedCategory(0));
        dispatch(setSelectedStore(idStore));
    }

    const changeCategory = (idCategory: number) => {
        dispatch(setSelectedLine(0));
        dispatch(setSelectedCategory(idCategory));
    }

    const changeLine = (idLine: number) => {
        dispatch(setSelectedLine(idLine));
    }

    return (
        <Paper sx={{ padding: '1rem' }}>
            <StoreFilter
                title='Tiendas'
                icon={<InsertLinkOutlinedIcon fontSize='small' />}
                options={stores.map(({ idStore, name }) => ({ id: idStore, name }))}
                selectedOption={selectedStore}
                onOptionClick={changeStore}
            />
            <StoreFilter
                title='Categorías'
                icon={<DiscountOutlinedIcon fontSize='small' />}
                options={displayedCategories.map(({ idCategory, name }) => ({ id: idCategory, name }))}
                selectedOption={selectedCategory}
                onOptionClick={changeCategory}
            />
            <StoreFilter
                title='Líneas'
                icon={<WidgetsOutlinedIcon fontSize='small' />}
                options={displayedLines.map(({ idLine, name }) => ({ id: idLine, name }))}
                showDivider={false}
                selectedOption={selectedLine}
                onOptionClick={changeLine}
            />
        </Paper>
    );
}

export default StoreSideBar;
import { FC, FormEvent, useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MuiPhoneNumber from 'material-ui-phone-number';

import { useAppDispatch, useAppSelector, useForm } from '../../hooks';
import { LoadingOverlay, Notification } from '../ui';
import { AccountDetailsFormData } from '../../interfaces';
import { updateAccountData } from '../../redux/slices/account';

interface Props {
    active: boolean;
    onClose: VoidFunction;
}

const AccountDetailsForm: FC<Props> = ({ active, onClose }) => {
    const dispatch = useAppDispatch();
    const { loading } = useAppSelector(state => state.ui);
    const { user } = useAppSelector(state => state.session);

    const [error, setError] = useState({
        active: false,
        message: '',
    });

    const [errorsActive, setErrorsActive] = useState(false);

    const { data, handleInputChange, handlePhoneInputChange, reset } = useForm<AccountDetailsFormData>({
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        phoneNumber: user?.phoneNumber || '',
        oldPassword: '',
        password: '',
        repeatPassword: '',
    });
    const { firstName, lastName, phoneNumber, oldPassword, password, repeatPassword } = data;

    useEffect(() => {
        reset({
            ...data,
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            phoneNumber: user?.phoneNumber || '',
        });
    }, [user])

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        setErrorsActive(false);

        if (phoneNumber.trim().length < 10) {
            return setErrorsActive(true);
        }

        if (oldPassword.trim().length > 0 && (password.trim().length < 8 || repeatPassword.trim().length < 8 || password !== repeatPassword || oldPassword.trim().length < 8)) {
            return setErrorsActive(true);
        }

        dispatch(updateAccountData(data, () => {
            onClose();
        }, () => {
            setError({
                active: true,
                message: 'Ha ocurrido un error al intentar actualizar los datos, por favor compruebe su conexión a internet e intente de nuevo.'
            })
        }));
    }

    const closeForm = () => {
        reset();
        onClose();
    }

    return (
        <Box component='form' autoComplete='off' onSubmit={handleFormSubmit}>
            <LoadingOverlay open={loading} />
            <Notification
                type='error'
                open={error.active}
                onClose={() => setError({ active: false, message: '' })}
                message={error.message}
                vertical='bottom'
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h5' component='h2'>
                        Datos Generales
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        size='small'
                        label='Nombre(s)'
                        name='firstName'
                        value={firstName}
                        onChange={handleInputChange}
                        fullWidth
                        required
                        disabled={!active}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        size='small'
                        label='Apellidos'
                        name='lastName'
                        value={lastName}
                        onChange={handleInputChange}
                        fullWidth
                        required
                        disabled={!active}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MuiPhoneNumber
                        label='Número Tel.'
                        defaultCountry='mx'
                        variant='outlined'
                        name='phoneNumber'
                        value={phoneNumber}
                        onChange={handlePhoneInputChange}
                        size='small'
                        fullWidth
                        disabled={!active}
                        disableDropdown={!active}
                        error={errorsActive && data.phoneNumber.length < 10}
                        helperText={(errorsActive && data.phoneNumber.length < 10) && 'El número telefónico no es válido.'}
                    />
                </Grid>
                {
                    active
                        ? <>
                            <Grid item xs={12}>
                                <Typography variant='h5' component='h2'>
                                    Contraseña
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type='password'
                                    size='small'
                                    label='Antigua Contraseña'
                                    name='oldPassword'
                                    value={oldPassword}
                                    onChange={handleInputChange}
                                    fullWidth
                                    disabled={!active}
                                    error={errorsActive && data.repeatPassword.length < 8}
                                    helperText={(errorsActive && data.repeatPassword.length < 8) && 'La contraseña no es válida. Debe tener al menos 8 caracteres.'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    type='password'
                                    size='small'
                                    label='Nueva Contraseña'
                                    name='password'
                                    value={password}
                                    onChange={handleInputChange}
                                    fullWidth
                                    disabled={!active}
                                    error={errorsActive && data.repeatPassword.length < 8}
                                    helperText={(errorsActive && data.repeatPassword.length < 8) && 'La contraseña no es válida. Debe tener al menos 8 caracteres.'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    type='password'
                                    size='small'
                                    label='Repetir Nueva Contraseña'
                                    name='repeatPassword'
                                    value={repeatPassword}
                                    onChange={handleInputChange}
                                    fullWidth
                                    disabled={!active}
                                    error={errorsActive && data.repeatPassword.length < 8}
                                    helperText={(errorsActive && data.repeatPassword.length < 8) && 'El contraseña no es válida. Debe tener al menos 8 caracteres.'}
                                />
                            </Grid>
                            {
                                errorsActive && (password !== repeatPassword)
                                    ? <Grid item xs={12}>
                                        <Alert severity='error'>
                                            Las contraseñas no coinciden.
                                        </Alert>
                                    </Grid>
                                    : null
                            }
                            <Grid item xs={12}>
                                <Box display='flex' justifyContent='end' marginTop='1rem'>
                                    <Button variant='outlined' color='error' sx={{ marginRight: '0.5rem', width: 135 }} onClick={closeForm}>
                                        Cancelar
                                    </Button>
                                    <Button variant='contained' type='submit'>
                                        Guardar Cambios
                                    </Button>
                                </Box>
                            </Grid>
                        </>
                        : null
                }
            </Grid>
        </Box>
    );
}

export default AccountDetailsForm;
import { FC } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import { NavigationPath } from '../../interfaces';
import { useAppSelector, useDesignConstants } from '../../hooks';
import Skeleton from '@mui/material/Skeleton';

interface Props {
    children: JSX.Element | JSX.Element[]
    name: string;
    paths?: NavigationPath[];
    showTitle?: boolean;
    showSkeleton?: boolean;
}

const ShopPageLayout: FC<Props> = ({ children, name, paths = [], showTitle = true, showSkeleton = false }) => {
    const { ShopContent } = useDesignConstants();

    const { loading } = useAppSelector(state => state.ui);

    return (
        <Box sx={ShopContent} paddingTop='1rem'>
            <Breadcrumbs aria-label="breadcrumb" sx={{ color: 'text.secondary' }}>
                <Link to='/' style={{ color: 'inherit' }}>
                    Inicio
                </Link>
                {
                    paths.map(({ path, name }, index) =>
                        <Link key={`path_${index}`} to={path} style={{ color: 'inherit' }}>
                            {name}
                        </Link>
                    )
                }
                {
                    showSkeleton && loading
                        ? <Skeleton variant='rectangular' width={100} height={15} />
                        : <Typography color='text.primary'>
                            {name}
                        </Typography>
                }
            </Breadcrumbs>
            {
                showTitle
                    ? showSkeleton && loading
                        ? <Skeleton variant='rectangular' width={300} height={25} />
                        : <Typography component='h1' variant='h4'>
                            {name}
                        </Typography>
                    : null
            }
            <Box width='100%'>
                {children}
            </Box>
        </Box>
    );
}

export default ShopPageLayout;
import { FC, ReactNode, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';

import { colors } from '../../themes';
import { useOnView } from '../../hooks';

interface Props {
    title: string;
    subtitle?: string;
    children: ReactNode;
}

const HomeSection: FC<Props> = ({ title, subtitle, children }) => {
    const ref = useRef<HTMLElement>(null);
    const isVisible = useOnView(ref);

    return (
        <Fade in={isVisible}>
            <Box display='flex' flexDirection='column' alignItems='center'>
                <Typography variant='h4' component='h2' textTransform='uppercase' textAlign='center'>
                    {title}
                </Typography>
                {
                    subtitle
                        ? <Typography>{subtitle}</Typography>
                        : null
                }
                <Box
                    sx={{
                        marginTop: '0.5rem',
                        height: '0.35rem',
                        width: isVisible ? '5rem' : '1rem',
                        backgroundColor: colors.secondary,
                        transition: 'all 0.3s ease-in'
                    }}
                />
                <Box ref={ref} width='100%' marginY='2.5rem'>
                    {children}
                </Box>
            </Box>
        </Fade>
    );
}

export default HomeSection;
import { FC, useEffect, useState } from 'react';
import { AppModal } from '../ui';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Cookie from 'js-cookie';

import { LocationFormData } from '../../interfaces';
import LocationForm from './LocationForm';
import { useAppDispatch } from '../../hooks';
import { setMunicipality, setZipcode } from '../../redux/slices/session';

interface Props {
}

const LocationModal: FC<Props> = () => {
    const [modalActive, setModalActive] = useState(false);

    const dispatch = useAppDispatch();

    const setCookiesInStore = () => {
        dispatch(setMunicipality(Cookie.get('m') as string));
        dispatch(setZipcode(Cookie.get('m') as string));
    }

    const hasTrackingCookies = () => {
        return Cookie.get('m') && Cookie.get('zp');
    }

    const setTrackingCookies = ({ municipality, zipcode }: LocationFormData) => {
        Cookie.set('m', municipality);
        Cookie.set('zp', zipcode);
        setCookiesInStore();
    }

    const showLocationModal = () => {
        setTimeout(() => {
            setModalActive(true);
        }, 1000);
    }

    const closeModal = () => {
        setModalActive(false);
    }

    useEffect(() => {
        if (!hasTrackingCookies()) 
            showLocationModal();
         else 
            setCookiesInStore();
    }, [])

    const handleSubmit = (data: LocationFormData) => {
        setTrackingCookies(data);
        closeModal();
    }

    return (
        <AppModal open={modalActive} onClose={closeModal} title='¡Bienvenido a Pinturas Famosas!' containerStyle={{ width: { xs: '90%', md: '80%', lg: '60%' } }}>
            <Box>
                <Typography align='justify'>
                    En Pinturas Famosas nos preocupamos por darte la mejor atención. Por eso nos gustaría saber tu ubicación para brindarte
                    las mejores ofertas y precios.
                </Typography>
                <LocationForm onSubmit={handleSubmit} />
            </Box>
        </AppModal>
    );
}

export default LocationModal;
import { FC } from 'react';
import { colors } from '../../themes';

import { UserAddress } from '../../interfaces';
import { Formatter, Parser } from '../../utils';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface Props {
    data: UserAddress;
}

const AddressCard: FC<Props> = ({ data }) => {
    return (
        <Box padding='1rem' borderRadius={1} position='relative' sx={{ backgroundColor: colors.cardBgAlt }}>
            {
                data.isDefault === 1
                    ? <Button variant='outlined' size='small' sx={{ position: 'absolute', right: 10, top: 10 }} disabled>
                        Principal
                    </Button>
                    : null
            }
            <Typography>{data.fullName}</Typography>
            <Typography>{Formatter.formatPhoneNumber(data.phoneNumber)}</Typography>
            {
                data.company
                    ? <Typography>{data.company}</Typography>
                    : null
            }
            <Typography>{data.address1}</Typography>
            {
                data.address2
                    ? <Typography>{data.address2}</Typography>
                    : null
            }
            <Typography>
                {`${data.city} ${data.zipcode}`}
            </Typography>
            <Typography>
                {Parser.parseToMunicipality(data.municipality)} BCS, México
            </Typography>
        </Box>
    );
}

export default AddressCard;
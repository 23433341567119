import { FormEvent, useState } from 'react';

import useForm from './useForm';
import useAppDispatch from './useAppDispatch';
import { RegistrationFormData } from '../interfaces';
import { registerCustomer } from '../redux/slices/session';

const useRegisterForm = () => {

    const [error, setError] = useState(false);
    const [errorsActive, setErrorsActive] = useState(false);

    const dispatch = useAppDispatch();

    const { data, handleInputChange, handlePhoneInputChange, handleCheckChange } = useForm<RegistrationFormData>({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        password: '',
        repeatPassword: '',
        isSubscribed: false,
        type: 18,
    });

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setErrorsActive(false);
        setError(false);

        const { phoneNumber, password, repeatPassword } = data;
        if (phoneNumber.trim().length < 10 || password.trim().length < 8 || repeatPassword.trim().length < 8 || password !== repeatPassword) {
            return setErrorsActive(true);
        }

        dispatch(registerCustomer(data, () => {
            setError(true);
        }));
    }

    return {
        data,
        error,
        errorsActive,
        handleInputChange,
        handlePhoneInputChange,
        handleCheckChange,
        handleSubmit,
    };
}

export default useRegisterForm;
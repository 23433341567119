import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

import { ShopPageLayout } from '../components';
import { useAppDispatch } from '../hooks';
import { getAddresses, setSelectedAddress } from '../redux/slices/account';
import { AddressForm, AddressesList } from '../components/accountaddresses';


interface Props {
}

const AccountAddresses: FC<Props> = () => {
    const navigate = useNavigate();
    const [formActive, setFormActive] = useState(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getAddresses());
    }, []);

    const toggleForm = () => {
        if (formActive) 
            dispatch(setSelectedAddress(null));
        setFormActive(!formActive);
    }

    return (
        <ShopPageLayout name='Direcciones' paths={[{ name: 'Mi Cuenta', path: '/cuenta' }]}>
            <Grid container spacing='1rem' flexDirection={{ xs: 'column-reverse', md: 'row' }} paddingBottom='2.5rem'>
                <Grid item xs={12} md={8}>
                    <Paper elevation={2}>
                        <Box padding='1rem'>
                            {
                                formActive
                                    ? <AddressForm
                                        onClose={toggleForm}
                                    />
                                    : <AddressesList
                                        onAddressEdit={(address) => {
                                            dispatch(setSelectedAddress(address));
                                            setFormActive(true);
                                        }}
                                    />
                            }
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper elevation={2}>
                        <Box padding='1rem'>
                            <Box>
                                <Button variant='contained' onClick={toggleForm} fullWidth>
                                    Añadir una nueva dirección
                                </Button>
                            </Box>
                            <Box marginTop='0.5rem'>
                                <Button variant='outlined' fullWidth onClick={() => navigate('/cuenta')}>
                                    <KeyboardBackspaceOutlinedIcon fontSize='small' sx={{ marginRight: '0.5rem' }} />
                                    Regresar a Direcciones
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </ShopPageLayout>
    );
}

export default AccountAddresses;
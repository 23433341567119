import { FC, useMemo } from 'react';
import Box from '@mui/material/Box';

import { useAppSelector } from '../../hooks';
import FadeImage from './FadeImage';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';

interface Props {
}

const InspirationGallery: FC<Props> = () => {
    const { loading } = useAppSelector(state => state.ui);
    const { images, colors, selectedColor, selectedZone } = useAppSelector(state => state.inspiration);

    const displayedImages = useMemo(() => {
        let filteredImages = images;
        if (selectedColor !== 0)
            filteredImages = filteredImages.filter(({ idColor }) => idColor === selectedColor);
        if (selectedZone !== '')
            filteredImages = filteredImages.filter(({ section }) => section === selectedZone);
        return filteredImages;
    }, [images, selectedColor, selectedZone]);

    if (displayedImages.length === 0) {
        return (
            <Box display='flex' justifyContent='center' marginTop='2.5rem'>
                {
                    loading
                        ? <CircularProgress color='primary' />
                        : <Typography textTransform='uppercase'>
                            No hay imágenes disponibles.
                        </Typography>
                }
            </Box>
        );
    }

    return (
        <Box sx={{ columns: '5 200px', columnGap: '1rem', margin: '2.5rem auto', paddingBottom: '1rem' }}>
            {
                displayedImages.map(({ image, idColor }, index) =>
                    <FadeImage
                        key={`inspiration_${index}`}
                        img={image}
                        alt={`inspiration_${index}`}
                        color={colors.find(c => c.idColor === idColor)!}
                    />
                )
            }
        </Box>
    );
}

export default InspirationGallery;
import { FC, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

interface Props {
    message?: string;
}

const LoadingCard: FC<Props> = ({ message }) => {
    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <Box display='flex' justifyContent='center' alignItems='center' marginTop='5rem'>
            <Card elevation={6}>
                <CardContent >
                    <Box minWidth={{ xs: '90%', md:'10rem'}} display='flex' flexDirection='column' alignItems='center' paddingTop='1.5rem'>
                        <CircularProgress size='3rem' />
                        {
                            message
                                ? <Typography variant='body1' marginTop='2rem' align='center' textTransform='uppercase' marginX='2.5rem' fontWeight='500'>
                                    {message}
                                </Typography>
                                : null
                        }
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

export default LoadingCard;
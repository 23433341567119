import { FC, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';

import { ShopPageLayout } from '../components/layouts';
import { CheckoutCart, CheckoutPayment, CheckoutStepper, CouponRedeemal, OrderSummary, OrderTotal, ShippingSettings } from '../components/checkout';
import { useAppDispatch, useAppSelector } from '../hooks';
import Box from '@mui/material/Box';
import { calculateSubtotal, calculateTotal, getPayments, resetCheckout, setStep, } from '../redux/slices/checkout';
import { validateShoppingCart } from '../redux/slices/cart';
import { LoadingOverlay } from '../components';
import Button from '@mui/material/Button';

interface Props {
}

const Checkout: FC<Props> = () => {
    const [cartChecked, setCartChecked] = useState(false);

    const dispatch = useAppDispatch();
    const { loading } = useAppSelector(state => state.ui);
    const { cart } = useAppSelector(state => state.cart);
    const { step, subtotal, shipping, promotion, shippingAddress, isNewClient } = useAppSelector(state => state.checkout);
    const { municipality } = useAppSelector(state => state.session);

    useEffect(() => {
        dispatch(resetCheckout());
    }, []);

    useEffect(() => {
        if (cart.length > 0 && !cartChecked) {
            dispatch(validateShoppingCart());
            setCartChecked(true);
        }
    }, [cart]);

    useEffect(() => {
        dispatch(calculateSubtotal());
    }, [cart, municipality, shippingAddress]);

    useEffect(() => {
        dispatch(calculateTotal());
    }, [subtotal, shipping, promotion, isNewClient]);

    useEffect(() => window.scrollTo(0, 0), [step]);

    const goToPayment = () => {
        dispatch(setStep(2));
        dispatch(getPayments());
    }

    return (
        <ShopPageLayout name='Pago' paths={[{ name: 'Tienda', path: '/tienda' }, { name: 'Carrito de Compra', path: '/carrito' }]}>
            <LoadingOverlay open={loading} />
            <Grid container marginTop='.5rem' marginBottom='2.5rem'>
                <Grid item xs={12}>
                    <CheckoutStepper />
                </Grid>
                <Grid item xs={12}>
                    <Box width='100%' marginBottom='1rem'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                {
                                    step === 0
                                        ? <CheckoutCart />
                                        : null
                                }
                                {
                                    step === 1
                                        ? <ShippingSettings />
                                        : null
                                }
                                {
                                    step >= 2
                                        ? <CheckoutPayment />
                                        : null
                                }
                            </Grid>
                            <Grid item xs={12} md={4}>
                                {
                                    step < 2
                                        ? <CouponRedeemal />
                                        : null
                                }
                                <OrderSummary />
                                <OrderTotal />
                                <Box marginTop='1rem'>
                                    {
                                        step === 0
                                            ? <Button variant='contained' disabled={cart.length <= 0} fullWidth onClick={() => dispatch(setStep(1))}>
                                                Proceder a los datos de Envío
                                            </Button>
                                            : null
                                    }
                                    {
                                        step === 1
                                            ? <Button
                                                variant='contained'
                                                disabled={subtotal > 3000 ? shippingAddress === null : shipping === null}
                                                fullWidth
                                                onClick={goToPayment}
                                            >
                                                Proceder al Pago
                                            </Button>
                                            : null
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

            </Grid>
        </ShopPageLayout>
    );
}

export default Checkout;
import { FC } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';

import { Color } from '../../interfaces';

interface Props {
    visible?: boolean;
    color: Color;
}

const Container: SxProps = {
    position: 'absolute', 
    top: '50%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)', 
    padding: '0.5rem', 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center',
}

const ColorInfo: FC<Props> = ({ visible = false, color }) => {
    if (!visible) return null;

    return (
        <Paper sx={Container} elevation={5}>
            <Box width='5rem' height='1rem' sx={{ backgroundColor: `rgb${color.rgb}` }} />
            <Typography variant='body2'>
                {color.code}
            </Typography>
        </Paper>
    );
}

export default ColorInfo;
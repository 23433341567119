import { FC, useMemo } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';

import { Formatter, Parser } from '../../utils';
import { StoreProduct } from '../../interfaces';
import Box from '@mui/material/Box';
import { useAppSelector } from '../../hooks';
import { PromotionIndicator } from '../ui';

interface Props {
    data: StoreProduct;
}

const StoreProductCard: FC<Props> = ({ data }) => {
    const name = Formatter.capitalize(data.name);
    const description = Formatter.truncate(data.description, 15);

    const { municipality } = useAppSelector(state => state.session);
    
    const price = useMemo(() => {
        if (!municipality) return data.generalPrice;
        return municipality === '1' ? data.localPrice : data.foreignPrice;
    }, [data]);

    return (
        <Link to={`/productos/${data.slug}`}>
            <Card sx={{ position: 'relative' }}>
                {
                    data.hasSale
                        ? <PromotionIndicator />
                        : null
                }
                <CardContent>
                    <Tooltip title={name}>
                        <Typography component='h2' variant='h6' fontWeight='400' color='primary' noWrap>
                            {name}
                        </Typography>
                    </Tooltip>
                </CardContent>
                <CardMedia
                    component='img'
                    height='160'
                    src={data.image}
                    alt={data.name}
                    sx={{
                        objectFit: 'contain',
                    }}
                />
                <CardContent>
                    <Box height={100} overflow='hidden'>
                        <Typography textAlign='justify'>
                            {description}
                        </Typography>
                    </Box>
                    <Box display='flex' justifyContent='end'>
                        {
                            data.variants > 1
                                ? <Typography marginRight='0.5rem' alignSelf='end'>desde</Typography>
                                : null
                        }
                        <Typography component='h3' variant='h5' color={data.discount ? 'red' : 'primary'}>
                            {Parser.parseToPrice(data.discount ? price - (price * (data.discount / 100)) : price)}
                        </Typography>
                        <Box display='flex' alignItems='end' height='100%'>
                            {
                                data.discount
                                    ? <Typography variant='body2' marginLeft='0.25rem' sx={{ textDecorationLine: 'line-through' }}>
                                        {Parser.parseToPrice(price)}
                                    </Typography>
                                    : null
                            }
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Link>
    );
}

export default StoreProductCard;
import axios from 'axios';
import Cookie from "js-cookie";

const mainApi = axios.create({
    baseURL: 'https://pfamosas-ecommerce-api-fic6.onrender.com/api/v1'
});

mainApi.interceptors.request.use((config: any) => {
    config.headers = {
        ...config.headers,
        'c-token': Cookie.get('c-token') ?? '',
    };
    return config;
});

export default mainApi;
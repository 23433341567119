import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../store';
import { finishLoading, startLoading } from './ui';
import { mainApi } from '../../api';
import { Coupon, GetCoupon, PaymentData, PostShippingFee, UserAddress } from '../../interfaces';
import { emptyCart } from './cart';

interface CheckoutState {
    step: number;
    subtotal: number;
    promotion: Coupon | null;
    shipping: number | null;
    total: number;
    shippingAddress: UserAddress | null;
    preferenceId: string | null;
    isNewClient: boolean;
}

const initialState: CheckoutState = {
    step: 0,
    subtotal: 0,
    promotion: null,
    shipping: null,
    total: 0,
    shippingAddress: null,
    preferenceId: null,
    isNewClient: false,
};

const checkoutSlice = createSlice({
    name: 'Checkout',
    initialState,
    reducers: {
        setStep(state, { payload }: PayloadAction<number>) {
            state.step = payload;
        },
        setSubtotal(state, { payload }: PayloadAction<number>) {
            state.subtotal = payload;
        },
        setPromotion(state, { payload }: PayloadAction<Coupon | null>) {
            state.promotion = payload;
        },
        resetCheckout() {
            return initialState;
        },
        setTotal(state, { payload }: PayloadAction<number>) {
            state.total = payload;
        },
        setShippingAddress(state, { payload }: PayloadAction<UserAddress | null>) {
            state.shippingAddress = payload;
        },
        setShipping(state, { payload }: PayloadAction<number | null>) {
            state.shipping = payload;
        },
        setPreferenceId(state, { payload }: PayloadAction<string | null>) {
            state.preferenceId = payload;
        },
        setNewClient(state, { payload }: PayloadAction<boolean>) {
            state.isNewClient = payload;
        }
    },
});

export const {
    setStep,
    setSubtotal,
    setPromotion,
    resetCheckout,
    setTotal,
    setShippingAddress,
    setShipping,
    setPreferenceId,
    setNewClient,
} = checkoutSlice.actions;

export const searchCouponCode = (code: string, onSuccess?: VoidFunction, onError?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetCoupon>(`/coupons/${code}`);

            dispatch(setPromotion(data.coupon));
            dispatch(finishLoading());
            if (onSuccess) onSuccess();
        } catch (error) {
            // console.log('searchCouponCode', error);
            dispatch(setPromotion(null));;
            dispatch(finishLoading());
            if (onError) onError();
        }
    }
}

export const calculateSubtotal = (): AppThunk => {
    return async (dispatch, getState) => {
        try {
            const { cart } = getState().cart;
            const { municipality: sessionMunicipality } = getState().session;
            const { shippingAddress } = getState().checkout;

            let subtotal = 0;

            const municipality = shippingAddress ? shippingAddress.municipality.toString() : sessionMunicipality;

            cart.forEach(product => {
                const price = municipality === null
                    ? product.variant.price.generalPrice
                    : municipality === '1'
                        ? product.variant.price.localPrice
                        : product.variant.price.foreignPrice;

                subtotal += (price - (product.variant.discount ? price * (product.variant.discount / 100) : 0)) * product.quantity;
            });

            dispatch(setSubtotal(subtotal));
        } catch (error) {
            // console.log('calculateSubtotal', error);
        }
    }
}

export const calculateTotal = (): AppThunk => {
    return async (dispatch, getState) => {
        try {
            const { subtotal, shipping, promotion, isNewClient } = getState().checkout;

            let total = subtotal;
            if (promotion) {
                total -= subtotal * (promotion.discountPercentage / 100);
            }

            if (isNewClient) {
                total -= subtotal * 0.1;
            }

            dispatch(setTotal(total + (shipping ?? 0)));
        } catch (error) {
            // console.log('calculateTotal', error);
        }
    }
}

export const getShippingFee = (): AppThunk => {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());

            const { shippingAddress } = getState().checkout;
            const { cart } = getState().cart;

            const { data } = await mainApi.post<PostShippingFee>(`/ecommerce/calculate-shipping/${shippingAddress!.idAddress}`, {
                items: cart.map(({ variant, quantity }) =>
                    variant.color
                        ? ({ key: variant.key, quantity, color: variant.color.idColor })
                        : ({ key: variant.key, quantity })
                ),
            });

            dispatch(setShipping(data.shippingFee));
            dispatch(finishLoading());
        } catch (error) {
            // console.log('getShippingFee', error);
            dispatch(finishLoading());
        }
    }
}

export const getPayments = (): AppThunk => {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());

            const { shippingAddress, promotion } = getState().checkout;
            const { cart } = getState().cart;

            const { data } = await mainApi.post('/orders/generate-payment', {
                idAddress: shippingAddress?.idAddress,
                cartItems: cart.map(({ variant, quantity }) => {
                    const defaultCartItemData = {
                        key: variant.key,
                        quantity,
                        color: variant.color && variant.color.code,
                        notes: null,
                    }

                    const cartItem = variant.paintSheen
                        ? { ...defaultCartItemData, paintSheen: variant.paintSheen!.id }
                        : defaultCartItemData

                    // console.log('test ->', cartItem)

                    return cartItem;
                }),
                code: promotion && promotion.code,
            })

            dispatch(setPreferenceId(data.preferenceId));
            dispatch(setNewClient(data.isNewCustomer));
            dispatch(finishLoading());
        } catch (error) {
            // console.log('getPayments', error);
            dispatch(finishLoading());
        }
    }
}

export const abortPayment = (preferenceId: string): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.post('/orders/abort-payment', { preferenceId });

            dispatch(setPreferenceId(null));
            dispatch(finishLoading());
        } catch (error) {
            // console.log('cancelPayment', error);
            dispatch(finishLoading());
        }
    }
}

export const confirmPayment = (data: PaymentData, onSuccess?: (id: string) => void, onError?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data: { orderId } } = await mainApi.post('/orders/confirm-payment', data);

            dispatch(resetCheckout());
            dispatch(emptyCart());
            dispatch(finishLoading());
            if (onSuccess) onSuccess(orderId);
        } catch (error) {
            // console.log('confirmPayment', error);
            if (onError) onError();
        }
    }
}

export default checkoutSlice.reducer;
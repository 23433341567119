import { Fragment, FC, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import { NavigationBar } from '../navbar';
import { FooterBar } from '../footer';
import { WhatsAppButton } from '../ui';

interface Props {
}

const ShopLayout: FC<Props> = () => {
    const { pathname, hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.replace('#', ''));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
                return
            }
        }

        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Fragment>
            <NavigationBar />
            <Box minHeight='calc( 100vh - 60px )'>
                <Outlet />
            </Box>
            <FooterBar />
            <WhatsAppButton />
        </Fragment>
    );
}

export default ShopLayout;
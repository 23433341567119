import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAppSelector } from '../hooks';
import { LoadingCard } from '../components';

interface Props {
}

const PrivateRoute: FC<Props> = () => {
    const { pathname } = useLocation();

    const { user } = useAppSelector(state => state.session);

    if (user === undefined) {
        return <LoadingCard message='Verificando datos de sesión' />
    }

    return (
        <>
            {
                user 
                    ? <Outlet />
                    : <Navigate to={`/auth?prev=${pathname}`} replace />
            }
        </>
    );
}

export default PrivateRoute;
import { FC } from 'react';
import { useAppSelector } from '../../hooks';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Skeleton from '@mui/material/Skeleton';

interface Props {
}

const DataDisplay: FC<Props> = () => {
    const { product } = useAppSelector(state => state.product);

    if (!product) {
        return (
            <Box>
                <Skeleton variant='rectangular' width='100%' height={120} sx={{ marginBottom: '1rem' }} />
                <Skeleton variant='rectangular' width='100%' height={120} sx={{ marginBottom: '1rem' }} />
                <Skeleton variant='rectangular' width='100%' height={120} sx={{ marginBottom: '1rem' }} />
            </Box>
        );
    }

    return (
        <Box>
            <Typography align='justify'>
                {product.description}
            </Typography>
            {
                product.features.length > 0
                    ? <Box marginTop='1rem'>
                        <Typography fontWeight='bold'>Características</Typography>
                        {
                            product.features.map((feature, index) =>
                                <li key={`feature_${index}`} style={{ listStylePosition: 'inside' }} >
                                    <Typography display='inline'>
                                        {feature}
                                    </Typography>
                                </li>
                            )
                        }
                    </Box>
                    : null
            }

        </Box>
    );
}

export default DataDisplay;
import { FormEvent, useState } from 'react';

import useForm from './useForm';
import useAppDispatch from './useAppDispatch';
import { LoginFormData } from '../interfaces';
import { logIn } from '../redux/slices/session';

const useLoginForm = () => {

    const [error, setError] = useState(false);

    const dispatch = useAppDispatch();
    const { data, handleInputChange } = useForm<LoginFormData>({
        email: '',
        password: '',
    });

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setError(false);
        dispatch(logIn(data, () => {
            setError(true);
        }));
    }

    return {
        data,
        error,
        handleInputChange,
        handleSubmit,
    };
}

export default useLoginForm;
import { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { LoginForm, RegisterForm, TabSelector } from '../components/auth';
import { useDesignConstants } from '../hooks';


interface Props {
}

const Auth: FC<Props> = () => {
    const [searchParams] = useSearchParams();
    const [tab, setTab] = useState(searchParams.get('opt') === 'login' ? 1 : 0);

    const { PaperContent } = useDesignConstants();

    return (
        <Box width='100%' marginY='2.5rem' display='flex' justifyContent='center' alignItems='center'>
            <Paper elevation={4} sx={PaperContent}>
                <Box padding='1rem'>
                    <TabSelector tab={tab} onChange={(t) => setTab(t)} />
                    <Box margin='0 auto' width={{ xs: '90%', sm: '60%', md: '50%' }}>
                        {
                            tab === 0
                                ? <RegisterForm />
                                : <LoginForm />
                        }
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}

export default Auth;
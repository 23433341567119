import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UISliceState {
    loading: boolean;
}

const initialState: UISliceState = {
    loading: false,
}

const uiSlice = createSlice({
    name: 'UI',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
        },
        finishLoading(state) {
            state.loading = false;
        }
    },
});

export const {
    startLoading,
    finishLoading,
} = uiSlice.actions;

export default uiSlice.reducer;
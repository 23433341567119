import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { colors } from '../../themes';

interface Props {
    title: string;
    path: string;
    icon: ReactNode;
}

const RightIcon: FC<Props> = ({ title, path, icon }) => {
    return (
        <Tooltip title={title}>
            <Link to={path} style={{ color: colors.white }}>
                <IconButton color='inherit'>
                    {icon}
                </IconButton>
            </Link>
        </Tooltip>
    );
}

export default RightIcon;
import { useMediaQuery, useTheme } from '@mui/material';

const useBreakpoints = () => {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const isDesktop = useMediaQuery(theme.breakpoints.up(965));

    return {
        isMobile,
        isDesktop,
    }
}

export default useBreakpoints;
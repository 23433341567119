import { FC, useEffect, useState } from 'react';
import { LoadingOverlay, ShopPageLayout } from '../components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { CartSummary, ProductsList } from '../components/cart';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import { useAppDispatch, useAppSelector } from '../hooks';
import { validateShoppingCart } from '../redux/slices/cart';

interface Props {
}

const Cart: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { loading } = useAppSelector(state => state.ui);
    const { cart } = useAppSelector(state => state.cart);

    const [cartChecked, setCartChecked] = useState(false);

    useEffect(() => {
        if (cart.length > 0 && !cartChecked) {
            dispatch(validateShoppingCart());
            setCartChecked(true);
        }
    }, [cart]);

    return (
        <ShopPageLayout name='Carrito de Compra' paths={[{ name: 'Tienda', path: '/tienda' }]}>
            <Box>
                <LoadingOverlay open={loading} />
                <Grid container spacing='1rem' paddingY='1rem' marginBottom='1rem'>
                    <Grid item xs={12} sm={8}>
                        <Slide in={true} direction='right' timeout={500}>
                            <Box>
                                <ProductsList />
                            </Box>
                        </Slide>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Fade in={true} timeout={500}>
                            <Box>
                                <CartSummary />
                            </Box>
                        </Fade>
                    </Grid>
                </Grid>
            </Box>
        </ShopPageLayout>
    );
}

export default Cart;
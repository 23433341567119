import { CSSProperties, FC } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { SxProps } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import EmailIcon from '@mui/icons-material/Email';

import { colors } from '../../themes';
import { useBarsData } from '../../hooks';
import FooterLink from './FooterLink';
import FooterSection from './FooterSection';
import FooterMessage from './FooterMessage';
import FooterIconButton from './FooterIconButton';
import { TikTokIcon } from '../ui';

interface Props {
}

interface FooterAction {
    title: string;
    href: string;
    icon: JSX.Element;
}

const footerStyle: CSSProperties = {
    backgroundColor: colors.footerBg,
    color: colors.footer,
};

const footerContainerStyle: SxProps = {
    width: {
        xs: '100%',
        md: '95%',
        lg: '80%'
    },
};

const footerGridStyle: CSSProperties = {
    padding: '1.5rem',
};



const FooterBar: FC<Props> = () => {
    const { navBarPages, footerInfoPages, copyright } = useBarsData();

    const socials: FooterAction[] = [
        {
            title: 'Pinturas Osel Famosas',
            href: 'https://www.facebook.com/famosasbcs',
            icon: <FacebookIcon sx={{ width: '2rem', height: '2rem' }} />,
        },
        {
            title: '@famosasbcs',
            href: 'https://www.instagram.com/famosasbcs/',
            icon: <InstagramIcon sx={{ width: '2rem', height: '2rem' }} />,
        },
        {
            title: '@pinturasfamosas',
            href: 'https://www.tiktok.com/@pinturasfamosas',
            icon: <TikTokIcon color='white' width='2rem' height='2rem' />,
        }
    ]

    const contacts: FooterAction[] = [
        {
            title: 'contacto@pinturasfamosas.com.mx',
            href: 'mailto:contacto@pinturasfamosas.com.mx',
            icon: <EmailIcon sx={{ width: '2rem', height: '2rem' }} />
        },
        {
            title: '612 159 9120',
            href: 'tel:6121599120',
            icon: <PhoneEnabledIcon sx={{ width: '2rem', height: '2rem' }} />,
        }
    ];

    return (
        <Box display='flex' justifyContent='center' sx={footerStyle}>
            <Box sx={footerContainerStyle}>
                <Grid container sx={footerGridStyle}>
                    <FooterSection title='Tienda Famosas'>
                        {
                            navBarPages.map(({ title, path }, id) =>
                                <FooterLink key={`footer_page_${id}`} title={title} path={path} />
                            )
                        }
                    </FooterSection>
                    <FooterSection title='Síguenos'>
                        {
                            socials.map((props, index) =>
                                <FooterIconButton key={`social_${index}`}  {...props} />
                            )
                        }
                    </FooterSection>
                    <FooterSection title='Contacto'>
                        <Grid container>
                            {
                                contacts.map((props, index) =>
                                    <FooterIconButton key={`contact_${index}`}  {...props} />
                                )
                            }
                        </Grid>
                    </FooterSection>
                    <FooterSection title='Información'>
                        {
                            footerInfoPages.map(({ title, path }, id) =>
                                <FooterLink key={`footer_info_page_${id}`} title={title} path={path} />
                            )
                        }
                    </FooterSection>
                </Grid>
                <Divider />
                <FooterMessage content={copyright} />
            </Box>
        </Box>
    );
}

export default FooterBar;
import { FC } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { abortPayment, setStep } from '../../redux/slices/checkout';
import Grid from '@mui/material/Grid';
import { Wallet } from '@mercadopago/sdk-react';
import Alert from '@mui/material/Alert';

interface Props {
}

const CheckoutPayment: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { preferenceId } = useAppSelector(state => state.checkout);

    const goBackToShippingSettings = () => {
        if (preferenceId) {
            dispatch(abortPayment(preferenceId));
        }
        dispatch(setStep(1));
    }

    return (
        <Slide in={true} direction='right' timeout={500}>
            <Box>
                <Paper elevation={3}>
                    <Box padding='1rem'>
                        <Box display='flex' alignItems='center' paddingBottom='0.25rem'>
                            <Typography marginRight='0.5rem'>
                                Métodos de Pago
                            </Typography>
                        </Box>
                        <Divider />
                        <Box marginTop='0.75rem'>
                            <Alert severity='info'>
                                Tu seguridad es nuestra prioridad. Utilizamos Mercado Pago para procesar tus pagos, lo que significa que no almacenamos información de tarjetas en nuestro sistema. ¡Puedes realizar tus compras con total tranquilidad y confianza en la protección de tus datos financieros!
                            </Alert>
                        </Box>
                        <Box marginTop='1rem'>
                            <Grid container>
                                {
                                    preferenceId
                                        ? <Grid item xs={3}>
                                            <Wallet initialization={{ preferenceId, redirectMode: 'modal' }} />
                                        </Grid>
                                        : null
                                }
                            </Grid>
                        </Box>

                    </Box>
                </Paper>
                <Box marginTop='1rem' display='flex'>
                    <Paper elevation={3}>
                        <Button variant='outlined' onClick={goBackToShippingSettings}>
                            <KeyboardBackspaceIcon fontSize='small' />
                            Volver al Envío
                        </Button>
                    </Paper>
                </Box>
            </Box>
        </Slide>
    );
}

export default CheckoutPayment;
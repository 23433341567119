import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { mainApi } from '../../api';
import { AppThunk } from '../store';
import { finishLoading, startLoading } from './ui';
import { CategoryData, GetStoreData, GetStoreProducts, LineData, StoreData, StoreProduct, StoreProductsParams } from '../../interfaces';

interface StoreState {
    stores: StoreData[];
    categories: CategoryData[];
    lines: LineData[];
    selectedStore: number;
    selectedCategory: number;
    selectedLine: number;
    selectedOrder: '' | 'price_desc' | 'price_asc' | 'name_desc' | 'name_asc';
    products: StoreProduct[];
    page: number;
}

const initialState: StoreState = {
    stores: [],
    categories: [],
    lines: [],
    selectedStore: 0,
    selectedCategory: 0,
    selectedLine: 0,
    selectedOrder: '',
    products: [],
    page: 1,
};

const storeSlice = createSlice({
    name: 'Store',
    initialState,
    reducers: {
        setStores(state, { payload }: PayloadAction<StoreData[]>) {
            state.stores = payload;
        },
        setCategories(state, { payload }: PayloadAction<CategoryData[]>) {
            state.categories = payload;
        },
        setLines(state, { payload }: PayloadAction<LineData[]>) {
            state.lines = payload;
        },
        setSelectedStore(state, { payload }: PayloadAction<number>) {
            state.selectedStore = payload;
        },
        setSelectedCategory(state, { payload }: PayloadAction<number>) {
            state.selectedCategory = payload;
        },
        setSelectedLine(state, { payload }: PayloadAction<number>) {
            state.selectedLine = payload;
        },
        setSelectedOrder(state, { payload }: PayloadAction<'' | 'price_desc' | 'price_asc' | 'name_desc' | 'name_asc'>) {
            state.selectedOrder = payload;
        },
        resetSelections(state) {
            state.selectedStore = 0;
            state.selectedCategory = 0;
            state.selectedLine = 0;
            state.selectedOrder = '';
            state.page = 1;
        },
        setProducts(state, { payload }: PayloadAction<StoreProduct[]>) {
            state.products = payload;
        },
        setPage(state, { payload}: PayloadAction<number>) {
            state.page = payload;
        },
        setStoreData(state, { payload }) {
            state.stores = payload.stores;
            state.categories = payload.categories;
            state.lines = payload.lines
        },
    },
});

export const {
    setStores,
    setCategories,
    setLines,
    setSelectedStore,
    setSelectedCategory,
    setSelectedLine,
    setSelectedOrder,
    resetSelections,
    setProducts,
    setPage,
    setStoreData,
} = storeSlice.actions;

export const getStoreData = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetStoreData>('/ecommerce/store');

            dispatch(setStoreData(data));
            dispatch(finishLoading());
        } catch (error) {
            // console.log('getStoreData', error);
            dispatch(finishLoading());
        }
    }
}

export const getStoreProducts = (idStore: number, idCategory: number, idLine: number): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            let params: StoreProductsParams = {};

            if (idStore !== 0)
                params.idStore = idStore;
            if (idCategory !== 0)
                params.idCategory = idCategory;
            if (idLine !== 0)
                params.idLine = idLine;

            const { data } = await mainApi.get<GetStoreProducts>('/ecommerce/products', { params });

            dispatch(setProducts(data.products));
            dispatch(finishLoading());
        } catch (error) {
            // console.log('getStoreProducts', error);
            dispatch(setProducts([]));
            dispatch(finishLoading());
        }
    }
}

export default storeSlice.reducer;
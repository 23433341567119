import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Formatter } from '../utils';
import { ShopPageLayout } from '../components/layouts';
import { getProductData, setProduct } from '../redux/slices/product';
import { useAppDispatch, useAppSelector } from '../hooks';
import { DataDisplay, ImageDisplay, ProductSelector } from '../components/product';
import { ProductVariant } from '../interfaces';

interface Props {
}

const Product: FC<Props> = () => {
    const { slug } = useParams();
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const { product } = useAppSelector(state => state.product);

    useEffect(() => {
        dispatch(getProductData(slug as string, () => navigate('/tienda')));

        return () => {
            dispatch(setProduct(undefined));
        }
    }, []);

    const [selectedVariant, setSelectedVariant] = useState<ProductVariant | null>(null);

    useEffect(() => {
        if (product)
            setSelectedVariant(product.variants[0]);
    }, [product])

    return (
        <ShopPageLayout
            paths={[{ name: 'Tienda', path: '/tienda' }]}
            name={(product?.name && Formatter.capitalize(product.name)) || ''}
            showSkeleton
        >
            <Box marginY='1rem'>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={5}>
                        <ImageDisplay variant={selectedVariant} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DataDisplay />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <ProductSelector
                            variant={selectedVariant}
                            onVariantClick={(v) => setSelectedVariant(v)}
                        />
                    </Grid>
                </Grid>
            </Box>
        </ShopPageLayout>
    );
}

export default Product;
import { FC } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { useAppSelector } from '../../hooks';
import Fade from '@mui/material/Fade';

interface Props {
}

const steps = ['Carrito', 'Envío', 'Pago']

const CheckoutStepper: FC<Props> = () => {
    const { step } = useAppSelector(state => state.checkout);

    return (
        <Paper elevation={4}>
            <Fade in={true} timeout={500}>
                <Box padding='1rem' marginBottom='1.25rem'>
                    <Stepper activeStep={step}>
                        {
                            steps.map((label) =>
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            )
                        }
                    </Stepper>
                </Box>
            </Fade>
        </Paper>
    );
}

export default CheckoutStepper;
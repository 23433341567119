import { FC, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { GalleryColor, ProductColors } from '../../interfaces';
import { colors } from '../../themes';
import { AppModal } from '../ui';
import ColorBox from './ColorBox';
import ColorSearchBar from './ColorSearchBar';
import Alert from '@mui/material/Alert';
import ColorGallery from './ColorGallery';


interface Props {
    colorOptions: ProductColors;
    groups: string[];
    color?: GalleryColor | null;
    onColorPicked: (color: GalleryColor) => void;
    usesSpecialColors?: boolean;
}

const ColorPickerModal: FC<Props> = ({ color = null, colorOptions, groups, onColorPicked, usesSpecialColors = false }) => {
    const [modalActive, setModalActive] = useState(false);
    const [search, setSearch] = useState('');

    const closeModal = () => {
        setModalActive(false);
    }

    const colorfilter = (color: GalleryColor, searchString: string) => {
        return color.name.toLowerCase().includes(searchString) || color.code.toLowerCase().includes(searchString);
    }

    const displayedColors: ProductColors = useMemo(() => {
        if (search.trim().length > 0) {
            return {
                millennium: colorOptions.millennium.filter(c => colorfilter(c, search.toLowerCase())),
                historic: colorOptions.historic.filter(c => colorfilter(c, search.toLowerCase())),
                tranquility: colorOptions.tranquility.filter(c => colorfilter(c, search.toLowerCase())),
                transicion: colorOptions.transicion.filter(c => colorfilter(c, search.toLowerCase())),
                industry: colorOptions.industry.filter(c => colorfilter(c, search.toLowerCase())),
                line: colorOptions.line.filter(c => colorfilter(c, search.toLowerCase())),
            }
        }
        return colorOptions;
    }, [colorOptions, search]);

    const pickColorAndCloseModal = (color: GalleryColor) => {
        onColorPicked(color);
        setModalActive(false)
    }

    return (
        <>
            <Box
                width='100%'
                padding='0.5rem'
                border={`1px ${colors.primary} solid`}
                borderRadius={1}
                display='flex'
                alignItems='center'
                sx={{ cursor: 'pointer', opacity: modalActive ? 0.5 : 1 }}
                onClick={() => setModalActive(true)}
            >
                {
                    color
                        ? <>
                            <Box width='3rem' height='1.5rem' sx={{ backgroundColor: `rgb${color.rgb}` }} />
                            <Typography textTransform='uppercase' marginLeft='0.5rem' fontWeight='500'>
                                {`Color: ${color.code}`}
                            </Typography>
                        </>
                        : <>
                            <Skeleton variant='rectangular' width='3rem' />
                            <Typography textTransform='uppercase' marginLeft='0.5rem' fontWeight='500'>
                                Seleccionar color
                            </Typography>
                        </>
                }
            </Box>
            <AppModal open={modalActive} onClose={closeModal} title='Selección de Color' containerStyle={{ width: { xs: '90%', md: '80%', lg: '60%' } }}>
                <Box height='70vh' overflow='auto' sx={{ overflowX: 'hidden' }}>
                    <Box marginBottom='1rem' width={{ xs: '100%', md: '50%' }} paddingTop='0.3rem'>
                        <ColorSearchBar onChangeText={(text) => setSearch(text)} />
                    </Box>
                    <Box marginTop='0.75rem' marginBottom='1rem'>
                        <Alert severity='info'>
                            Aviso: los colores podrían lucir diferentes por la calibración de color en la pantalla de su dispositivo.
                        </Alert>
                    </Box>
                    {
                        displayedColors.millennium.length > 0
                            ? <Box>
                                {
                                    groups.map((group) =>
                                        <Box key={group} marginBottom='2.5rem'>
                                            <Grid container spacing={1 / 2} >
                                                {
                                                    displayedColors.millennium.filter(({ code, isSpecial }) => code.endsWith(group) && !isSpecial).map(color =>
                                                        <Grid item xs={4} md={2} key={color.idColor}>
                                                            <ColorBox color={color} onClick={pickColorAndCloseModal} />
                                                        </Grid>
                                                    )
                                                }
                                            </Grid>
                                        </Box>
                                    )
                                }
                                {
                                    displayedColors.historic.length > 0
                                        ? <ColorGallery
                                            title='Colores Históricos'
                                            colors={displayedColors.historic}
                                            onColorClick={pickColorAndCloseModal}
                                        />
                                        : null
                                }
                                {
                                    displayedColors.tranquility.length > 0
                                        ? <ColorGallery
                                            title='Galería Tranquility'
                                            colors={displayedColors.tranquility}
                                            onColorClick={pickColorAndCloseModal}
                                        />
                                        : null
                                }
                                {
                                    displayedColors.transicion.length > 0
                                        ? <ColorGallery
                                            title='Galería Transicion'
                                            colors={displayedColors.transicion}
                                            onColorClick={pickColorAndCloseModal}
                                        />
                                        : null
                                }
                                {
                                    displayedColors.industry.length > 0
                                        ? <ColorGallery
                                            title='Colores Industriales'
                                            colors={displayedColors.industry}
                                            onColorClick={pickColorAndCloseModal}
                                        />
                                        : null
                                }
                                {
                                    displayedColors.line.length > 0
                                        ? <ColorGallery
                                            title='Colores de Línea'
                                            colors={displayedColors.line}
                                            onColorClick={pickColorAndCloseModal}
                                        />
                                        : null
                                }
                                {
                                    usesSpecialColors && displayedColors.millennium.filter(({ isSpecial }) => isSpecial).length > 0
                                        ? <ColorGallery
                                            title='Colores Especiales'
                                            colors={displayedColors.millennium.filter(({ isSpecial }) => isSpecial)}
                                            onColorClick={pickColorAndCloseModal}
                                        />
                                        : null
                                }
                            </Box>
                            : <Typography textAlign='center'>
                                NO HAY COLORES DISPONIBLES.
                            </Typography>
                    }
                </Box>
            </AppModal>
        </>
    );
}

export default ColorPickerModal;
import { CSSProperties, FC, FormEvent, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { colors } from '../../themes';
import { useOnView } from '../../hooks';

interface Props {
}

const SearchEntry: FC<Props> = () => {
    const navigate = useNavigate();
    const ref = useRef<HTMLElement>(null)
    const [search, setSearch] = useState('');

    const isVisible = useOnView(ref);

    const containerStyle: CSSProperties = {
        backgroundColor: colors.primary,
        borderRadius: 2,
    }

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();
        navigate(`/tienda?s=${search}`)
    }

    return (
        <Box ref={ref} position='relative' display='flex' justifyContent='center' height={{ xs: '15rem', sm: '9rem', lg: '8rem' }}>
            <Slide direction='down' in={isVisible} timeout={500} container={ref.current}>
                <Box
                    position='absolute'
                    top='-95%'
                    width={{ xs: '95%', md: '80%', lg: '70%' }}
                    padding='1rem'
                    sx={containerStyle}
                >
                    <Typography variant='h3' component='h1' align='center' fontWeight='400' color={colors.white}>
                        ¿Qué estás buscando?
                    </Typography>
                    <Typography marginY='1rem' align='center' color={colors.white}>
                        Nos dedicamos a comercializar pinturas, recubrimientos e impermeabilizantes de la más alta calidad, asi como a la venta de los accesorios necesarios para obtener el mejor resultado en la aplicación.
                    </Typography>
                    <Box component='form' onSubmit={onSubmit}>
                        <TextField
                            variant='outlined'
                            size='small'
                            value={search}
                            onChange={({ target }) => setSearch(target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        {
                                            search.length > 0
                                                ? <IconButton size='small' type='button' onClick={() => setSearch('')}>
                                                    <CloseIcon />
                                                </IconButton>
                                                : null
                                        }
                                        <IconButton size='small' type='submit'>
                                            <SearchOutlinedIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            placeholder='Busca entre nuestros productos...'
                            sx={{
                                backgroundColor: colors.white,
                                borderRadius: 1,
                            }}
                            fullWidth
                        />
                    </Box>
                </Box>
            </Slide>
        </Box>
    );
}

export default SearchEntry;
import { FC, useEffect } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { useAppDispatch, useAppSelector, useDesignConstants } from '../hooks';
import { HomeCarousel, HomeSection, HomeStoreProducts } from '../components';
import { getHomeData } from '../redux/slices/home';

interface Props {
}

const Home: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { loading } = useAppSelector(state => state.ui);
    const { stores, banners } = useAppSelector(state => state.home);

    const { HomeContent } = useDesignConstants();

    useEffect(() => {
        dispatch(getHomeData());
    }, []);

    return (
        <>
            <HomeCarousel banners={banners} />
            <Box sx={HomeContent}>
                <HomeSection title='Productos Destacados'>
                    {
                        stores.length === 0
                            ? <Box display='flex' justifyContent='center'>
                                {
                                    loading
                                        ? <CircularProgress color='primary' />
                                        : <Typography>No hay datos disponibles.</Typography>
                                }
                            </Box>
                            : stores.map(({ products, ...store }, index) =>
                                <HomeStoreProducts
                                    key={`store_${index}`}
                                    direction={index % 2 === 0 ? 'right' : 'left'}
                                    store={store}
                                    products={products}
                                />
                            )
                    }
                </HomeSection>
            </Box>
        </>
    );
}

export default Home;
import { FC } from 'react';
import Typography from '@mui/material/Typography';

interface Props {
    message?: string;
}

const PromotionIndicator: FC<Props> = ({ message = 'PROMOCIÓN'}) => {
    return (
        <Typography variant='body2' fontWeight='800' paddingX='1rem' color='white' position='absolute'  top={5} right={5} borderRadius={1} sx={{ backgroundColor: 'red' }}>
            {message}
        </Typography>
    );
}

export default PromotionIndicator;
import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

import {
    accountReducer,
    cartReducer,
    checkoutReducer,
    homeReducer,
    inspirationReducer,
    invoicingReducer,
    ordersReducer,
    productReducer,
    sessionReducer,
    storeReducer,
    uiReducer
} from "./slices";

const store = configureStore({
    reducer: {
        ui: uiReducer,
        home: homeReducer,
        inspiration: inspirationReducer,
        store: storeReducer,
        session: sessionReducer,
        account: accountReducer,
        product: productReducer,
        cart: cartReducer,
        checkout: checkoutReducer,
        orders: ordersReducer,
        invoicing: invoicingReducer
    },
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export default store;
import { FC } from 'react';
import Typography from '@mui/material/Typography';

interface Props {
    content: string;
}

const FooterMessage: FC<Props> = ({ content }) => {
    return (
        <Typography align='center' paddingY='0.5rem'>
            {content}
        </Typography>
    );
}

export default FooterMessage;
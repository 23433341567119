import { FC, useMemo } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';

import { CartProduct } from '../../interfaces';
import { colors } from '../../themes';
import { Formatter, Parser } from '../../utils';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { deleteProductFromCart } from '../../redux/slices/cart';
import { Link } from 'react-router-dom';

interface Props {
    data: CartProduct;
}

const ProductCard: FC<Props> = ({ data }) => {
    const dispatch = useAppDispatch();
    const { municipality } = useAppSelector(state => state.session);

    const price = useMemo(() => {
        const { generalPrice, localPrice, foreignPrice } = data.variant.price;
        if (!municipality) return generalPrice;
        return municipality === '1' ? localPrice : foreignPrice;
    }, [data, municipality]);

    return (
        <Box marginBottom='1rem'>
            <Grid container paddingY='0.5rem' spacing={1}>
                <Grid item xs={2} md={2}>
                    <img
                        src={data.image}
                        alt={data.name}
                        style={{
                            width: '100%',
                            objectFit: 'contain',
                            border: `1px ${colors.primary} solid`,
                            borderRadius: '3px',
                        }}
                    />
                </Grid>
                <Grid item xs={10} md={8}>
                    <Link to={`/productos/${data.slug}`}>
                        <Typography variant='h5' color='primary'>
                            {Formatter.capitalize(data.name)}
                        </Typography>
                    </Link>
                    <Chip color='primary' label={data.variant.measurement} sx={{ marginRight: '0.25rem' }} />
                    {
                        data.variant.paintSheen
                            ? <Chip color='primary' label={data.variant.paintSheen.name} />
                            : null
                    }
                    <Box marginTop='1rem' display='flex'>
                        <Box width='fit-content' padding='0.25rem 1rem' border={`1px ${colors.primary} solid`} borderRadius={1} marginRight='0.5rem'>
                            <Typography variant='body2' textTransform='uppercase'>
                                Cantidad: {data.quantity}
                            </Typography>
                        </Box>
                        {
                            data.variant.color
                                ? <Box display='flex' width='fit-content' padding='0.25rem 1rem' border={`1px ${colors.primary} solid`} borderRadius={1}>
                                    <Typography variant='body2' marginRight='0.5rem' textTransform='uppercase'>
                                        Color: {data.variant.color.code}
                                    </Typography>
                                    <Box width='3rem' height='100%' sx={{ backgroundColor: `rgb${data.variant.color.rgb}` }} />
                                </Box>
                                : null
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Box height='100%' display='flex' flexDirection={{ xs: 'row-reverse', md: 'column' }} justifyContent='space-between' alignItems='end'>
                        <Box display='flex' flexDirection='column' alignItems='end'>
                            <Box display='flex' alignItems='end' height='100%'>
                                {
                                    data.variant.discount
                                        ? <Typography variant='body2' marginLeft='0.25rem' sx={{ textDecorationLine: 'line-through' }}>
                                            {Parser.parseToPrice(price)}
                                        </Typography>
                                        : null
                                }
                            </Box>
                            <Typography component='h3' variant='h5' color={data.variant.discount ? 'red' : 'primary'}>
                                {Parser.parseToPrice(data.variant.discount ? price - (price * (data.variant.discount / 100)) : price)}
                            </Typography>
                        </Box>
                        <Tooltip title='Borrar del carrito'>
                            <IconButton onClick={() => dispatch(deleteProductFromCart(data))}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Grid>
            </Grid>
            <Divider />
        </Box>
    );
}

export default ProductCard;
import { FC } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SettingsIcon from '@mui/icons-material/Settings';

import { colors } from '../../themes';
import { Formatter } from '../../utils';
import { logOut } from '../../redux/slices/session';
import { useAppDispatch, useAppSelector } from '../../hooks';

interface Props {
}

const AccountDetailsCard: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(state => state.session);

    return (
        <Paper elevation={3}>
            <Box padding='1rem' position='relative'>
                <Box position='absolute' top={5} right={5}>
                    <Link to='/cuenta/ajustes'>
                        <IconButton>
                            <SettingsIcon />
                        </IconButton>
                    </Link>
                </Box>
                <Typography component='h3' variant='h5'>
                    Detalles
                </Typography>
                <Box marginTop='0.5rem' padding='0.5rem' borderRadius={1} sx={{ backgroundColor: colors.cardBgAlt }}>
                    <Typography noWrap>
                        {`${user?.firstName} ${user?.lastName}`}
                    </Typography>
                    <Typography noWrap>
                        {Formatter.formatPhoneNumber(user!.phoneNumber)}
                    </Typography>
                    <Typography noWrap>
                        {user?.email}
                    </Typography>
                </Box>
                <Box display='flex' justifyContent='end' marginTop='0.5rem'>
                    <Button variant='outlined' color='error' size='small' onClick={() => dispatch(logOut())}>
                        <LogoutOutlinedIcon fontSize='small' sx={{ marginRight: '0.5rem' }} />
                        Cerrar Sesión
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
}

export default AccountDetailsCard;
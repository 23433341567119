import { createSlice } from '@reduxjs/toolkit';

import { CartProduct, PostValidateCart } from '../../interfaces';
import { AppThunk } from '../store';
import { mainApi } from '../../api';
import { finishLoading, startLoading } from './ui';

interface CartState {
    cart: CartProduct[];
}

const initialState: CartState = {
    cart: [],
};

const name = 'pfamosas-cart';

const cartSlice = createSlice({
    name: 'Cart',
    initialState,
    reducers: {
        setCart(state, { payload }) {
            state.cart = payload;
        }
    },
});

export const {
    setCart
} = cartSlice.actions;

export const syncShoppingCart = (): AppThunk => {
    return async (dispatch) => {
        try {
            const localStorageCart = localStorage.getItem(name);
            if (localStorageCart) {
                dispatch(setCart(JSON.parse(localStorageCart)));
            }
        } catch (error) {
            // console.log('syncShoppingCart', error);
        }
    }
}

export const validateShoppingCart = (): AppThunk => {
    return async (dispatch, getState) => {
        try {
            const { cart } = getState().cart;

            dispatch(startLoading());

            const { data } = await mainApi.post<PostValidateCart>('/ecommerce/cart/validate', { cart });

            localStorage.setItem(name, JSON.stringify(data.cart));
            dispatch(setCart(data.cart));
            dispatch(finishLoading());
        } catch (error) {
            // console.log('validateShoppingCart', error);
            dispatch(finishLoading());
        }
    }
}

export const addProductToCart = (data: CartProduct): AppThunk => {
    return async (dispatch, getState) => {
        try {
            const { cart } = getState().cart;

            if (cart.length <= 0) {
                localStorage.setItem(name, JSON.stringify([data]));
                dispatch(setCart([data]));
                return;
            }

            const productInCart = cart.find(p => p.variant.key === data.variant.key && p.variant.color?.idColor === data.variant.color?.idColor);

            if (productInCart) {
                const newCart: CartProduct[] = cart.map((product) => {
                    if (product.variant.key === data.variant.key) {
                        return { ...product, quantity: product.quantity + data.quantity };
                    }
                    return product;
                });

                localStorage.setItem(name, JSON.stringify(newCart));
                dispatch(setCart(newCart));
                return;
            }

            localStorage.setItem(name, JSON.stringify([...cart, data]));
            dispatch(setCart([...cart, data]));
        } catch (error) {
            // console.log('addProductToCart', error);
        }
    }
}

export const setProductQuantity = (product: CartProduct, quantity: number): AppThunk => {
    return async (dispatch, getState) => {
        try {
            const { cart } = getState().cart;

            if (cart.length <= 0) {
                return;
            }

            const newCart = cart.map((productInCart) => {
                if (productInCart.variant.key === product.variant.key && productInCart.variant.color?.idColor === product.variant.color?.idColor)
                    return { ...productInCart, quantity: quantity >= 1 ? quantity : product.quantity };
                else
                    return productInCart;
            });

            localStorage.setItem(name, JSON.stringify(newCart));
            dispatch(setCart(newCart));
        } catch (error) {
            // console.log('setProductQuantity', error);
        }
    }
}

export const deleteProductFromCart = (product: CartProduct): AppThunk => {
    return async (dispatch, getState) => {
        try {
            const { cart } = getState().cart;

            if (cart.length <= 0) {
                return;
            }

            const newCart: (CartProduct | null)[] = cart.map((productInCart) => {
                if (productInCart.variant.key === product.variant.key && productInCart.variant.color?.idColor === product.variant.color?.idColor) {
                    return null;
                }
                return productInCart;
            }).filter((productInCart) => productInCart !== null);

            localStorage.setItem(name, JSON.stringify(newCart));
            dispatch(setCart(newCart));
        } catch (error) {
            // console.log('deleteProductFromCart', error);
        }
    }
}

export const emptyCart = (): AppThunk => {
    return async (dispatch) => {
        try {
            localStorage.setItem(name, JSON.stringify([]));
            dispatch(setCart([]));
        } catch (error) {
            // console.log('emptyCart', error);
        }
    }
}

export default cartSlice.reducer;
import { FC, useState } from 'react';
import { Box, Fade } from '@mui/material';
import ColorInfo from './ColorInfo';
import { Color } from '../../interfaces';

interface Props {
    img: string;
    alt: string;
    color: Color;
}

const FadeImage: FC<Props> = ({ img, alt, color }) => {
    const [imgActive, setImgActive] = useState(false);
    const [isHovering, setHovering] = useState(false);

    return (
        <Fade in={imgActive} timeout={800}>
            <Box position='relative' onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
                <img
                    src={img}
                    alt={alt}
                    onLoad={() => setImgActive(true)}
                    style={{
                        width: '100%',
                        marginBottom: '1rem',
                        objectFit: 'cover',
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'black',
                        transition: 'all ease-out 0.3s',
                        opacity: isHovering ? 0.5 : 0,
                        '&:hover': {
                            opacity: 0.5,
                        },
                    }}
                />
                <ColorInfo visible={isHovering} color={color} />
            </Box>
        </Fade>
    );
}

export default FadeImage;
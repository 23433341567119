import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { Parser } from '../../utils';
import { useAppSelector } from '../../hooks';

interface Props {
}

const CartSummary: FC<Props> = () => {
    const navigate = useNavigate();

    const { municipality } = useAppSelector(state => state.session);
    const { cart } = useAppSelector(state => state.cart);

    const totalPrice = useMemo(() => {
        let total = 0;
        cart.forEach(({ variant: { price, discount }, quantity }) => {
            if (!municipality) {
                total += (price.generalPrice - (discount ? (price.generalPrice * (discount / 100)) : 0)) * quantity;
                return;
            }
            if (municipality === '1')
                total += (price.localPrice - (discount ? (price.localPrice * (discount / 100)) : 0)) * quantity;
            else
                total += (price.foreignPrice - (discount ? (price.foreignPrice * (discount / 100)) : 0)) * quantity;
        });
        return total;
    }, [cart, municipality]);

    const totalProducts = useMemo(() => {
        let total = 0;
        cart.forEach(({ quantity }) => {
            total += quantity;
        });
        return total;
    }, [cart])

    return (
        <Paper elevation={4}>
            <Box padding='1rem'>
                {
                    totalPrice > 3000
                        ? <Alert severity='success'>
                            Este pedido es elegible para envío GRATIS.
                        </Alert>
                        : null
                }
                <Box marginY='1rem' display='flex' justifyContent='space-between'>
                    <Typography variant='h6' component='p'>
                        {`Subtotal (${totalProducts} producto${totalProducts > 1 ? 's' : ''}):`}
                    </Typography>
                    <Typography variant='h6' component='p'>
                        {Parser.parseToPrice(totalPrice)}
                    </Typography>
                </Box>
                <Button variant='contained' disabled={cart.length <= 0 || totalPrice <= 0} fullWidth onClick={() => navigate('/pago')}>
                    PROCEDER AL PAGO
                </Button>
            </Box>
        </Paper>
    );
}

export default CartSummary;
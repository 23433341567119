import { FC } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import { useAppDispatch, useAppSelector, useStoreConstants } from '../../hooks';
import { setPage } from '../../redux/slices/store';

interface Props {
}

const StorePagination: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { products, page } = useAppSelector(state => state.store);
    const { productsPerPage } = useStoreConstants();

    return (
        <Grid item xs={12}>
            <Box display='flex' justifyContent='center'>
                <Pagination
                    count={Math.ceil(products.length / productsPerPage)}
                    color="primary"
                    size='small'
                    page={page}
                    onChange={(_, page) => dispatch(setPage(page))}
                />
            </Box>
        </Grid>
    );
}

export default StorePagination;
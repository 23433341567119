import { FC, ReactNode } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';

interface Props {
    title: string;
    children: ReactNode | ReactNode[];
}

const sectionStyle: SxProps = {
    marginBottom: { xs: '1rem', md: 0 }
}

const FooterSection: FC<Props> = ({ title, children }) => {
    return (
        <Grid item xs={12} sm={6} md={3} sx={sectionStyle}>
            <Typography variant='h6' component='p'>
                {title}
            </Typography>
            <Box>
                {children}
            </Box>
        </Grid>
    );
}

export default FooterSection;
import { FC } from 'react';
import { OrderProductData } from '../../interfaces';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip, { chipClasses } from '@mui/material/Chip';
import CircleIcon from '@mui/icons-material/Circle';

interface Props {
    data: OrderProductData;
}

const OrderProductCard: FC<Props> = ({ data }) => {
    return (
        <Box display='flex' marginBottom='1rem'>
            <Box width='4rem' marginRight='0.5rem'>
                <img src={data.image} alt={data.name} style={{ aspectRatio: '1 / 1', objectFit: 'contain' }} />
            </Box>
            <Box flex={1}>
                <Typography fontSize='1.05rem'>
                    {data.name}
                </Typography>
                <Chip label={data.measurement} variant='filled' color='primary' sx={{ marginRight: '0.5rem', marginBottom: '0.5rem' }} />
                {
                    data.colorName && data.colorCode && data.colorRgb
                        ? <Chip
                            sx={{ [`& .${chipClasses.icon}`]: { color: `rgb${data.colorRgb}` }, marginRight: '0.5rem', marginBottom: '0.5rem' }}
                            icon={<CircleIcon />}
                            label={`${data.colorName} (${data.colorCode})`}
                        />
                        : null
                }
                {
                    data.paintSheenName
                        ? <Chip label={data.paintSheenName} variant='filled' color='primary'  sx={{ marginRight: '0.5rem', marginBottom: '0.5rem' }} />
                        : null
                }
                <Typography>
                    Cantidad: {data.quantity}
                </Typography>
            </Box>
        </Box>
    );
}

export default OrderProductCard;
import { FC } from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import CodeForm from './CodeForm';

interface Props {
}

const CouponRedeemal: FC<Props> = () => {
    return (
        <Box marginBottom='1rem'>
            <Fade in={true} timeout={500}>
                <Paper elevation={3}>
                    <Box padding='1rem'>
                        <Typography>¿Tienes un código de promoción?</Typography>
                        <CodeForm />
                    </Box>
                </Paper>
            </Fade>
        </Box>
    );
}

export default CouponRedeemal;
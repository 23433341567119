import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import useAppSelector from './useAppSelector';
import useAppDispatch from './useAppDispatch';
import { getStoreProducts, setPage } from '../redux/slices/store';
import { StoreProduct } from '../interfaces';
import useStoreConstants from './useStoreConstants';

const useProductsProvider = () => {
    const [searchParams] = useSearchParams();

    const { productsPerPage } = useStoreConstants();

    const dispatch = useAppDispatch();
    const { selectedStore, selectedCategory, selectedLine, products, selectedOrder, page } = useAppSelector(state => state.store);

    useEffect(() => {
        dispatch(setPage(1));
        dispatch(getStoreProducts(selectedStore, selectedCategory, selectedLine));
    }, [selectedStore, selectedCategory, selectedLine]);

    const displayedProducts = useMemo(() => {
        const s = searchParams.get('s');
        let storeProducts: StoreProduct[] = products;

        if (s && s.trim().length > 0)
            storeProducts = storeProducts.filter((p) => p.name.toLowerCase().includes(s.toLowerCase()));

        if (selectedOrder === 'price_desc')
            storeProducts = [...storeProducts].sort((a, b) => {
                return b.generalPrice - a.generalPrice;
            });
        if (selectedOrder === 'price_asc')
            storeProducts = [...storeProducts].sort((a, b) => {
                return a.generalPrice - b.generalPrice;
            });
        if (selectedOrder === 'name_asc')
            storeProducts = [...storeProducts].sort((a, b) => {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
        if (selectedOrder === 'name_desc')
            storeProducts = [...storeProducts].sort((a, b) => {
                return b.name.toLowerCase().localeCompare(a.name.toLowerCase());
            });

        return storeProducts.slice(((page - 1) * productsPerPage), page * productsPerPage);
    }, [products, searchParams, selectedOrder, page, productsPerPage]);

    return {
        products: displayedProducts,
    };
}

export default useProductsProvider;
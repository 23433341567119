import { FC, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { sendPasswordRecoveryRequest } from '../../redux/slices/account';

interface Props {
}

const PasswordRecoveryForm: FC<Props> = () => {
    const [email, setEmail] = useState('');

    const dispatch = useAppDispatch();
    const { loading } = useAppSelector(state => state.ui);

    const handleSubmit = () => {
        if (email.trim().length <= 0) 
            return;
        dispatch(sendPasswordRecoveryRequest(email));
    }

    return(
        <Grid container spacing={1}>
            <Grid item xs={12} sm={10}>
                <TextField 
                    size='small'
                    label='Correo electrónico'
                    type='email'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    autoComplete='off'
                    fullWidth 
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <Button variant='contained' disabled={loading} fullWidth onClick={handleSubmit}>
                    Enviar
                </Button>
            </Grid>
        </Grid>
    );
}

export default PasswordRecoveryForm;
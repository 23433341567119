import { FC, FormEvent, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';

import AppModal from './AppModal';
import { DataConstants } from '../../utils';
import { InvoiceFormData } from '../../interfaces';
import { useAppDispatch, useAppSelector, useForm } from '../../hooks';
import { requestOrderInvoice } from '../../redux/slices/invoicing';

interface Props {
    open: boolean;
    idOrder: string;
    onClose: VoidFunction;
    onError?: VoidFunction;
    callback?: VoidFunction;
}

const modalContainer: SxProps = {
    width: { xs: '90%', sm: '80%', md: '60%' }
}

const InvoiceRequestModal: FC<Props> = ({ idOrder, open, onClose, onError, callback }) => {
    const modalTitle = useMemo(() => {
        return `Factura del Pedido #${idOrder.split('-')[0].toUpperCase()}`;
    }, [idOrder]);
    const [orderAddressUsed, setOrderAddressUsed] = useState(false);
    const [addressNumbersError, setAddressNumbersError] = useState(false);

    const dispatch = useAppDispatch();
    const { loading } = useAppSelector(state => state.ui);
    const { orders, selectedOrder } = useAppSelector(state => state.orders);

    const { data, handleInputChange, handleSelectChange, reset } = useForm<InvoiceFormData>({
        idOrder: '',
        rfc: '',
        socialReason: '',
        taxRegime: '',
        cfdiUse: '',
        address: '',
        interiorNumber: '',
        exteriorNumber: '',
        zipcode: '',
        neighbourhood: '',
        city: '',
        locality: '',
        state: '',
        country: '',
    });
    const { rfc, socialReason, taxRegime, cfdiUse, address, interiorNumber, exteriorNumber, zipcode, locality, city, neighbourhood, state, country } = data;

    const canAutocompleteAddress = useMemo(() => {
        if (selectedOrder && selectedOrder.idOrder === idOrder)
            return true;
        return Boolean(orders.find(order => order.idOrder === idOrder));
    }, [idOrder, orders, selectedOrder]);

    useEffect(() => {
        if (orderAddressUsed) {
            const order = (selectedOrder ?? orders.find(order => order.idOrder === idOrder))!;
            reset({
                ...data,
                address: order.address1,
                zipcode: order.addressZipcode,
                city: order.addressCity,
                locality: order.addressCity,
                state: 'Baja California Sur',
                country: 'México',
            });
        } else {
            reset({
                ...data,
                address: '',
                interiorNumber: '',
                exteriorNumber: '',
                zipcode: '',
                neighbourhood: '',
                city: '',
                locality: '',
                state: '',
                country: '',
            });
        }
    }, [idOrder, orderAddressUsed]);

    useEffect(() => {
        if (addressNumbersError === true && (interiorNumber.trim().length > 0 || exteriorNumber.trim().length > 0)) {
            setAddressNumbersError(false)
        }
    }, [addressNumbersError, interiorNumber, exteriorNumber]);

    useEffect(() => {
        if (open) {
            reset({
                ...data,
                idOrder,
            });
        } else {
            reset();
            setAddressNumbersError(false);
            setOrderAddressUsed(false);
        }
    }, [open, idOrder]);

    const submitInvoiceRequest = (e: FormEvent) => {
        e.preventDefault();

        if (interiorNumber.trim().length <= 0 && exteriorNumber.trim().length <= 0) {
            setAddressNumbersError(true);
            return;
        }
        dispatch(requestOrderInvoice(data, () => {
            onClose();
            if (callback) callback();
        }, () => {
            if (onError) onError();
        }));
    }

    return (
        <AppModal title={modalTitle} open={open} onClose={onClose} containerStyle={modalContainer}>
            <Box component='form' onSubmit={submitInvoiceRequest}>
                <Typography textAlign='justify'>
                    Para que podamos emitirte una factura de este pedido, necesitamos que nos proporciones los siguientes datos:
                </Typography>
                <Box marginTop='0.5rem' marginBottom='1rem'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                size='small'
                                label='RFC'
                                name='rfc'
                                value={rfc}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 20 }}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                size='small'
                                label='Razón Social'
                                name='socialReason'
                                value={socialReason}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 255 }}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                size='small'
                                label='Régimen Fiscal'
                                name='taxRegime'
                                value={taxRegime}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 255 }}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl size='small' required fullWidth>
                                <InputLabel id='cfdiUseSelectLabel'>Uso CFDI</InputLabel>
                                <Select labelId='cfdiUseSelectLabel' label='Uso CFDI' name='cfdiUse' value={cfdiUse} onChange={handleSelectChange}>
                                    {
                                        DataConstants.cfdiUses.map(value =>
                                            <MenuItem key={value} value={value}>{value}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider sx={{ marginTop: '1rem', marginBottom: '0.5rem' }} />
                    {
                        canAutocompleteAddress
                            ? <Box marginBottom='0.5rem'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size='small'
                                            checked={orderAddressUsed}
                                            onChange={(_, checked) => setOrderAddressUsed(checked)}
                                        />
                                    }
                                    label="Usar datos de la dirección de envío"
                                />
                            </Box>
                            : null
                    }
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                size='small'
                                label='Domicilio'
                                name='address'
                                value={address}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 255 }}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <TextField
                                size='small'
                                label='No. Ext.'
                                name='exteriorNumber'
                                error={addressNumbersError}
                                value={exteriorNumber}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 10 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <TextField
                                size='small'
                                label='No. Int.'
                                name='interiorNumber'
                                error={addressNumbersError}
                                value={interiorNumber}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 10 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                size='small'
                                label='Código Postal'
                                name='zipcode'
                                value={zipcode}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 20 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                size='small'
                                label='Colonia'
                                name='neighbourhood'
                                value={neighbourhood}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 255 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                size='small'
                                label='Localidad'
                                name='locality'
                                value={locality}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 255 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                size='small'
                                label='Ciudad'
                                name='city'
                                value={city}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 255 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                size='small'
                                label='Estado'
                                name='state'
                                value={state}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 255 }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                size='small'
                                label='País'
                                name='country'
                                value={country}
                                onChange={handleInputChange}
                                inputProps={{ maxLength: 255 }}
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                </Box>
                <Box marginTop='1rem' display='flex' justifyContent='end'>
                    <Box width={{ xs: '100%', sm: '35%' }}>
                        <Button variant='contained' type='submit' fullWidth>
                            {
                                loading
                                    ? <CircularProgress size='1.5rem' />
                                    : 'Solicitar Factura'
                            }
                        </Button>
                    </Box>
                </Box>
            </Box>
        </AppModal>
    );
}

export default InvoiceRequestModal;
import { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TextField from '@mui/material/TextField';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { setSelectedOrder } from '../../redux/slices/store';
import { useSearchParams } from 'react-router-dom';
import { StoreOrder } from '../../types';

interface Props {
}

const StoreTopBar: FC<Props> = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isVisible, setVisible] = useState(false);

    const dispatch = useAppDispatch();
    const { selectedOrder } = useAppSelector(state => state.store);

    useEffect(() => {
        setTimeout(() => setVisible(true), 300)
    }, []);

    return (
        <Paper elevation={2}>
            <Box padding='1rem'>
                <Fade in={isVisible} timeout={500}>
                    <Grid container spacing='1rem' justifyContent='space-between'>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant='outlined'
                                name='nav-bar-search'
                                size='small'
                                placeholder='Buscador'
                                aria-label='Buscador'
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <SearchOutlinedIcon />
                                        </InputAdornment>
                                    )
                                }}
                                autoComplete='off'
                                value={searchParams.get('s') ?? ''}
                                onChange={(e) =>
                                    e.target.value.trim().length > 0
                                        ? setSearchParams({ s: e.target.value })
                                        : setSearchParams({})
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl fullWidth size='small'>
                                <InputLabel>Ordenar por:</InputLabel>
                                <Select label="Ordenar por:" value={selectedOrder} onChange={(e) => dispatch(setSelectedOrder(e.target.value as StoreOrder))}>
                                    <MenuItem value='price_desc'>Precio más alto</MenuItem>
                                    <MenuItem value='price_asc'>Precio más bajo</MenuItem>
                                    <MenuItem value='name_asc'>Orden A-Z</MenuItem>
                                    <MenuItem value='name_desc'>Orden Z-A</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Fade>
            </Box>
        </Paper>
    );
}

export default StoreTopBar;
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

import { ShopPageLayout } from '../components/layouts';
import { AccountDetailsForm } from '../components/accountsettings';

interface Props {
}

const AccountSettings: FC<Props> = () => {
    const navigate = useNavigate();

    const [formActive, setFormActive] = useState(false);

    const toggleForm = () => setFormActive(!formActive);

    const goToAccountDeactivation = () => {
        navigate('/cuenta/eliminacion');
    }

    return (
        <ShopPageLayout name='Ajustes' paths={[{ name: 'Mi Cuenta', path: '/cuenta' }]}>
            <Grid container spacing='1rem' flexDirection={{ xs: 'column-reverse', md: 'row' }} marginBottom='2.5rem'>
                <Grid item xs={12} md={8}>
                    <Paper elevation={2}>
                        <Box padding='1rem'>
                            <AccountDetailsForm active={formActive} onClose={() => setFormActive(false)} />
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper elevation={2}>
                        <Box padding='1rem'>
                            <Box>
                                <Button variant='contained' onClick={toggleForm} fullWidth>
                                    Actualizar datos
                                </Button>
                            </Box>
                            <Box marginTop='0.5rem'>
                                <Button variant='outlined' color='error' onClick={goToAccountDeactivation} fullWidth>
                                    Eliminar mi cuenta
                                </Button>
                            </Box>
                            <Box marginTop='0.5rem'>
                                <Button variant='outlined' fullWidth onClick={() => navigate('/cuenta')}>
                                    <KeyboardBackspaceOutlinedIcon fontSize='small' sx={{ marginRight: '0.5rem' }} />
                                    Regresar a detalles
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </ShopPageLayout>
    );
}

export default AccountSettings;
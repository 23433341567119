import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import { fetchOrder, setSelectedOrder } from '../redux/slices/orders';
import { ShopPageLayout } from '../components/layouts';
import { useAppDispatch, useAppSelector } from '../hooks';
import { CancellationModal, DetailedOrderProductCard, GeneralDetailsCard } from '../components/order';
import InvoiceRequestModal from '../components/ui/InvoiceRequestModal';
import { Notification } from '../components';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import { TimeUtils } from '../utils';

interface Props {
}

const Order: FC<Props> = () => {
    const { idOrder } = useParams();
    const navigate = useNavigate();
    const [invoiceDialogActive, setInvoiceDialogActive] = useState(false);
    const [cancelDialogActive, setCancelDialogActive] = useState(false);
    const [showError, setShowError] = useState(false);

    const dispatch = useAppDispatch();
    const { selectedOrder } = useAppSelector(state => state.orders);

    const canCancel = useMemo(() => {
        if (selectedOrder) {
            const hoursElapsed = TimeUtils.getHourDifference(moment(selectedOrder.orderDate).toDate(), new Date());
            return Boolean(selectedOrder.status !== 'completed' && hoursElapsed < 24)
        }
        return false;
    }, [selectedOrder])

    useEffect(() => {
        dispatch(fetchOrder(idOrder ?? '', () => {
            navigate('/cuenta/pedidos', { replace: true });
        }));
    }, []);

    useEffect(() => {
        return () => {
            dispatch(setSelectedOrder(null));
        }
    }, [])

    const statusMessage = useMemo(() => {
        if (!selectedOrder)
            return '';
        if (selectedOrder.status === 'pending')
            return `Pedido el ${moment(selectedOrder.orderDate).format('dddd, DD [de] MMMM')}`;
        if (selectedOrder.status === 'in-progress')
            return `Llegando el ${moment(selectedOrder.deliveryDate).format('dddd, DD [de] MMMM')}`;
        if (selectedOrder.status === 'completed')
            return `Entregado el ${moment(selectedOrder.completionDate).format('dddd, DD [de] MMMM')}`;
        if (selectedOrder.status === 'cancelled')
            return `Cancelado`;
        return '';
    }, [selectedOrder]);

    const openInvoiceDialog = () => {
        setInvoiceDialogActive(true);
    }

    const closeInvoiceDialog = () => {
        setInvoiceDialogActive(false);
    }

    const openCancelDialog = () => {
        if (selectedOrder && (TimeUtils.getHourDifference(moment(selectedOrder.orderDate).toDate(), new Date()) < 24))
            setCancelDialogActive(true);
    }

    const closeCancelDialog = () => {
        setCancelDialogActive(false);
    }

    const goToInvoice = () => {
        if (selectedOrder)
            navigate(`/cuenta/pedidos/${selectedOrder.idOrder}/factura`)
    }


    return (
        <ShopPageLayout
            paths={[{ name: 'Mi Cuenta', path: '/cuenta' }, { name: 'Mis Pedidos', path: '/cuenta/pedidos' }]}
            name='Detalles del Pedido'
            showSkeleton
        >
            <Box marginY='1rem'>
                <Notification
                    open={showError}
                    onClose={() => setShowError(false)}
                    message='Ha ocurrido un error al intentar completar el procedimiento, por favor intente más tarde.'
                    type='error'
                />
                <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom='1rem'>
                    {
                        selectedOrder
                            ? <Typography>
                                Pedido el {moment(selectedOrder?.orderDate).format('DD [de] MMMM [del] YYYY')}
                            </Typography>
                            : <Skeleton variant='text' width={250} />
                    }
                    {
                        selectedOrder
                            ? <Typography textAlign='right'>
                                Pedido #{selectedOrder.idOrder.toUpperCase()}
                            </Typography>
                            : <Skeleton variant='text' width={250} />
                    }
                </Box>
                <Box marginBottom='1rem'>
                    <Slide in={true} direction='right' timeout={500}>
                        <Box>
                            <GeneralDetailsCard />
                        </Box>
                    </Slide>
                </Box>
                <Box>
                    <Slide in={true} direction='right' timeout={500}>
                        <Box>
                            <Paper elevation={4}>
                                <Box padding='1rem'>
                                    {
                                        selectedOrder
                                            ? <Typography variant='h6' component='p' >
                                                {statusMessage}
                                            </Typography>
                                            : <Skeleton variant='rectangular' width={300} height={25} />
                                    }
                                    {
                                        selectedOrder && selectedOrder.deliveryCode
                                            ? <Alert severity='info'>
                                                La contraseña de entrega es: <Typography variant='body2' fontWeight='bold' display='inline' >{selectedOrder.deliveryCode}</Typography>
                                            </Alert>
                                            : null
                                    }
                                    <Box marginTop='0.5rem' display='flex' flexDirection={{ xs: 'column', sm: 'row' }}>
                                        <Box width='70%' marginRight='1rem'>
                                            {
                                                selectedOrder
                                                    ? selectedOrder.products.map((product, index) =>
                                                        <DetailedOrderProductCard key={`${selectedOrder.idOrder}_${index}`} data={product} />
                                                    )
                                                    : <Skeleton variant='rectangular' height={120} />
                                            }
                                        </Box>
                                        {
                                            selectedOrder && selectedOrder.status !== 'cancelled'
                                                ? <Box flex={1}>
                                                    {
                                                        selectedOrder.cancellation === null
                                                            ? <Button
                                                                variant='contained'
                                                                color='inherit'
                                                                disabled={selectedOrder.invoice === 'pending'}
                                                                fullWidth sx={{ marginBottom: '0.5rem' }}
                                                                onClick={selectedOrder.invoice === 'fulfilled' ? goToInvoice : openInvoiceDialog}
                                                            >
                                                                {selectedOrder.invoice === 'pending' ? 'Factura Solicitada' : 'Factura'}
                                                            </Button>
                                                            : null
                                                    }
                                                    {
                                                        canCancel
                                                            ? <Button
                                                                variant='contained'
                                                                color='error'
                                                                onClick={openCancelDialog}
                                                                disabled={selectedOrder.cancellation === 'pending'}
                                                                fullWidth
                                                            >
                                                                {
                                                                    selectedOrder.cancellation === 'pending'
                                                                        ? 'Cancelación Solicitada'
                                                                        : 'Cancelar Pedido'
                                                                }
                                                            </Button>
                                                            : null
                                                    }
                                                </Box>
                                                : null
                                        }

                                    </Box>
                                </Box>
                            </Paper>
                        </Box>
                    </Slide>
                </Box>
                {
                    selectedOrder && selectedOrder.invoice === null
                        ? <InvoiceRequestModal
                            idOrder={selectedOrder.idOrder}
                            open={invoiceDialogActive}
                            onClose={closeInvoiceDialog}
                            onError={() => setShowError(true)}
                            callback={() => dispatch(fetchOrder(idOrder!))}
                        />
                        : null
                }
                {
                    selectedOrder && selectedOrder.status !== 'cancelled' && selectedOrder.status !== 'completed'
                        ? <CancellationModal
                            open={cancelDialogActive}
                            onClose={closeCancelDialog}
                            idOrder={selectedOrder.idOrder}
                            onError={() => setShowError(true)}
                            callback={() => dispatch(fetchOrder(idOrder!))}
                        />
                        : null
                }
            </Box>
        </ShopPageLayout>
    );
}

export default Order;
import { FC } from 'react';
import Grid from '@mui/material/Grid';

import { Generator } from '../../utils';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setSelectedColor } from '../../redux/slices/inspiration';
import ColorCircle from './ColorCircle';

interface Props {
}

const ColorsSelector: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { colors, selectedColor } = useAppSelector(state => state.inspiration);

    return (
        <Grid container justifyContent='center' marginTop={{ xs: '1rem', md: 0 }}>
            {
                colors.length === 0
                    ? Generator.generateArrayFrom(5).map((num) =>
                        <ColorCircle key={`color_placeholder_${num}`} placeholder />
                    )
                    : colors.map(({ idColor, rgb }) =>
                        <ColorCircle
                            key={`color_${idColor}`}
                            color={`rgb${rgb}`}
                            selected={selectedColor === idColor}
                            onClick={() => dispatch(setSelectedColor(idColor))}
                        />
                    )
            }
        </Grid>
    );
}

export default ColorsSelector;
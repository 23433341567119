import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useBarsData } from '../../hooks';
import { colors } from '../../themes';

interface Props {
}

const MainPagesNavigation: FC<Props> = () => {
    const { pathname } = useLocation();

    const { navBarPages } = useBarsData();

    return (
        <Grid container justifyContent='center' alignItems='center' marginX='1.5rem'>
            <nav>
                {
                    navBarPages.map(({ title, path }, index) =>
                        <Link key={`navbar_page_${index}`} to={path}>
                            <Typography
                                variant='h6'
                                display='inline'
                                marginRight='1rem'
                                fontWeight='normal'
                                component='p'
                                sx={{
                                    color: pathname === path ? colors.white : '#c2c2c2',
                                    borderBottom: pathname === path ? `2px ${colors.secondary} solid` : undefined,
                                    transition: 'all ease-in 0.1s',
                                    '&:hover': {
                                        color: colors.white,
                                        borderBottom: `2px ${colors.secondary} solid`
                                    }
                                }}
                                color='inherit'
                            >
                                {title}
                            </Typography>
                        </Link>
                    )
                }
            </nav>
        </Grid>
    );
}

export default MainPagesNavigation;
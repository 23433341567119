import { useCallback, useEffect, useMemo } from 'react';
import Cookie from 'js-cookie';

import useAppDispatch from './useAppDispatch';
import { setMunicipality, setZipcode, verifySession } from '../redux/slices/session';

const useSessionManagement = () => {
    const dispatch = useAppDispatch();

    const municipality = useMemo(() => {
        return Cookie.get('m');
    }, [Cookie.get('m')]);

    const zipcode = useMemo(() => {
        return Cookie.get('zp');
    }, [Cookie.get('zp')]);

    const checkSessionData = useCallback(() => {
        dispatch(verifySession());
    }, []); 

    useEffect(() => {
        if (municipality)
            dispatch(setMunicipality(municipality));
    }, [municipality]);

    useEffect(() => {
        if (zipcode)
            dispatch(setZipcode(zipcode));
    }, [zipcode]);

    return {
        checkSessionData
    };
}

export default useSessionManagement;
import { FC, Fragment } from 'react';
import Grid from '@mui/material/Grid';

import { useBarsData } from '../../hooks';
import { Link } from 'react-router-dom';

interface Props {
}

const BarLogos: FC<Props> = () => {
    const { navBarLogos } = useBarsData();

    return (
        <Link to='/'>
            <Grid container justifyContent='center' alignItems='center' wrap='nowrap'>
                {
                    navBarLogos.map(({ mobileWidth, desktopWidth, title, pngPath, avifPath, webpPath }, index) =>
                        <Grid key={`navbar_logo_${index}`} item sx={{ width: { xs: mobileWidth, lg: desktopWidth } }}>
                            <picture>
                                {avifPath && <source srcSet={avifPath} type='image/avif' />}
                                {webpPath && <source srcSet={webpPath} type="image/webp" />}
                                <img src={pngPath} alt={title} />
                            </picture>
                        </Grid>
                    )
                }
            </Grid>
        </Link>
    );
}

export default BarLogos;
import { FC } from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

interface Props {
    onClick?: VoidFunction;
}

const MobileMenuButton: FC<Props> = ({ onClick }) => {
    return (
        <Grid item>
            <IconButton color='inherit' onClick={onClick}>
                <MenuIcon />
            </IconButton>
        </Grid>
    );
}

export default MobileMenuButton;
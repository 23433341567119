import { FC } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { AddressCard } from '../account';
import { AddressForm } from '../accountaddresses';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { deleteAddress, setSelectedAddress } from '../../redux/slices/account';
import { UserAddress } from '../../interfaces';
import { setShippingAddress } from '../../redux/slices/checkout';

interface Props {
}

const ShippingAddressSelector: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { addresses, selectedAddress } = useAppSelector(state => state.account);
    
    const deleteShippingAddress = (idAddress: number) => {
        dispatch(deleteAddress(idAddress));
    }

    const updateShippingAddress = (address: UserAddress) => {
        dispatch(setSelectedAddress(address));
    }

    const closeShippingAddress = (address?: UserAddress) => {
        if (address) {
            dispatch(setShippingAddress(address));
        }
        dispatch(setSelectedAddress(null));
    }

    return (
        <Box marginTop='1rem'>
            <Typography marginBottom='0.5rem'>
                Seleccione una dirección de envío:
            </Typography>
            <Grid container spacing={1}>
                {
                    addresses.length <= 0
                        ? <Box width='100%' marginTop='0.5rem'>
                            <Alert severity='info'>
                                Actualmente no hay ninguna dirección de envío registrada.
                            </Alert>
                        </Box>
                        : addresses.map(address =>
                            <Grid item key={address.idAddress} xs={12} md={6} lg={4}>
                                <Box>
                                    <Box sx={{ cursor: 'pointer', transition: 'all ease-in 0.2s', '&:hover': { transform: 'scale(1.025)' } }} onClick={() => dispatch(setShippingAddress(address))}>
                                        <AddressCard data={address} />
                                    </Box>
                                    <Box display='flex'>
                                        <Button color='error' onClick={() => deleteShippingAddress(address.idAddress)}>
                                            Eliminar
                                        </Button>
                                        <Button onClick={() => updateShippingAddress(address)}>
                                            Editar
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        )
                }
            </Grid>
            <Box>
                <AddressForm showTitle={false} showCancelButton={selectedAddress !== null} onClose={closeShippingAddress} />
            </Box>
        </Box>
    );
}

export default ShippingAddressSelector;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CancellationFormData, DetailedOrderData, GetOrder, OrderData } from '../../interfaces';
import { AppThunk } from '../store';
import { mainApi } from '../../api';
import { finishLoading, startLoading } from './ui';

interface OrdersState {
    orders: OrderData[],
    years: string[],
    selectedRange: string;
    filter: string | null;
    search: string | null;
    selectedOrder: DetailedOrderData | null;
}

const initialState: OrdersState = {
    orders: [],
    years: [],
    selectedRange: '0',
    filter: null,
    search: null,
    selectedOrder: null,
};

const ordersSlice = createSlice({
    name: 'Orders',
    initialState,
    reducers: {
        setOrders(state, { payload }: PayloadAction<OrderData[]>) {
            state.orders = payload;
        },
        setYears(state, { payload }: PayloadAction<string[]>) {
            state.years = payload;
        },
        setSelectedRange(state, { payload }: PayloadAction<string>) {
            state.selectedRange = payload;
        },
        setFilter(state, { payload }: PayloadAction<string | null>) {
            state.filter = payload;
        },
        setSearch(state, { payload }: PayloadAction<string | null>) {
            state.search = payload;
        },
        resetOrdersState() {
            return initialState;
        },
        setSelectedOrder(state, { payload }: PayloadAction<DetailedOrderData | null>) {
            state.selectedOrder = payload;
        }
    },
});

export const {
    setOrders,
    setYears,
    setSelectedRange,
    setFilter,
    setSearch,
    resetOrdersState,
    setSelectedOrder,
} = ordersSlice.actions;

export const fetchOrders = (): AppThunk => {
    return async (dispatch, getState) => {
        try {
            const { filter, selectedRange, search } = getState().orders;

            dispatch(startLoading());

            const queryData: any = {};

            if (filter) 
                queryData.status = filter;

            switch(selectedRange) {
                case '0': queryData.last = '30d'; break;
                case '1': queryData.last = '3m'; break;
                default: queryData.year = selectedRange; break;
            }

            if (search) {
                delete queryData.status;
                queryData.search = search;
            }

            const { data } = await mainApi.post('/ecommerce/query-account-orders', queryData);

            dispatch(setOrders(data.orders));
            dispatch(setYears(data.years));
            dispatch(finishLoading());
        } catch (error) {
            // console.log('fetchOrders', error);
            dispatch(finishLoading());
        }
    }
}

export const fetchOrder = (idOrder: string, onOrderNotFound?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetOrder>(`/ecommerce/customer-order/${idOrder}`);

            dispatch(setSelectedOrder(data.order));
            dispatch(finishLoading());
        } catch (error) {
            // console.log('fetchOrder', error);
            dispatch(setSelectedOrder(null));
            dispatch(finishLoading());
            if (onOrderNotFound) onOrderNotFound();
        }
    }
}

export const requestCancellation = ({ idOrder, motive }: CancellationFormData, onSucces?: VoidFunction, onError?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.post(`/cancellations/request/${idOrder}`, motive.trim().length > 0 ? { motive } : {} )

            dispatch(finishLoading());
            if (onSucces) onSucces();
        } catch (error) {
            // console.log('requestCancellation', error);
            dispatch(finishLoading());
            if (onError) onError();
        }
    }
}

export default ordersSlice.reducer;
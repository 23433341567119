import { FC, useRef } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { HomeProductCardData, Store } from '../../interfaces';
import HomeStoreCard from './HomeStoreCard';
import HomeProductCard from './HomeProductCard';

interface Props {
    direction?: 'right' | 'left'
    store?: Store | null;
    products?: HomeProductCardData[];
}

const HomeStoreProducts: FC<Props> = ({ direction = 'right', store = null, products = [] }) => {
    const storeContainer = useRef<HTMLElement>(null)

    return (
        <Grid container marginY='1.5rem' flexDirection={direction === 'right' ? 'row' : 'row-reverse'}>
            <Grid item xs={12} md={7}>
                <Box width='100%' height='100%' display='flex' alignItems='center'>
                    <HomeStoreCard data={store}  container={storeContainer.current} />
                </Box>
            </Grid>
            <Grid item xs={12} md={5} paddingX={{ md: '0.5rem' }} paddingY={{ xs: '2.5rem', md: 0 }}>
                <Box display='flex' flexDirection='column' alignItems='center' position='relative' marginTop={{ xs: '0.5rem', md: 0 }}>
                    <HomeProductCard
                        data={products[2]}
                        containerSx={{
                            width: { xs: '80%', lg: '60%' },
                            position: 'absolute',
                            transform: 'translateX(-2rem) translateY(-2rem)',
                        }}
                    />
                    <HomeProductCard
                        data={products[1]}
                        containerSx={{
                            width: { xs: '80%', lg: '60%' },
                            position: 'absolute',
                            transform: 'translateX(2rem) '
                        }}
                    />
                    <HomeProductCard
                        data={products[0]}
                        containerSx={{
                            width: { xs: '80%', lg: '60%' },
                            transform: 'translateY(2rem) '
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
    );
}

export default HomeStoreProducts;
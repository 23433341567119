import { FC, SyntheticEvent } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

interface Props {
    tab: number;
    onChange: (tab: number) => void;
    tab1Title?: string;
    tab2Title?: string;
}

const TabSelector: FC<Props> = ({ tab, onChange, tab1Title = 'Registro', tab2Title = '¿Ya tienes una cuenta?' }) => {
    const handleTabChange = (_: SyntheticEvent, value: number) => {
        onChange(value);
    }

    return (
        <Box>
            <Tabs value={tab} onChange={handleTabChange} centered>
                <Tab label={tab1Title} sx={{ width: '50%' }} />
                <Tab label={tab2Title} sx={{ width: '50%' }} />
            </Tabs>
            <Divider />
        </Box>
    );
}

export default TabSelector;
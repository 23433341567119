import { FC } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { useAppSelector } from '../../hooks';
import AddressCard from './AddressCard';
import { Link } from 'react-router-dom';

interface Props {
}

const AccountAddressesCard: FC<Props> = () => {
    const { defaultAddress, totalAddresses } = useAppSelector(state => state.account);

    return (
        <Paper elevation={3}>
            <Box padding='1rem'>
                <Typography component='h3' variant='h5'>
                    Direcciones
                </Typography>
                {
                    defaultAddress
                        ? <AddressCard data={defaultAddress} />
                        : <Alert severity='info'>
                            Aún no se ha registrado una dirección principal de envío.
                        </Alert>
                }
                <Box display='flex' justifyContent='end' marginTop='0.5rem'>
                    <Link to='/cuenta/direcciones'>
                        <Button variant='outlined' size='small'>
                            Ver direcciones
                            {
                                totalAddresses > 0
                                    ? ` (${totalAddresses})`
                                    : ''
                            }
                        </Button>
                    </Link>
                </Box>
            </Box>
        </Paper>
    );
}

export default AccountAddressesCard;
import { FC, useMemo } from 'react';
import { CartProduct } from '../../interfaces';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';

import { Parser } from '../../utils';
import { colors } from '../../themes';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { deleteProductFromCart, setProductQuantity } from '../../redux/slices/cart';
import Tooltip from '@mui/material/Tooltip';

interface Props {
    data: CartProduct;
}

const CheckoutProductCard: FC<Props> = ({ data }) => {
    const dispatch = useAppDispatch();
    const { municipality } = useAppSelector(state => state.session);

    const price = useMemo(() => {
        const { generalPrice, localPrice, foreignPrice } = data.variant.price;
        if (!municipality) return generalPrice;
        return municipality === '1' ? localPrice : foreignPrice;
    }, [data, municipality]);

    const onQuantityAdd = () => {
        dispatch(setProductQuantity(data, data.quantity + 1));
    }

    const onQuantityRemove = () => {
        dispatch(setProductQuantity(data, data.quantity - 1));
    }

    return (
        <Grid container spacing={1} marginY='1rem' alignItems='center'>
            <Grid item xs={12} md={6} display='flex'>
                <img src={data.image} alt={data.name} style={{ width: '20%', aspectRatio: '1 / 1', objectFit: 'contain' }} />
                <Box marginLeft='0.5rem' overflow='hidden'>
                    <Tooltip title={data.name} placement='top-end'>
                        <Typography noWrap>
                            {data.name}
                        </Typography>
                    </Tooltip>
                    <Box display='flex'>
                        <Chip label={data.variant.measurement} color='primary' sx={{ marginRight: '0.25rem' }} />
                        {
                            data.variant.paintSheen
                                ? <Chip label={data.variant.paintSheen.name} color='primary' sx={{ marginRight: '0.25rem' }} />
                                : null
                        }
                    </Box>
                    {
                        data.variant.color
                            ? <Box display='flex' marginTop='0.5rem'>
                                <Box display='flex' width='fit-content' alignItems='center' padding='0.25rem 1rem' border={`1px ${colors.primary} solid`} borderRadius={40}>
                                    <Typography variant='body2' marginRight='0.5rem' textTransform='uppercase'>
                                        {data.variant.color.code}
                                    </Typography>
                                    <Box width='3rem' height='100%' sx={{ backgroundColor: `rgb${data.variant.color.rgb}` }} />
                                </Box>
                            </Box>
                            : null
                    }
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Box>
                        <Typography fontWeight='bold' color={data.variant.discount ? 'red' : undefined}>
                            {Parser.parseToPrice(price - (data.variant.discount ? (price * (data.variant.discount / 100)) : 0))}
                        </Typography>
                    </Box>
                    <Box display='flex' alignItems='center'>
                        <IconButton size='small' disabled={data.quantity === 1} color='primary' onClick={onQuantityRemove}>
                            <RemoveIcon />
                        </IconButton>
                        <Box padding='0.25rem 0.75rem' border='1px black solid' borderRadius={1}>
                            <Typography>{data.quantity}</Typography>
                        </Box>
                        <IconButton size='small' color='primary' onClick={onQuantityAdd}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        <IconButton size='small' onClick={() => dispatch(deleteProductFromCart(data))}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

export default CheckoutProductCard;
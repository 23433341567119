import { FC } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { useAppSelector } from '../../hooks';
import EmptyOrdersList from './EmptyOrdersList';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import OrderCard from '../ui/OrderCard';

interface Props {
}

const OrdersHistory: FC<Props> = () => {
    const { orders } = useAppSelector(state => state.orders);

    return (
        <Paper elevation={3}>
            <Box padding='1rem'>
                <Typography component='h2' variant='h5'>
                    Historial de Pedidos
                </Typography>
                <Box marginTop='1rem'>
                    {
                        orders.length > 0
                            ? orders.slice(0, 3).map(order => <OrderCard key={order.idOrder} data={order} />)
                            : <EmptyOrdersList />
                    }
                </Box>
                {
                    orders.length > 0
                        ? <Box display='flex' justifyContent='end'>
                            <Link to='/cuenta/pedidos'>
                                <Button variant='contained'>
                                    Ver todos los pedidos
                                </Button>
                            </Link>
                        </Box>
                        : null
                }
            </Box>
        </Paper>
    );
}

export default OrdersHistory;
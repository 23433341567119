import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AccountDetailsFormData, AddressFormData, GetAccountData, GetAddreses, OrderData, PostPutAddress, PutAccountData, UserAddress } from '../../interfaces';
import { AppThunk } from '../store';
import { finishLoading, startLoading } from './ui';
import { mainApi } from '../../api';
import { logOut, setUser } from './session';

interface AddressCreationData {
    fullName: string;
    company?: string;
    address1: string;
    address2?: string;
    city: string;
    municipality: string;
    zipcode: string;
    phoneNumber: string;
    isDefault: 0 | 1;
}

interface AccountState {
    defaultAddress: UserAddress | null;
    totalAddresses: number;
    addresses: UserAddress[];
    selectedAddress: UserAddress | null;
}

const initialState: AccountState = {
    defaultAddress: null,
    totalAddresses: 0,
    addresses: [],
    selectedAddress: null,
};

const accountSlice = createSlice({
    name: 'Account',
    initialState,
    reducers: {
        setDefaultAddress(state, { payload }: PayloadAction<UserAddress | null>) {
            state.defaultAddress = payload;
        },
        setTotalAddresses(state, { payload }: PayloadAction<number>) {
            state.totalAddresses = payload;
        },
        setAddresses(state, { payload }: PayloadAction<UserAddress[]>) {
            state.addresses = payload;
        },
        setSelectedAddress(state, { payload }: PayloadAction<UserAddress | null>) {
            state.selectedAddress = payload;
        },
    },
});

export const {
    setDefaultAddress,
    setTotalAddresses,
    setAddresses,
    setSelectedAddress,
} = accountSlice.actions;

export const getAccountData = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetAccountData>('/ecommerce/account');

            dispatch(setDefaultAddress(data.defaultAddress));
            dispatch(setTotalAddresses(data.totalAddresses));
            dispatch(finishLoading());
        } catch (error) {
            // console.log('getAccountData', error);
            dispatch(finishLoading());
        }
    }
}

export const updateAccountData = (data: AccountDetailsFormData, onSuccess?: (passwordUpdated: boolean) => void, onError?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const formData: any = {
                firstName: data.firstName,
                lastName: data.lastName,
                phoneNumber: data.phoneNumber,
            }
            if (data.oldPassword && data.password) {
                formData.oldPassword = data.oldPassword;
                formData.password = data.password;
            }

            const { data: { passwordUpdated, customer } } = await mainApi.put<PutAccountData>('/customers/update', formData);

            dispatch(finishLoading());
            dispatch(setUser(customer));
            if (onSuccess) onSuccess(passwordUpdated);
        } catch (error) {
            // console.log('updateAccountData', error);
            dispatch(finishLoading());
            if (onError) onError();
        }
    }
}

export const getAddresses = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetAddreses>('/addresses');

            dispatch(setAddresses(data.addresses));
            dispatch(finishLoading());
        } catch (error) {
            // console.log('getAddresses', error);
            dispatch(finishLoading());
        }
    }
}

export const createNewAddress = ({ fullName, address1, city, municipality, zipcode, phoneNumber, ...form }: AddressFormData, onSuccess?: (address: UserAddress) => void, onError?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const data: AddressCreationData = {
                fullName, address1, city, municipality, zipcode, phoneNumber,
                isDefault: form.isDefault ? 1 : 0,
            };
            if (form.company.trim().length > 0)
                data.company = form.company;
            if (form.address2.trim().length > 0)
                data.address2 = form.address2;

            const { data: { address } } = await mainApi.post<PostPutAddress>('/addresses', data);

            dispatch(getAddresses());
            dispatch(finishLoading());
            if (onSuccess) onSuccess(address);
        } catch (error) {
            // console.log('createNewAddress', error);
            dispatch(finishLoading());
            if (onError) onError();
        }
    }
}

export const updateAddress = (idAddress: number, { fullName, address1, city, municipality, zipcode, phoneNumber, ...form }: AddressFormData, onSuccess?: (address: UserAddress) => void, onError?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const data: AddressCreationData = {
                fullName, address1, city, municipality, zipcode, phoneNumber,
                isDefault: form.isDefault ? 1 : 0,
            };
            if (form.company.trim().length > 0)
                data.company = form.company;
            if (form.address2.trim().length > 0)
                data.address2 = form.address2;

            const { data: { address } } = await mainApi.put<PostPutAddress>(`/addresses/${idAddress}`, data);

            dispatch(getAddresses());
            dispatch(finishLoading());
            if (onSuccess) onSuccess(address);
        } catch (error) {
            // console.log('updateAddress', error);
            dispatch(finishLoading());
            if (onError) onError();
        }
    }
}

export const deleteAddress = (idAddress: number, onSuccess?: VoidFunction, onError?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.delete(`/addresses/${idAddress}`);

            dispatch(getAddresses());
            dispatch(finishLoading());
            if (onSuccess) onSuccess();
        } catch (error) {
            // console.log('deleteAddress', error);
            dispatch(finishLoading());
            if (onError) onError();
        }
    }
}

export const sendPasswordRecoveryRequest = (email: string): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.post('/customers/passwordReset', { email });

            dispatch(finishLoading());
        } catch (error) {
            // console.log('sendPasswordRecoveryRequest', error);
            dispatch(finishLoading());
        }
    }
}

export const resetPassword = (token: string, password: string, onSuccess?: VoidFunction, onError?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.put('/customers/passwordReset', { token, password });

            dispatch(finishLoading());
            if (onSuccess) onSuccess();
        } catch (error) {
            // console.log('resetPassword', error);
            dispatch(finishLoading());
            if (onError) onError();
        }
    }
}

export const requestAccountDeactivation = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.post('/customers/request-deactivation');

            dispatch(finishLoading());
        } catch (error) {
            // console.log('requestAccountDeactivation', error);
            dispatch(finishLoading());
        }
    }
}

export const deactivateAccount = (token: string, onSuccess?: VoidFunction, onError?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            await mainApi.post('/customers/deactivate-account', { token });

            if (onSuccess) onSuccess();
            dispatch(logOut());
            dispatch(finishLoading());
        } catch (error) {
            // console.log('deactivateAccount', error);
            dispatch(finishLoading());
            if (onError) onError();
        }
    }
}

export default accountSlice.reducer;
import { FC, useEffect } from 'react';
import Box from '@mui/material/Box';

import { useAppDispatch, useDesignConstants } from '../hooks';
import { getInspirationData, setSelectedColor, setSelectedZone } from '../redux/slices/inspiration';
import { ColorsSelector, InspirationGallery, ShopPageLayout, ZoneSelector } from '../components';

interface Props {
}

const Inspiration: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { ShopContent } = useDesignConstants();

    useEffect(() => {
        dispatch(getInspirationData());
        dispatch(setSelectedColor(0));
        dispatch(setSelectedZone(''))
    }, []);

    return (
        <ShopPageLayout name='Inspiración'>
            <Box sx={ShopContent}>
                <ColorsSelector />
                <ZoneSelector />
                <InspirationGallery />
            </Box>
        </ShopPageLayout>
    );
}

export default Inspiration;
import { FC, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useAppDispatch } from '../hooks';
import { getAccountData } from '../redux/slices/account';
import { ShopPageLayout } from '../components';
import { AccountAddressesCard, AccountDetailsCard, OrdersHistory } from '../components/account';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import { fetchOrders } from '../redux/slices/orders';

interface Props {
}

const Account: FC<Props> = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getAccountData());
        dispatch(fetchOrders());
    }, []);

    return (
        <ShopPageLayout name='Mi Cuenta'>
            <Grid container spacing='1rem' paddingY='1rem'>
                <Grid item xs={12} lg={8}>
                    <Slide in={true} direction='right' timeout={500}>
                        <Box>
                            <OrdersHistory />
                        </Box>
                    </Slide>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Grid container spacing='1rem'>
                        <Grid item xs={12} md={6} lg={12}>
                            <Fade in={true} timeout={500}>
                                <Box>
                                    <AccountDetailsCard />
                                </Box>
                            </Fade>
                        </Grid>
                        <Grid item xs={12} md={6} lg={12}>
                            <Fade in={true} timeout={500}>
                                <Box>
                                    <AccountAddressesCard />
                                </Box>
                            </Fade>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ShopPageLayout >
    );
}

export default Account;
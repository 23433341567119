import { FC, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import FilterOption from './FilterOption';
import { StoreFilterOptionData } from '../../interfaces/store';

interface Props {
    title: string;
    icon: JSX.Element;
    options?: StoreFilterOptionData[];
    selectedOption?: number;
    onOptionClick?: (id: number) => void;
    allOptionsTitle?: string;
    showDivider?: boolean;
}

const StoreFilter: FC<Props> = ({ title, icon, options = [], selectedOption = 0, onOptionClick, allOptionsTitle, showDivider = true }) => {
    const ref = useRef<HTMLElement>(null);

    return (
        <Box ref={ref}>
            <Box>
                <Typography variant='h5' component='p'>
                    {title}
                </Typography>
                <Box marginTop='0.5rem'>
                    <FilterOption
                        title={allOptionsTitle ?? `Todas las ${title}`}
                        icon={icon}
                        selected={selectedOption === 0}
                        onClick={() => onOptionClick && onOptionClick(0)}
                        container={ref.current}
                    />
                    {
                        options.map(({ id, name }) =>
                            <FilterOption
                                key={`option_${title.toLowerCase()}_${id}`}
                                title={name}
                                icon={icon}
                                selected={selectedOption === id}
                                onClick={() => onOptionClick && onOptionClick(id)}
                                container={ref.current}
                            />
                        )
                    }
                </Box>
            </Box>
            {
                showDivider
                    ? <Divider sx={{ marginY: '1rem' }} />
                    : null
            }
        </Box>
    );
}

export default StoreFilter;
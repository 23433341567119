import { FC, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import { OrderData } from '../../interfaces';
import { Parser } from '../../utils';
import { colors } from '../../themes';
import OrderProductCard from './OrderProductCard';
import InvoiceRequestModal from './InvoiceRequestModal';
import Notification from './Notification';
import { fetchOrders } from '../../redux/slices/orders';
import { useAppDispatch } from '../../hooks';

interface Props {
    data: OrderData;
}

const OrderCard: FC<Props> = ({ data }) => {
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const [invoiceDialogActive, setInvoiceDialogActive] = useState(false);
    const [showError, setShowError] = useState(false);

    const statusMessage = useMemo(() => {
        if (data.status === 'pending')
            return `Pedido el ${moment(data.orderDate).format('dddd, DD [de] MMMM')}`;
        if (data.status === 'in-progress')
            return `Llegando el ${moment(data.deliveryDate).format('dddd, DD [de] MMMM')}`;
        if (data.status === 'completed')
            return `Entregado el ${moment(data.completionDate).format('dddd, DD [de] MMMM')}`;
        if (data.status === 'cancelled')
            return `Cancelado`;
        return '';
    }, [data]);

    const openInvoiceDialog = () => {
        setInvoiceDialogActive(true);
    }

    const closeInvoiceDialog = () => {
        setInvoiceDialogActive(false);
    }

    const goToInvoice = () => {
        navigate(`/cuenta/pedidos/${data.idOrder}/factura`)
    }

    return (
        <Paper sx={{ marginBottom: '1rem', borderRadius: '8px' }} elevation={3}>
            <Notification
                open={showError}
                onClose={() => setShowError(false)}
                message='Ha ocurrido un error al intentar completar el procedimiento, por favor intente más tarde.'
                type='error'
            />
            <Box
                padding='1rem'
                display='flex'
                flexDirection={{ xs: 'column-reverse', sm: 'row' }}
                justifyContent='space-between'
                sx={{ backgroundColor: colors.primary, color: 'white', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
            >
                <Box display='flex' flexDirection={{ xs: 'column', sm: 'row' }}>
                    <Box marginRight='3rem'>
                        <Typography variant='body2' textTransform='uppercase'>
                            Pedido Realizado
                        </Typography>
                        <Typography>
                            {moment(data.orderDate).format('LLL')}
                        </Typography>
                    </Box>
                    <Box marginRight='3rem'>
                        <Typography variant='body2' textTransform='uppercase'>
                            Total
                        </Typography>
                        <Typography>
                            {Parser.parseToPrice(data.total)}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant='body2' textTransform='uppercase'>
                            Enviar a
                        </Typography>
                        <Typography>
                            {data.addressName}
                        </Typography>
                    </Box>
                </Box>
                <Box display='flex' justifyContent='end'>
                    <Typography fontWeight='600' textTransform='uppercase'>
                        Pedido #{data.idOrder.split('-')[0]}
                    </Typography>
                </Box>
            </Box>
            <Divider />
            <Box padding='1rem' paddingTop='0.5rem'>
                <Box marginBottom='0.5rem'>
                    {
                        statusMessage.length > 0
                            ? <Typography variant='h6' component='p'>
                                {statusMessage}
                            </Typography>
                            : null
                    }
                </Box>
                <Box display='flex' flexDirection={{ xs: 'column', sm: 'row' }}>
                    <Box width={{ xs: '100%', sm: '65%' }}>
                        {
                            data.products.map((product, index) =>
                                <OrderProductCard key={`${product.idOrder}_${index}`} data={product} />
                            )
                        }
                    </Box>
                    <Box flex={1} padding='0 0.5rem'>
                        <Link to={`/cuenta/pedidos/${data.idOrder}`}>
                            <Button size='small' variant='contained' fullWidth sx={{ marginBottom: '0.5rem' }}>
                                Ver detalles
                            </Button>
                        </Link>
                        {
                            data.status !== 'cancelled' && data.cancellation === null
                                ? <Button
                                    size='small'
                                    variant='contained'
                                    color='inherit'
                                    disabled={data.invoice === 'pending'}
                                    fullWidth sx={{ marginBottom: '0.5rem' }}
                                    onClick={data.invoice === 'fulfilled' ? goToInvoice : openInvoiceDialog}
                                >
                                    {data.invoice === 'pending' ? 'Factura Solicitada' : 'Factura'}
                                </Button>
                                : null
                        }
                    </Box>
                </Box>
            </Box>
            {
                data.invoice === null
                    ? <InvoiceRequestModal
                        idOrder={data.idOrder}
                        open={invoiceDialogActive}
                        onClose={closeInvoiceDialog}
                        onError={() => setShowError(true)}
                        callback={() => dispatch(fetchOrders())}
                    />
                    : null
            }
        </Paper>
    );
}

export default OrderCard;
import { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';

import { colors } from '../../themes';
interface Props {
    title: string;
    icon: JSX.Element;
    container?: HTMLElement | null;
    onClick?: VoidFunction;
    selected?: boolean;
}

const FilterOptionStyle: SxProps = {
    cursor: 'pointer',
    transition: 'all ease-out 0.3s',
    opacity: 1,
    '&:hover': {
        opacity: 0.6
    }
}

const FilterOptionSelectedStyle: SxProps = {
    ...FilterOptionStyle,
    backgroundColor: colors.primary,
    color: 'white',
}

const FilterOption: FC<Props> = ({ title, icon, onClick, selected = false, container }) => {
    const [isVisible, setVisible] = useState(false);

    useEffect(() => {
        setTimeout(() => setVisible(true), 50)
    }, []);

    return (
        <Box>
            <Slide in={isVisible} timeout={500} container={container}>
                <Box
                    padding='0.25rem'
                    marginBottom='0.1rem'
                    border='1px #d9d9d9 solid'
                    borderRadius={1}
                    sx={selected ? FilterOptionSelectedStyle : FilterOptionStyle}
                    onClick={onClick}
                >
                    <Box display='flex' alignItems='center'>
                        {icon}
                        <Typography color={selected ? 'inherit' : 'primary'} textTransform='capitalize' marginLeft='0.5rem' noWrap>
                            {title.toLowerCase()}
                        </Typography>
                    </Box>
                </Box>
            </Slide>
        </Box>
    );
}

export default FilterOption;
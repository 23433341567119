import { FC, FormEvent, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useAppDispatch, useAppSelector, useForm } from '../../hooks';
import { AddressFormData, UserAddress } from '../../interfaces';
import { createNewAddress, updateAddress } from '../../redux/slices/account';
import { LoadingOverlay, Notification } from '../ui';

interface Props {
    onClose: (address?: UserAddress) => void;
    showTitle?: boolean;
    showCancelButton?: boolean;
}

const initialFormState = {
    fullName: '',
    company: '',
    address1: '',
    address2: '',
    city: '',
    municipality: '',
    zipcode: '',
    phoneNumber: '',
    isDefault: false,
}

const AddressForm: FC<Props> = ({ onClose, showTitle = true, showCancelButton = true }) => {
    const dispatch = useAppDispatch();
    const { selectedAddress } = useAppSelector(state => state.account);
    const { loading } = useAppSelector(state => state.ui);

    const [error, setError] = useState({
        active: false,
        message: '',
    });

    const { data, handleInputChange, handleSelectChange, handleCheckChange, reset } = useForm<AddressFormData>(initialFormState);
    const { fullName, company, address1, address2, city, municipality, zipcode, phoneNumber, isDefault } = data;

    useEffect(() => {
        if (selectedAddress) {
            reset({
                ...selectedAddress,
                company: selectedAddress.company || '',
                address2: selectedAddress.address2 || '',
                municipality: selectedAddress.municipality.toString(),
                isDefault: Boolean(selectedAddress.isDefault === 1),
            });
        } else {
            reset();
        }
    }, [selectedAddress]);


    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (selectedAddress) {
            dispatch(updateAddress(selectedAddress.idAddress, data, (address) => {
                onClose(address);
                reset();
            }, () => {
                setError({
                    active: true,
                    message: 'Ha ocurrido un error al intentar actualizar la dirección.'
                })
            }));
        } else {
            dispatch(createNewAddress(data, (address) => {
                onClose(address);
                reset();
            }, () => {
                setError({
                    active: true,
                    message: 'Ha ocurrido un error al intentar añadir la dirección.'
                })
            }));
        }
    }

    return (
        <Box>
            <LoadingOverlay open={loading} />
            <Notification
                type='error'
                open={error.active}
                onClose={() => setError({ active: false, message: '' })}
                message={error.message}
                vertical='bottom'
            />
            {
                showTitle
                    ? <Typography component='h2' variant='h5'>
                        {selectedAddress ? 'Actualizar Dirección' : 'Añadir una nueva Dirección'}
                    </Typography>
                    : null
            }

            <Box marginTop='1rem' component='form' autoComplete='off' onSubmit={handleSubmit}>
                <Grid container spacing='1rem'>
                    <Grid item xs={6} >
                        <TextField label='Nombre Completo' size='small' name='fullName' value={fullName} onChange={handleInputChange} fullWidth required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField label='Teléfono' size='small' name='phoneNumber' value={phoneNumber} onChange={handleInputChange} fullWidth required />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label='Compañía' size='small' name='company' value={company} onChange={handleInputChange} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label='Dirección 1' size='small' name='address1' value={address1} onChange={handleInputChange} fullWidth required />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label='Dirección 2' size='small' name='address2' value={address2} onChange={handleInputChange} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth size='small'>
                            <InputLabel>País</InputLabel>
                            <Select label="País" value='México'>
                                <MenuItem value='México'>México</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth size='small'>
                            <InputLabel>Estado</InputLabel>
                            <Select label="Estado" value='BCS'>
                                <MenuItem value='BCS'>Baja California Sur (BCS)</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField label='Ciudad' size='small' name='city' value={city} onChange={handleInputChange} fullWidth required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth size='small' required>
                            <InputLabel>Municipio</InputLabel>
                            <Select label="Municipio" name='municipality' value={municipality} onChange={handleSelectChange}>
                                <MenuItem value='1'>La Paz</MenuItem>
                                <MenuItem value='2'>Los Cabos</MenuItem>
                                <MenuItem value='3'>Comondú</MenuItem>
                                <MenuItem value='4'>Loreto</MenuItem>
                                <MenuItem value='5'>Mulegé</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField label='Código Postal' size='small' name='zipcode' value={zipcode} onChange={handleInputChange} fullWidth required />
                    </Grid>
                </Grid>
                <Box marginTop='0.25rem'>
                    <FormControlLabel
                        control={<Checkbox name='isDefault' checked={isDefault} onChange={handleCheckChange} />}
                        label="Seleccionar como Principal"
                    />
                </Box>
                <Box display='flex' justifyContent='end'>
                    {
                        showCancelButton
                            ? <Button variant='outlined' color='error' sx={{ marginRight: '0.5rem' }} onClick={() => onClose()}>
                                Cancelar
                            </Button>
                            : null
                    }
                    <Button variant='contained' type='submit'>
                        Guardar
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default AddressForm;
import { FC } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

interface Props {
    title: string;
    href: string;
    icon: JSX.Element;
}

const FooterIconButton: FC<Props> = ({ title, href, icon }) => {
    return (
        <a href={href} style={{ color: 'inherit' }} rel="noopener noreferrer" target="_blank">
            <Tooltip title={title}>
                <IconButton color='inherit'>
                    {icon}
                </IconButton>
            </Tooltip>
        </a>
    );
}

export default FooterIconButton;
import { FC, useMemo, useRef } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { SxProps } from '@mui/material/styles';

import { HomeProductCardData } from '../../interfaces';
import { useOnView } from '../../hooks';
import { Formatter } from '../../utils';

interface Props {
    containerSx?: SxProps;
    data?: HomeProductCardData | null;
}


const HomeProductCard: FC<Props> = ({ containerSx, data = null }) => {
    const ref = useRef<HTMLElement>(null);
    const isVisible = useOnView(ref);

    const description = useMemo(() => {
        if (!data) return null;
        return Formatter.truncate(data.description, 17);
    }, [data]);

    return (
        <Box ref={ref} sx={containerSx}>
            <Fade in={isVisible} timeout={500}>
                <Card>
                    {
                        data
                            ? <Typography padding='0.5rem' fontWeight='600'>{data.name}</Typography>
                            : <Box padding='0.5rem'>
                                <Skeleton variant='rectangular' width='100%' height={25} />
                            </Box>
                    }
                    {
                        data
                            ? <CardMedia
                                component='img'
                                width='100%'
                                height={150}
                                image={data.image}
                                alt='Product'
                                style={{
                                    objectFit: 'contain'
                                }}
                            />
                            : <Box>
                                <Skeleton variant='rectangular' width='100%' height={150} />
                            </Box>
                    }
                    <CardContent>
                        {
                            data
                                ? <Typography align='justify'>
                                    {description}
                                    </Typography>
                                : <Box>
                                    <Skeleton variant='rectangular' width='100%' height={100} />
                                </Box>
                        }
                    </CardContent>
                </Card>
            </Fade>
        </Box>
    );
}

export default HomeProductCard;
import { RefObject, useEffect, useMemo, useState } from 'react';

const useOnView = (ref: RefObject<HTMLElement>) => {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(() => {
        return new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));
    }, [ref]);

    useEffect(() => {
        observer.observe(ref.current!);

        return () => {
            observer.disconnect();
        };
    }, []);
    
    return isIntersecting;
}

export default useOnView;
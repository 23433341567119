import { FC } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';

interface Props {
    open: boolean;
    onClose: VoidFunction;
    children: JSX.Element;
    title?: string;
    containerStyle?: SxProps;
}

const ModalContent: SxProps = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    borderRadius: 1,
    boxShadow: 24,
}

const AppModal: FC<Props> = ({ open, onClose, children, title, containerStyle }) => {
    return (
        <Modal open={open} onClose={onClose} disableAutoFocus>
            <Fade in={open} timeout={500}>
                <Box sx={{ ...ModalContent, ...containerStyle }}>
                    {
                        title
                            ? <>
                                <Box padding='0.75rem'>
                                    <Typography variant='h6' component='p' fontWeight='400' paddingRight='1rem'>
                                        {title}
                                    </Typography>
                                </Box>
                                <Divider />
                            </>
                            : null
                    }
                    <IconButton sx={{ position: 'absolute', top: 1, right: 1 }} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                    <Box padding='1rem'>
                        {children}
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}

export default AppModal;
import { FC } from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { Parser } from '../../utils';
import { useAppSelector } from '../../hooks';

interface Props {
}

const OrderTotal: FC<Props> = () => {
    const { total } = useAppSelector(state => state.checkout);

    return (
        <Box marginTop='1rem'>
            <Fade in={true} timeout={500}>
                <Paper elevation={3}>
                    <Box padding='1rem'>
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <Typography>Total del Pedido:</Typography>
                            <Typography>
                                {Parser.parseToPrice(total)}
                            </Typography>
                        </Box>
                    </Box>
                </Paper>
            </Fade>
        </Box>
    );
}

export default OrderTotal;
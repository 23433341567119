import { FC, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { useAppDispatch } from '../../hooks';
import { searchCouponCode } from '../../redux/slices/checkout';
import { Notification } from '../ui';

interface Props {
}

const CodeForm: FC<Props> = () => {
    const [code, setCode] = useState('');
    const [success, setSuccess] = useState(false);
    const [failure, setFailure] = useState(false);

    const dispatch = useAppDispatch();

    const handleCodeSubmit = () => {
        if (code.trim().length <= 0)
            return;

        dispatch(searchCouponCode(code, () => setSuccess(true), () => setFailure(true)));
        setCode('');
    }

    return (
        <Box marginTop='1rem' display='flex'>
            <Notification vertical='bottom' open={success} onClose={() => setSuccess(false)} message='El código de promoción ha sido aplicado con éxito.' type='success' />
            <Notification vertical='bottom' open={failure} onClose={() => setFailure(false)} message='El código de promoción no es válido.' type='error' />
            <Box flex={1} marginRight='0.5rem'>
                <TextField size='small' fullWidth placeholder='Introduce el código' value={code} onChange={e => setCode(e.target.value)} />
            </Box>
            <Button variant='contained' onClick={handleCodeSubmit}>
                Aplicar
            </Button>
        </Box>
    );
}

export default CodeForm;
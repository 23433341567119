import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { mainApi } from '../../api';
import { AppThunk } from '../store';
import { finishLoading, startLoading } from './ui';
import { Color, GetInspirationData, InspirationImage } from '../../interfaces';

interface InspirationState {
    colors: Color[];
    images: InspirationImage[];
    selectedColor: number;
    selectedZone: string;
}

const initialState: InspirationState = {
    colors: [],
    images: [],
    selectedColor: 0,
    selectedZone: '',
};

const inspirationSlice = createSlice({
    name: 'Inspiration',
    initialState,
    reducers: {
        setColors(state, { payload }: PayloadAction<Color[]>) {
            state.colors = payload;
        },
        setImages(state, { payload }: PayloadAction<InspirationImage[]>) {
            state.images = payload;
        },
        setSelectedColor(state, { payload }: PayloadAction<number>) {
            state.selectedColor = payload;
        },
        setSelectedZone(state, { payload }: PayloadAction<string>) {
            state.selectedZone = payload;
        },
    },
});

export const {
    setColors,
    setImages,
    setSelectedColor,
    setSelectedZone,
} = inspirationSlice.actions;

export const getInspirationData = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetInspirationData>('/ecommerce/inspiration');

            dispatch(setColors(data.colors));
            dispatch(setImages(data.images));
            dispatch(finishLoading());
        } catch (error) {
            // console.log('getInspirationData', error);
            dispatch(finishLoading());
            setColors([]);
        }
    }
}

export default inspirationSlice.reducer;
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { CartProduct, GalleryColor, ProductVariant } from '../../interfaces';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Generator, Parser } from '../../utils';
import { Notification, PromotionIndicator } from '../ui';
import ColorPickerModal from './ColorPickerModal';
import { addProductToCart } from '../../redux/slices/cart';

interface Props {
    variant: ProductVariant | null;
    onVariantClick: (variant: ProductVariant) => void;
}

const ProductSelector: FC<Props> = ({ variant, onVariantClick }) => {
    const [quantity, setQuantity] = useState(1);
    const [color, setColor] = useState<GalleryColor | null>(null);
    const [paintSheen, setPaintSheen] = useState<number | null>(null);
    const [notificationActive, setNotificationActive] = useState(false);
    const [warningActive, setWarningActive] = useState(false);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const { municipality } = useAppSelector(state => state.session);
    const { product } = useAppSelector(state => state.product);

    const price = useMemo(() => {
        if (!variant)
            return null;
        if (!municipality)
            return color?.isSpecial
                ? variant.specialGeneralPrice
                : variant.generalPrice;
        return municipality === '1'
            ? color?.isSpecial
                ? variant.specialLocalPrice
                : variant.localPrice
            : color?.isSpecial
                ? variant.specialGeneralPrice
                : variant.foreignPrice;
    }, [variant, color]);

    useEffect(() => {
        if (variant && variant.paintSheens) {
            setPaintSheen(variant.paintSheens[0].id);
        }
    }, [variant]);

    const onButtonClick = (callback: VoidFunction) => {
        if (!product || !price || !variant) return;

        const { idProduct, name, slug, image } = product;
        const {
            key, measurement,
            localPrice, foreignPrice, generalPrice,
            specialLocalPrice, specialForeignPrice, specialGeneralPrice,
            discount, usesColorGallery
        } = variant;

        if ((usesColorGallery && color === null) || (variant.paintSheens && paintSheen === null)) {
            setWarningActive(true);
            return;
        }

        dispatch(addProductToCart({
            idProduct, name, slug, image,
            variant: {
                key, measurement,
                color: color
                    ? {
                        idColor: color.idColor,
                        code: color.code,
                        rgb: color.rgb,
                    }
                    : null,
                discount,
                price: {
                    localPrice: color?.isSpecial ? specialLocalPrice! : localPrice,
                    foreignPrice: color?.isSpecial ? specialForeignPrice! : foreignPrice,
                    generalPrice: color?.isSpecial ? specialGeneralPrice! : generalPrice,
                },
                paintSheen: variant.paintSheens
                    ? variant.paintSheens.find(ps => ps.id === paintSheen)!
                    : null
            },
            quantity,
        }));

        callback();
    }

    if (!product)
        return null;
    if (!variant)
        return null;

    return (
        <Paper elevation={4}>
            <Notification open={notificationActive} onClose={() => setNotificationActive(false)} type='success' message='Se ha actualizado el carrito de compra.' />
            <Notification open={warningActive} onClose={() => setWarningActive(false)} type='warning' message='¡No olvides seleccionar un color y acabado antes de proceder!' />
            <Box padding='1rem' position='relative'>
                {variant.discount ? <PromotionIndicator /> : null}
                <Typography fontWeight='bold'>
                    Presentaciones
                </Typography>
                <Grid container spacing={1}>
                    {
                        product.variants.map(v =>
                            <Grid item key={v.key}>
                                <Box onClick={() => onVariantClick(v)} sx={{ cursor: 'pointer' }}>
                                    <Chip
                                        label={v.measurement}
                                        color='primary'
                                        variant={variant && variant.key === v.key ? 'filled' : 'outlined'}
                                        sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                                    />
                                </Box>
                            </Grid>
                        )
                    }
                </Grid>
                {
                    variant.usesColorGallery
                        ? <Box marginTop='1rem'>
                            <ColorPickerModal
                                color={color}
                                colorOptions={product.colors}
                                groups={variant.allowedColorGroups?.split(';') || []}
                                onColorPicked={c => setColor(c)}
                                usesSpecialColors={variant.usesSpecialPrices}
                            />
                        </Box>
                        : null
                }
                {
                    variant.paintSheens
                        ? <Box marginTop='1rem'>
                            <Typography fontWeight='bold'>
                                Acabados Disponibles
                            </Typography>
                            <Grid container spacing={1}>
                                {
                                    variant.paintSheens.map(ps =>
                                        <Grid item key={ps.id}>
                                            <Box onClick={() => setPaintSheen(ps.id)} sx={{ cursor: 'pointer' }}>
                                                <Chip
                                                    label={ps.name}
                                                    color='primary'
                                                    variant={paintSheen === ps.id ? 'filled' : 'outlined'}
                                                    sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                                                />
                                            </Box>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Box>
                        : null
                }
                <Box marginTop='1rem' display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography>
                        Precio unitario:
                    </Typography>
                    <Box>
                        {
                            variant.discount && price
                                ? <Typography variant='body2' sx={{ textDecorationLine: 'line-through' }} align='right'>
                                    {Parser.parseToPrice(price)}
                                </Typography>
                                : null
                        }
                        <Typography variant='h4' component='p' color={variant?.discount ? 'red' : 'primary'}>
                            {
                                price
                                    ? Parser.parseToPrice(price - (variant?.discount ? price * (variant.discount / 100) : 0))
                                    : ''
                            }
                        </Typography>
                    </Box>
                </Box>
                <Box marginTop='1rem' display='flex' alignItems='center'>
                    <Typography marginRight='0.5rem'>
                        Cantidad:
                    </Typography>
                    <select value={quantity} onChange={e => setQuantity(parseInt(e.target.value))}>
                        {
                            Generator.generateArrayFrom(30).map(num =>
                                <option key={`product_quantity_${num}`} value={num + 1}>{num + 1}</option>
                            )
                        }
                    </select>
                </Box>
                <Box marginTop='1rem'>
                    <Alert severity='info'>
                        Envíos GRATIS en compras mayores de $3000.00 MXN.
                    </Alert>
                </Box>
                <Box marginTop='1rem'>
                    <Button variant='outlined' sx={{ marginBottom: '0.5rem' }} onClick={() => onButtonClick(() => setNotificationActive(true))} fullWidth>
                        Agregar al carrito
                    </Button>
                    <Button variant='contained' onClick={() => onButtonClick(() => navigate('/pago'))} fullWidth>
                        Comprar ahora
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
}

export default ProductSelector;
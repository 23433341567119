import { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { useDesignConstants } from '../hooks';
import { useSearchParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { mainApi } from '../api';
import { PasswordResetForm } from '../components/passwordreset';

interface Props {
}

const PasswordReset: FC<Props> = () => {
    const [isFetching, setFetching] = useState(true);
    const [isInvalid, setInvalid] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [searchParams] = useSearchParams();

    const { PaperContent } = useDesignConstants();

    const validateToken = async () => {
        setFetching(true);
        try {
            const { data } = await mainApi.post<{ message: string; firstName: string; }>('/customers/validateToken', { token: searchParams.get('token') });
            setFirstName(data.firstName);
        } catch (error) {
            setInvalid(true);
        }
        setFetching(false);
    }

    useEffect(() => {
        validateToken();
    }, []);

    return (
        <Box width='100%' marginTop='2.5rem' display='flex' justifyContent='center' alignItems='center'>
            <Paper elevation={4} sx={PaperContent}>
                <Box padding='1rem'>
                    <Typography variant='h5' component='h1' align='center'>
                        Restablecimiento de Contraseña
                    </Typography>
                    <Box>
                        {
                            isFetching
                                ? <Box display='flex' flexDirection='column' alignItems='center' marginY='1.5rem'>
                                    <CircularProgress />
                                    <Typography marginTop='0.5rem' variant='body1' textTransform='uppercase' align='center'>
                                        Verificando Token...
                                    </Typography>
                                </Box>
                                : isInvalid
                                    ? <Box marginTop='0.5rem'>
                                        <Alert severity='error'>
                                            El Token de Restablecimiento de Contraseña no es válido. Aseguráte que los datos sean correctos y de ser necesario, solicta nuevamente restablecer la contraseña.
                                        </Alert>
                                    </Box>
                                    : <PasswordResetForm name={firstName} />
                        }
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}

export default PasswordReset;
import { FC } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
    open?: boolean;
}

const LoadingOverlay: FC<Props> = ({ open = false }) => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (t) => t.zIndex.drawer + 2 }}
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default LoadingOverlay;
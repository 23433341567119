import { FC, useRef } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Fade from '@mui/material/Fade';

import { useOnView } from '../../hooks';

interface Props {
    placeholder?: boolean;
    selected?: boolean;
    color?: string;
    onClick?: VoidFunction;
}

const ColorCircle: FC<Props> = ({ placeholder = false, selected = false, color, onClick }) => {
    const ref = useRef<HTMLElement>(null);
    
    const isVisible = useOnView(ref);

    return (
        <Grid item>
            <Box
                ref={ref}
                padding='0.25rem'
                margin='0 0.5rem'
                borderRadius='50%'
                sx={{
                    backgroundColor: selected ? '#d9d9d9' : 'transparent',
                    transition: 'transform ease-out 0.3s',
                    '&:hover': {
                        transform: 'scale(1.05)',
                    }
                }}
            >
                {
                    placeholder
                        ? <Skeleton variant='circular' width='2.5rem' height='2.5rem' />
                        : <Fade in={isVisible} timeout={600}>
                            <Box
                                width='2.5rem'
                                height='2.5rem'
                                borderRadius='50%'
                                sx={{ backgroundColor: color, cursor: 'pointer' }}
                                onClick={onClick}
                            />
                        </Fade>
                }
            </Box>
        </Grid>
    );
}

export default ColorCircle;
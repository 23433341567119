import { FC, SyntheticEvent, useRef } from 'react';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MuiPhoneNumber from 'material-ui-phone-number';

import { useAppSelector, useOnView, useRegisterForm } from '../../hooks';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

interface Props {
}

const RegisterForm: FC<Props> = () => {
    const ref = useRef<HTMLElement>(null);
    const isVisible = useOnView(ref);

    const { loading } = useAppSelector(state => state.ui);
    const { data, error, errorsActive, handleInputChange, handlePhoneInputChange, handleCheckChange, handleSubmit } = useRegisterForm();

    return (
        <Box ref={ref} marginY='1rem'>
            <Fade in={isVisible} timeout={500}>
                <Box component='form' onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                label='Nombre(s)'
                                size='small'
                                name='firstName'
                                value={data.firstName}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label='Apellidos'
                                size='small'
                                name='lastName'
                                value={data.lastName}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MuiPhoneNumber
                                label='Número Tel.'
                                defaultCountry='mx'
                                variant='outlined'
                                name='phoneNumber'
                                value={data.phoneNumber}
                                onChange={handlePhoneInputChange}
                                size='small'
                                error={errorsActive && data.phoneNumber.length < 10}
                                helperText={(errorsActive && data.phoneNumber.length < 10) && 'El número telefónico no es válido.'}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label='Correo'
                                size='small'
                                type='email'
                                name='email'
                                value={data.email}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label='Contraseña'
                                size='small'
                                type='password'
                                name='password'
                                value={data.password}
                                onChange={handleInputChange}
                                error={errorsActive && data.password.length < 8}
                                helperText={(errorsActive && data.password.length < 8) && 'La contraseña no es válida. Debe tener al menos 8 caracteres.'}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label='Repetir Contraseña'
                                size='small'
                                type='password'
                                name='repeatPassword'
                                value={data.repeatPassword}
                                onChange={handleInputChange}
                                error={errorsActive && data.repeatPassword.length < 8}
                                helperText={(errorsActive && data.repeatPassword.length < 8) && 'La contraseña no es válida. Debe tener al menos 8 caracteres.'}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box marginTop='0.75rem'>
                                <Alert severity='info'>
                                    ¡Personaliza tus ofertas! Regístrate y elige tu segmento para recibir promociones exclusivas basadas en tus intereses. ¡Aprovecha las mejores ofertas según tus preferencias!
                                </Alert>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
                            <FormControl required>
                                <FormLabel>Soy:</FormLabel>
                                <RadioGroup name='type' value={data.type} onChange={handleInputChange}>
                                    <Grid container columnSpacing={2}>
                                        <Grid item xs={6}>
                                            <FormControlLabel value={6} control={<Radio />} label="Constructora" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel value={7} control={<Radio />} label="Ingeniera/o" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel value={8} control={<Radio />} label="Arquitecta/o" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel value={9} control={<Radio />} label="Constratista" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel value={10} control={<Radio />} label="Pintora/o" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel value={11} control={<Radio />} label="Aplicadora/o" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel value={12} control={<Radio />} label="Carpintera/o" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel value={13} control={<Radio />} label="Herrera/o" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel value={14} control={<Radio />} label="Ferretería" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel value={15} control={<Radio />} label="Carrocera/o" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel value={16} control={<Radio />} label="Iniciativa privada" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel value={17} control={<Radio />} label="Iniciativa publica" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel value={18} control={<Radio />} label="Público en general" />
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox size='small' name='isSubscribed' value={data.isSubscribed} onChange={handleCheckChange} />}
                                label='Quiero recibir correos informativos sobre eventos, ofertas y más detalles.'
                            />
                        </Grid>
                        {
                            errorsActive && (data.password !== data.repeatPassword)
                                ? <Grid item xs={12}>
                                    <Alert severity='error'>
                                        Las contraseñas no coinciden.
                                    </Alert>
                                </Grid>
                                : null
                        }
                        {
                            error
                                ? <Grid item xs={12} marginBottom='1rem'>
                                    <Alert severity="error">
                                        Ha ocurrido un error al intentar registrarte, por favor asegúrate que los datos sean correctos.
                                    </Alert>
                                </Grid>
                                : null
                        }
                        <Grid item xs={12}>
                            <Box marginTop='1.5rem' sx={{ opacity: 0.45 }}>
                                <Typography variant='body2' align='center'>
                                    Al registrarte, aceptas nuestras Condiciones, la Política de privacidad y la Política de cookies.{' '}
                                    <Link to='/politicas' >
                                        Más información.
                                    </Link>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant='contained' type='submit' disabled={loading} fullWidth>
                                {
                                    loading
                                        ? <CircularProgress size='1.25rem' color='inherit' sx={{ margin: '.25rem' }} />
                                        : 'REGISTRARTE'
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Box>
    );
}

export default RegisterForm;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookie from 'js-cookie';

import { AppThunk } from '../store';
import { LoginFormData, PostSession, RegistrationFormData, User } from '../../interfaces';
import { finishLoading, startLoading } from './ui';
import { mainApi } from '../../api';

interface SessionState {
    user: User | null | undefined;
    municipality: string | null;
    zipcode: string | null;
}
    
const initialState: SessionState = {
    user: undefined,
    municipality: null,
    zipcode: null,
};

const sessionSlice = createSlice({
    name: 'Session',
    initialState,
    reducers: {
        setUser(state, { payload }: PayloadAction<User | null | undefined>) {
            state.user = payload;
        },
        setMunicipality(state, { payload }: PayloadAction<string | null>) {
            state.municipality = payload;
        },
        setZipcode(state, { payload }: PayloadAction<string | null>) {
            state.zipcode = payload;
        }
    },
});

export const {
    setUser,
    setMunicipality,
    setZipcode,
} = sessionSlice.actions;

export const verifySession = (): AppThunk => {
    return async (dispatch) => {
        try {
            const token = Cookie.get('c-token');
            if (!token) {
                return dispatch(setUser(null))
            }

            const { data } = await mainApi.post<PostSession>('/auth/verify-customer',);

            dispatch(setUser(data.customer));
            Cookie.set('c-token', data.token);
        } catch (error) {
            // console.log('verifySession', error);
            Cookie.remove('c-token');
            dispatch(setUser(null));
        }
    }
}

export const logIn = (formData: LoginFormData, onError?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.post<PostSession>('/auth/login-customer', formData);

            dispatch(setUser(data.customer));
            Cookie.set('c-token', data.token);
            dispatch(finishLoading());
        } catch (error) {
            // console.log('logIn', error);
            dispatch(finishLoading());
            if (onError) onError();
        }
    }
}

export const logOut = (): AppThunk => {
    return async (dispatch) => {
        try {
            Cookie.remove('c-token');
            dispatch(setUser(null));
        } catch (error) {
            // console.log('logOut', error);
        }
    }
}

export const registerCustomer = (formData: RegistrationFormData, onError?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.post<PostSession>('/customers/register', {
                ...formData,
                isSubscribed: formData.isSubscribed ? 1 : 0,
            });

            dispatch(setUser(data.customer));
            Cookie.set('c-token', data.token);
            dispatch(finishLoading());
        } catch (error) {
            // console.log('registerCustomer', error);
            dispatch(finishLoading());
            if (onError) onError();
        }
    }
}

export default sessionSlice.reducer;
import { ChangeEvent, useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';

const useForm = <T extends Object>(initialState: T) => {
    const [formData, setFormData] = useState<T>(initialState);

    const handleInputChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [name]: value });
    }

    const handleSelectChange = ({ target: { name, value } }: SelectChangeEvent) => {
        setFormData({ ...formData, [name]: value });
    }

    const handlePhoneInputChange = (e: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (typeof e === 'string')
            setFormData({ ...formData, phoneNumber: e });
        else
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleCheckChange = ({ target: { name, checked } }: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [name]: checked });
    }

    const reset = (data: T = initialState) => {
        setFormData(data);
    }

    return {
        data: formData,
        handleInputChange,
        handleSelectChange,
        handlePhoneInputChange,
        handleCheckChange,
        reset,
    };
}

export default useForm;
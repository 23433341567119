import { FC, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { initMercadoPago } from '@mercadopago/sdk-react';

import {
  Account,
  AccountAddresses,
  AccountDeactivation,
  AccountSettings,
  Auth,
  Cart,
  Checkout,
  Home,
  Inspiration,
  Order,
  OrderInvoice,
  Orders,
  PasswordRecovery,
  PasswordReset,
  PaymentFailure,
  PaymentSuccess,
  Product,
  ShopInformation,
  Store,
} from './pages';
import { PrivateRoute, PublicRoute } from './routes';
import { LocationModal, ShopLayout } from './components';
import { useAppDispatch, useSessionManagement } from './hooks';
import { syncShoppingCart } from './redux/slices/cart';

interface Props {
}

initMercadoPago('APP_USR-8a3ddb39-80b6-4df4-a356-cf264dc35ac8');

const App: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { checkSessionData } = useSessionManagement();

  useEffect(() => {
    checkSessionData();
  }, [checkSessionData]);

  useEffect(() => {
    dispatch(syncShoppingCart());
  }, []); 

  return (
    <>
      <LocationModal />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<ShopLayout />}>
            <Route index element={<Home />} />
            <Route path='/tienda' element={<Store />} />
            <Route path='/inspiracion' element={<Inspiration />} />
            <Route path='/carrito' element={<Cart />} />
            <Route path='/productos/:slug' element={<Product />} />
            <Route path='/informacion' element={<ShopInformation />} />
            <Route element={<PrivateRoute />}>
              <Route path='/cuenta' element={<Account />} />
              <Route path='/cuenta/direcciones' element={<AccountAddresses />} />
              <Route path='/cuenta/ajustes' element={<AccountSettings />} />
              <Route path='/cuenta/eliminacion' element={<AccountDeactivation />} />
              <Route path='/pago' element={<Checkout />} />
              <Route path='/pago/exito' element={<PaymentSuccess />} />
              <Route path='/pago/error' element={<PaymentFailure />} />
              <Route path='/cuenta/pedidos' element={<Orders />} />
              <Route path='/cuenta/pedidos/:idOrder' element={<Order />} />
              <Route path='/cuenta/pedidos/:idOrder/factura' element={<OrderInvoice />} />
            </Route>
            <Route element={<PublicRoute />}>
              <Route path='/auth' element={<Auth />} />
              <Route path='/cuenta/contrasena/recuperar' element={<PasswordRecovery />} />
              <Route path='/cuenta/contrasena/restablecer' element={<PasswordReset />} />
            </Route>
          </Route>
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
import { createTheme } from "@mui/material";

export const colors = {
    primary: '#313c51',
    secondary: '#F6C708',
    white: '#f5f5f6',
    black: '#121212',
    red: '#c0392b',
    blue: '#2980b9',
    footerBg: '#333333',
    footer: '#f5f5f5',
    cardBgAlt: '#f5f6f5',
};

const AppTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: colors.primary,
        },
        secondary: {
            main: colors.secondary,
        },
        error: {
            main: colors.red,
        }
    }
});

export default AppTheme;
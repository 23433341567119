import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetDetailedProduct, ProductDetailed } from '../../interfaces';
import { AppThunk } from '../store';
import { finishLoading, startLoading } from './ui';
import { mainApi } from '../../api';

interface ProductState {
    product: ProductDetailed | null | undefined
}

const initialState: ProductState = {
    product: undefined,
};

const productSlice = createSlice({
    name: 'Product',
    initialState,
    reducers: {
        setProduct(state, { payload }) {
            state.product = payload;
        }
    },
});

export const {
    setProduct,
} = productSlice.actions;

export const getProductData = (slug: string, onProductNotFound?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetDetailedProduct>(`/ecommerce/products/${slug}`);

            dispatch(setProduct(data.product));
            dispatch(finishLoading());
        } catch (error) {
            // console.log('getProductData', error);
            dispatch(finishLoading());
            dispatch(setProduct(null));
            if (onProductNotFound) onProductNotFound();
        }
    }
}

export default productSlice.reducer;
import { FC, FormEvent, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Box from '@mui/material/Box';

interface Props {
    onChangeText: (string: string) => void;
}

const ColorSearchBar: FC<Props> = ({ onChangeText }) => {
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (search.trim().length <= 0) {
            onChangeText('');
        }
    }, [search]);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onChangeText(search);
    }

    return (
        <Box component='form' onSubmit={handleSubmit}>
            <TextField
                label='Buscar por nombre o código'
                size='small'
                value={search}
                onChange={({ target }) => setSearch(target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            {
                                search.length > 0
                                    ? <IconButton size='small' type='button' onClick={() => setSearch('')}>
                                        <CloseIcon />
                                    </IconButton>
                                    : null
                            }
                            <IconButton size='small' type='submit' onClick={() => onChangeText(search)}>
                                <SearchOutlinedIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                fullWidth
            />
        </Box>
    );
}

export default ColorSearchBar;
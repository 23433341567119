import { FC } from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

interface Props {
    open: boolean;
    onClose: VoidFunction;
    message: string;
    type?: AlertColor;
    duration?: number;
    vertical?: 'top' | 'bottom';
    horizontal?: 'right' | 'center' | 'left';
}

const Notification: FC<Props> = ({ open, onClose, message, type = 'info', duration = 5000, vertical = 'top', horizontal = 'right' }) => {
    return (
        <Snackbar open={open} autoHideDuration={duration} onClose={onClose} anchorOrigin={{ vertical, horizontal }}>
            <Alert onClose={onClose} severity={type} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
}

export default Notification;
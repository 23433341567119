import { FC, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import { colors } from '../../themes';
import { useAppSelector } from '../../hooks';

interface Props {
}

const ShoppingCartButton: FC<Props> = () => {
    const { cart } = useAppSelector(state => state.cart);

    return (
        <Tooltip title='Carrito'>
            <Link to='/carrito' style={{ color: colors.white }}>
                <IconButton color='inherit'>
                    <Badge badgeContent={cart.length} color='error'>
                        <ShoppingCartIcon />
                    </Badge>
                </IconButton>
            </Link>
        </Tooltip>
    );
}

export default ShoppingCartButton;
import { FC, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { useBreakpoints } from '../../hooks';
import { SearchBar } from '../ui';
import BarLogos from './BarLogos';
import RightIcon from './RightIcon';
import MobileMenuButton from './MobileMenuButton';
import ShoppingCartButton from './ShoppingCartButton';
import MainPagesNavigation from './MainPagesNavigation';
import MobileNavigationDrawer from './MobileNavigationDrawer';
import { useNavigate } from 'react-router-dom';

interface Props {
}

const NavigationBar: FC<Props> = () => {
    const [drawerActive, setDrawerActive] = useState(false);
    const navigate = useNavigate();

    const { isDesktop } = useBreakpoints();

    const toggleDrawer = () => {
        setDrawerActive(value => !value);
    }

    return (
        <>
            <AppBar position='sticky' sx={{ zIndex: (t) => t.zIndex.drawer + 1, }}>
                <Toolbar>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        {
                            isDesktop
                                ? null
                                : <MobileMenuButton onClick={toggleDrawer} />
                        }
                        <Grid item>
                            <Box display='flex' flexDirection='row' >
                                <BarLogos />
                                {
                                    isDesktop
                                        ? <MainPagesNavigation />
                                        : null
                                }
                            </Box>
                        </Grid>
                        <Grid item display='flex' flexDirection='row' alignItems='center'>
                            {
                                isDesktop
                                    ? <SearchBar onSubmit={(search) => navigate(`/tienda?s=${search}`)} />
                                    : null
                            }
                            <ShoppingCartButton />
                            {
                                isDesktop
                                    ? <RightIcon title='Mi Cuenta' path='/cuenta' icon={<AccountCircleIcon />} />
                                    : null
                            }
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {
                isDesktop
                    ? null
                    : <MobileNavigationDrawer open={drawerActive} onClose={toggleDrawer} />
            }
        </>
    );
}

export default NavigationBar;

interface NavigationBarLogo {
    title: string;
    pngPath: string;
    avifPath?: string;
    webpPath?: string;
    mobileWidth: string;
    desktopWidth: string;
}

interface NavigationPage {
    title: string;
    path: string;
}

const useBarsData = () => {

    const navBarLogos: NavigationBarLogo[] = [
        {
            title: 'Logo Pinturas Osel',
            pngPath: '/osel-white.png',
            avifPath: '/osel-white.avif',
            webpPath: '/osel-white.webp',
            mobileWidth: '4.5rem',
            desktopWidth: '5rem',
        },
        {
            title: 'Logo Pinturas Famosas',
            pngPath: '/famosas.png',
            avifPath: '/famosas.avif',
            webpPath: '/famosas.webp',
            mobileWidth: '2.5rem',
            desktopWidth: '3rem',
        },
    ];

    const navBarPages: NavigationPage[] = [
        {
            title: 'Inicio',
            path: '/',
        },
        {
            title: 'Productos',
            path: '/tienda',
        },
        {
            title: 'Inspiración',
            path: '/inspiracion',
        },
    ];

    const navBarMobilePages: NavigationPage[] = [
        ...navBarPages,
        {
            title: 'Mi Cuenta',
            path: '/cuenta'
        },
    ];

    const footerInfoPages: NavigationPage[] = [
        {
            title: 'Política de Privacidad',
            path: '/informacion#privacidad',
        },
        {
            title: 'Política de Cancelación',
            path: '/informacion#cancelacion',
        },
        {
            title: 'Política de Envío',
            path: '/informacion#envio',
        },
        {
            title: 'Términos y Condiciones',
            path: '/informacion#terminos',
        },
    ];

    const copyright = '© Copyright 2023 - Pinturas Famosas';

    return {
        navBarLogos,
        navBarPages,
        navBarMobilePages,
        footerInfoPages,
        copyright,
    };
}

export default useBarsData;
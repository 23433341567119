export const parseToSlug = (string: string) => {
    return string.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-');
}

export const parseToPrice = (price: number) => {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(price);
}

export const parseToMunicipality = (municipality: number) => {
    switch (municipality) {
        case 1:
            return 'La Paz';
        case 2:
            return 'Los Cabos';
        case 3: 
            return 'Comondú';
        case 4:
            return 'Loreto';
        case 5:
            return 'Mulegé';
        default:
            return 'N/A';
    }
}
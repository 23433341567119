import { FC, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useAppDispatch, useAppSelector, useProductsProvider } from '../hooks';
import { getStoreData, getStoreProducts, resetSelections } from '../redux/slices/store';
import { ShopPageLayout, StorePagination, StoreProductCard, StoreSideBar, StoreTopBar } from '../components';
import { CircularProgress } from '@mui/material';

interface Props {

}

const Store: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { loading } = useAppSelector(state => state.ui);
    const { products } = useProductsProvider();

    const { page, selectedStore, selectedCategory, selectedLine } = useAppSelector(state => state.store);

    useEffect(() => {
        dispatch(getStoreData());
        dispatch(getStoreProducts(selectedStore, selectedCategory, selectedLine));
        return () => {
            dispatch(resetSelections());
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);
    
    return (
        <ShopPageLayout paths={[{ name: 'Tienda', path: '/tienda' }]} name='Catálogo de Productos'>
            <Grid container spacing='1rem' paddingY='1rem'>
                <Grid item xs={12} md={3}>
                    <StoreSideBar />
                </Grid>
                <Grid item xs={12} md={9}>
                    <StoreTopBar />
                    <Grid container marginTop='0.5rem' spacing='1rem'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center'>
                                {
                                    loading
                                        ? <CircularProgress sx={{ marginY: '1rem' }} />
                                        : products.length > 0
                                            ? <Grid container spacing={2}>
                                                {
                                                    products.map((product) =>
                                                        <Grid item key={product.slug} xs={12} sm={6} md={4}>
                                                            <StoreProductCard data={product} />
                                                        </Grid>
                                                    )
                                                }
                                            </Grid>
                                            : <Typography textTransform='uppercase'>
                                                No hay productos disponibles.
                                            </Typography>
                                }
                            </Box>
                        </Grid>
                        <StorePagination />
                    </Grid>
                </Grid>
            </Grid>
        </ShopPageLayout>
    );
}

export default Store;
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';

import { deactivateAccount, requestAccountDeactivation } from '../redux/slices/account';
import { useAppDispatch, useAppSelector, useDesignConstants } from '../hooks';
import { Notification } from '../components';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
}

const AccountDeactivation: FC<Props> = () => {
    const [canSendAgain, setCanSendAgain] = useState(false);
    const [showError, setShowError] = useState(false);
    const [token, setToken] = useState('');
    const canSendToken = useMemo(() => {
        return token.trim().length > 0;
    }, [token]);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const { loading } = useAppSelector(state => state.ui);
    const { PaperContent } = useDesignConstants();

    const sendToken = () => {
        dispatch(requestAccountDeactivation());
    }

    const goBackToSettings = () => {
        navigate('/cuenta/ajustes');
    }

    const submitAccountDeactivation = () => {
        dispatch(deactivateAccount(token, () => {
            navigate('/');
        }, () => {
            setShowError(true);
        }));
    }

    useEffect(() => {
        sendToken();
    }, []);

    useEffect(() => {
        if (loading === false) {
            setTimeout(() => {
                setCanSendAgain(true);
            }, 15000);
        } else {
            setCanSendAgain(false);
        }
    }, [loading])



    return (
        <Box width='100%' marginTop='2.5rem' display='flex' justifyContent='center' alignItems='center'>
            <Notification
                open={showError}
                onClose={() => setShowError(false)}
                message='Ha ocurrido un error al intentar completar el procedimiento, por favor intente más tarde.'
                type='error'
            />
            <Paper elevation={4} sx={PaperContent}>
                <Box padding='1rem'>
                    <Button variant='text' onClick={goBackToSettings}>
                        <KeyboardBackspaceOutlinedIcon fontSize='small' sx={{ marginRight: '0.5rem' }} />
                        Regresar a detalles
                    </Button>
                    <Typography variant='h5' component='h1' align='center'>
                        Eliminación de Cuenta
                    </Typography>
                    <Box>
                        <Typography textAlign='justify' marginBottom='0.5rem'>
                            Para continuar con la eliminación de tu cuenta, ingresa el código que envíamos a tu dirección de correo electrónico:
                        </Typography>
                        <Box width={{ xs: '90%', sm: '80%' }} margin='0 auto'>
                            <TextField
                                size='small'
                                label='Código de Autenticación'
                                placeholder='Ingrese código de autenticación'
                                value={token}
                                onChange={e => setToken(e.target.value)}
                                autoComplete='off'
                                fullWidth
                            />
                            <Box marginTop='0.5rem' display='flex' flexDirection={{ xs: 'column', sm: 'row' }} justifyContent='space-between'>
                                <Box width={{ xs: '100%', sm: '45%' }} marginBottom='0.5rem'>
                                    <Button variant='outlined' disabled={!canSendAgain} onClick={sendToken} fullWidth>
                                        Enviar código de nuevo
                                    </Button>
                                </Box>
                                <Box width={{ xs: '100%', sm: '45%' }} marginBottom='0.5rem'>
                                    <Button variant='contained' disabled={!canSendToken || loading} onClick={submitAccountDeactivation} fullWidth>
                                        {
                                            loading 
                                                ? <CircularProgress size='1.5rem' />
                                                : 'Aceptar'
                                        }
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box marginY='1rem'>
                        <Alert severity='info'>
                            Al eliminar tu cuenta no podrás volver a acceder a tus datos y pedidos, sin embargo podrás volver a usar tu dirección de correo electrónico en un futuro para crear una nueva cuenta.
                        </Alert>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}

export default AccountDeactivation;
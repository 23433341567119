import { FC, useRef } from 'react';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useAppSelector,  useLoginForm, useOnView } from '../../hooks';

interface Props {
}

const LoginForm: FC<Props> = () => {
    const ref = useRef<HTMLElement>(null);

    const isVisible = useOnView(ref);
    const { loading } = useAppSelector(state => state.ui);

    const { data, error, handleInputChange, handleSubmit } = useLoginForm();

    return (
        <Box ref={ref} marginTop='1rem'>
            <Fade in={isVisible} timeout={500}>
                <Box component='form' onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                label='Correo'
                                size='small'
                                name='email'
                                type='email'
                                value={data.email}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label='Contraseña'
                                size='small'
                                name='password'
                                type='password'
                                value={data.password}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        {
                            error
                                ? <Grid item xs={12} marginBottom='1rem'>
                                    <Alert severity="error">
                                        Ha ocurrido un error al intentar iniciar sesión, por favor asegúrate que los datos sean correctos.
                                    </Alert>
                                </Grid>
                                : null
                        }
                        <Grid item xs={12}>
                            <Box marginTop='1.5rem'>
                                <Button variant='contained' type='submit' disabled={loading} fullWidth>
                                    {
                                        loading
                                            ? <CircularProgress size='1.25rem' color='inherit' sx={{ margin: '.25rem' }} />
                                            : 'INGRESAR'
                                    }
                                </Button>
                                <Typography variant='body2' align='center' marginTop='1rem' sx={{ opacity: 0.8 }}>
                                    <Link to='/cuenta/contrasena/recuperar'>
                                        ¿Olvidaste tu contraseña?
                                    </Link>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Box>
    );
}

export default LoginForm;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Banner, GetHomeData, HomeStoreData } from '../../interfaces';
import { AppThunk } from '../store';
import { finishLoading, startLoading } from './ui';
import { mainApi } from '../../api';

interface HomeState {
    stores: HomeStoreData[];
    banners: Banner[];
}

const initialState: HomeState = {
    stores: [],
    banners: [],
};

const homeSlice = createSlice({
    name: 'Home',
    initialState,
    reducers: {
        setStores(state, { payload }: PayloadAction<HomeStoreData[]>) {
            state.stores = payload;
        },
        setBanners(state, { payload }) {
            state.banners = payload;
        }
    },
});

export const {
    setStores,
    setBanners,
} = homeSlice.actions;

export const getHomeData = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetHomeData>('/ecommerce/home');

            dispatch(setStores(data.stores));
            dispatch(setBanners(data.banners));
            dispatch(finishLoading());
        } catch (error) {
            // console.log('getHomeData', error);
            dispatch(finishLoading());
            dispatch(setStores([]));
        }
    }
}

export default homeSlice.reducer;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { mainApi } from '../../api';
import { AppThunk } from '../store';
import { GetInvoice, GetRfc, Invoice, InvoiceFormData, InvoiceRequestData } from '../../interfaces';
import { finishLoading, startLoading } from './ui';

interface InvoicingState {
    selectedInvoice: Invoice | null;
}

const initialState: InvoicingState = {
    selectedInvoice: null,
};

const invoicingSlice = createSlice({
    name: 'Invoicing',
    initialState,
    reducers: {
        setSelectedInvoice(state, { payload }: PayloadAction<Invoice | null>) {
            state.selectedInvoice = payload;
        }
    },
});

export const {
    setSelectedInvoice,
} = invoicingSlice.actions;


export const requestOrderInvoice = (form: InvoiceFormData, onSucces?: VoidFunction, onError?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const {
                idOrder, rfc, socialReason, taxRegime, cfdiUse,
                address, interiorNumber, exteriorNumber,
                zipcode, neighbourhood, city, locality, state, country,
            } = form;

            let data: InvoiceRequestData  = {
                rfc, socialReason, taxRegime, cfdiUse,
                address, zipcode, neighbourhood, city, locality, state, country,
            };

            if (form.exteriorNumber.trim().length > 0) {
                data.exteriorNumber = form.exteriorNumber;
            }

            if (form.interiorNumber.trim().length > 0) {
                data.interiorNumber = form.interiorNumber;
            }

            // console.log('test -> ', data);

            await mainApi.post(`/invoices/request/${idOrder}`, data);

            dispatch(finishLoading());
            if (onSucces) onSucces();
        } catch (error) {
            // console.log('requestOrderInvoice', error);
            dispatch(finishLoading());
            if (onError) onError();
        }
    }
}

export const fetchInvoice = (idOrder: string, onInvoiceNotFound?: VoidFunction): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(startLoading());

            const { data } = await mainApi.get<GetInvoice>(`/ecommerce/${idOrder}/invoice`);

            dispatch(setSelectedInvoice(data.invoice));
            dispatch(finishLoading());
        } catch (error) {
            // console.log('fetchOrder', error);
            dispatch(setSelectedInvoice(null));
            dispatch(finishLoading());
            if (onInvoiceNotFound) onInvoiceNotFound();
        }
    }
}

export default invoicingSlice.reducer;
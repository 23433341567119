export const cfdiUses = [
    'G01 - Adquisición de mercancías.',
    'G02 - Devoluciones, descuentos o bonificaciones.',
    'G03 - Gastos en general.',
    'P01 - Por definir.',
    'I01 - Construcciones.',
    'I02 - Mobiliario y equipo de oficina por inversiones.',
    'I03 - Equipo de transporte.',
    'I04 - Equipo de cómputo y accesorios.',
    'I05 - Dados, troqueles, moldes, matrices y herramental.',
    'I06 - Comunicaciones telefónicas.',
    'I07 - Comunicaciones satelitales.',
    'I08 - Otra maquinaria y equipo.',
    'D01 - Honorarios médicos, dentales y gastos hospitalarios.',
    'D02 - Gastos médicos por incapacidad o discapacidad.',
    'D03 - Gastos funerales.',
    'D04 - Donativos.',
    'D05 - Intereses reales efectivamente pagados por créditos hipotecarios.',
    'D06 - Aportaciones voluntarias al SAR.',
    'D07 - Primas por seguros de gastos médicos.',
    'D08 - Gastos de transportación escolar obligatoria.',
    'D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.',
    'D10 - Pagos por servicios educativos(colegiaturas).',
    'S01 - Sin efectos fiscales.',
    'I05 - Por definir.',
];
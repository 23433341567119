import { FC, FormEvent } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { useForm } from '../../hooks';
import { Validator } from '../../utils';
import { LocationFormData } from '../../interfaces';

interface Props {
    onSubmit: (data: LocationFormData) => void;
}

const LocationForm: FC<Props> = ({ onSubmit }) => {
    const { data, handleInputChange, handleSelectChange, reset } = useForm<LocationFormData>({
        municipality: '',
        zipcode: '',
    });
    const { municipality, zipcode } = data;

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        onSubmit(data);
        reset();
    }
    
    return (
        <Box component='form' marginTop='1rem' onSubmit={handleSubmit} autoComplete='off'>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth size='small'>
                        <InputLabel>Municipio</InputLabel>
                        <Select label="Municipio" name='municipality' value={municipality} onChange={handleSelectChange} required>
                            <MenuItem value='1'>La Paz</MenuItem>
                            <MenuItem value='2'>Los Cabos</MenuItem>
                            <MenuItem value='3'>Comondú</MenuItem>
                            <MenuItem value='4'>Loreto</MenuItem>
                            <MenuItem value='5'>Mulegé</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label='Código Postal'
                        variant="outlined"
                        size='small'
                        name='zipcode'
                        value={zipcode}
                        onChange={(e) => Validator.isNumber(e.target.value) && handleInputChange(e)}
                        required
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Box display='flex' justifyContent='end' marginTop='1rem'>
                <Button variant='contained' type='submit'>
                    ACEPTAR
                </Button>
            </Box>
        </Box>
    );
}

export default LocationForm;
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import SuccessSVG from '../assets/success.svg';
import { useAppDispatch, useAppSelector, useDesignConstants } from '../hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { confirmPayment } from '../redux/slices/checkout';

interface Props {
}

const PaymentSuccess: FC<Props> = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [isChecking, setChecking] = useState(true);
    const [orderId, setOrderId] = useState('')

    const dispatch = useAppDispatch();
    const { PaperContent } = useDesignConstants();

    const searchParamsAreValid = () => {
        if (searchParams.get('status') !== 'approved')
            return false;
        if (!searchParams.get('payment_type'))
            return false;
        if (!searchParams.get('payment_id'))
            return false;
        if (!searchParams.get('preference_id'))
            return false;
        return true;
    }

    useEffect(() => {
        if (searchParamsAreValid()) {
            dispatch(confirmPayment({
                preferenceId: searchParams.get('preference_id')!,
                paymentMethod: searchParams.get('payment_type')!,
                paymentId: searchParams.get('payment_id')!,
            }, (orderId: string) => {
                setOrderId(orderId);
                setChecking(false);
            }, () => {
                navigate('/pago/error');
            }))
        } else {
            navigate('/');
        }
    }, []);

    if (isChecking) {
        return (
            <Box display='flex' justifyContent='center' marginTop='2.5rem'>
                <Paper elevation={4}>
                    <Box padding='2rem 5rem' display='flex' flexDirection='column' alignItems='center'>
                        <CircularProgress />
                        <Typography variant='h6' component='p' marginTop='1rem' textTransform='uppercase' fontWeight='400'>
                            Procesando datos de la compra
                        </Typography>
                    </Box>
                </Paper>
            </Box>
        );
    }

    return (
        <Box display='flex' justifyContent='center' marginTop='2.5rem'>
            <Paper sx={PaperContent} elevation={4}>
                <Box padding='2rem 5rem' display='flex' flexDirection='column' alignItems='center'>
                    <Box width={{ xs: '60%', sm: '30%' }} margin='0 auto'>
                        <img src={SuccessSVG} style={{ maxWidth: '100%' }} />
                    </Box>
                    <Typography variant='h6' component='p' marginY='1rem' fontWeight='400' textAlign='center'>
                        ¡Felicidades! Tu pedido ha sido registrado con éxito.
                    </Typography>
                    <Box margin='0 auto'>
                        <Link to={`/cuenta/pedidos/${orderId}`} style={{ marginRight: '0.5rem' }}>
                            <Button variant='contained'>
                                Ver Pedido
                            </Button>
                        </Link>
                        <Link to='/'>
                            <Button variant='outlined'>
                                Volver al Inicio
                            </Button>
                        </Link>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}

export default PaymentSuccess;
import { ChangeEvent, FC, useMemo, useState } from 'react';

import { AppModal } from '../ui';
import { SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../hooks';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import { requestCancellation } from '../../redux/slices/orders';

interface Props {
    idOrder: string;
    open: boolean;
    onClose: VoidFunction;
    onError?: VoidFunction;
    callback?: VoidFunction;
}

const container: SxProps = {

    width: { xs: '90%', sm: '80%', md: '60%', xl: '40%' }
}

const CancellationModal: FC<Props> = ({ idOrder, onError, callback, ...props }) => {
    const dispatch = useAppDispatch();
    const { loading } = useAppSelector(state => state.ui);

    const [motive, setMotive] = useState('');

    const shortId = useMemo(() => {
        return idOrder.split('-')[0].toUpperCase();
    }, [idOrder]);

    const handleMotiveChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        setMotive(target.value);
    }

    const submitCancellationRequest = () => {
        dispatch(requestCancellation({ idOrder, motive }, () => {
            props.onClose();
            if (callback) callback();
        }, () => {
            if (onError) onError();
        }));
    }

    return (
        <AppModal title={`¿Cancelar el Pedido #${shortId}?`} containerStyle={container} {...props}>
            <Box>
                <Alert severity='info'>
                    Al solicitar la cancelación se procesará la solicitud de cancelación y su respectiva devolución. La cancelación del pedido se reflejará una vez se haya completado el proceso de cancelación.
                </Alert>
                <Box marginTop='1rem'>
                    <Typography marginBottom='0.5rem'>
                        ¿Nos quieres contar que pasó?
                    </Typography>
                    <TextField
                        size='small'
                        label='Motivo (Opcional)'
                        rows={2}
                        value={motive}
                        onChange={handleMotiveChange}
                        multiline
                        fullWidth
                    />
                </Box>
                <Box marginTop='1rem' display='flex' justifyContent='end'>
                    <Box width={{ xs: '100%', sm: '35%' }}>
                        <Button variant='contained' onClick={submitCancellationRequest} fullWidth>
                            {
                                loading
                                    ? <CircularProgress size='1.5rem' />
                                    : 'Solicitar Cancelación'
                            }
                        </Button>
                    </Box>
                </Box>
            </Box>
        </AppModal>
    );
}

export default CancellationModal;
import { FC } from 'react';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';

interface Props {
}

const EmptyOrdersList: FC<Props> = () => {
    return (
        <Alert severity='warning'>
            Vaya, parece que aún no se ha realizado ningún pedido.
            Dale un vistazo a nuestros {' '}
            <Link to='/tienda' style={{ color: 'inherit', textDecoration: 'underline' }}>
                productos
            </Link>
            .
        </Alert>
    );
}

export default EmptyOrdersList;
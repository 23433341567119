import { FC } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { useDesignConstants } from '../hooks';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import { PasswordRecoveryForm } from '../components/passwordrecovery';

interface Props {
}

const PasswordRecovery: FC<Props> = () => {
    const { PaperContent } = useDesignConstants();

    return(
        <Box width='100%' marginTop='2.5rem' display='flex' justifyContent='center' alignItems='center'>
            <Paper elevation={4} sx={PaperContent}>
                <Box padding='1rem'>
                    <Typography variant='h5' component='h1' align='center'>
                        Recuperación de Contraseña
                    </Typography>
                    <Box marginY='1rem'>
                        <Alert severity='info'>
                            Para reestablecer tu contraseña, ingresa la dirección de correo electrónico enlazada a tu cuenta. Si los datos coinciden, recibirás un correo con más instrucciones. 
                        </Alert>
                    </Box>
                    <PasswordRecoveryForm />
                </Box>
            </Paper>
        </Box>
    );
}

export default PasswordRecovery;
import { FC } from 'react';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { useAppSelector } from '../../hooks';
import CheckoutProductCard from './CheckoutProductCard';

interface Props {
}

const CheckoutCart: FC<Props> = () => {
    const { cart } = useAppSelector(state => state.cart);

    return (
        <Slide direction='right' in={true} timeout={500}>
            <Box>
                <Paper elevation={3}>
                    <Box padding='1rem'>
                        <Box display='flex' alignItems='center' paddingBottom='0.25rem'>
                            <Typography marginRight='0.5rem'>
                                Carrito de Compra
                            </Typography>
                            <Chip size='small' label={cart.length} />
                        </Box>
                        <Divider />
                        <Box>
                            {
                                cart.length <= 0
                                    ? <Box marginTop='1rem'>
                                        <Alert severity='warning'>
                                            No hay productos en el carrito de compra.
                                        </Alert>
                                    </Box>
                                    : cart.map((product, index) =>
                                        <Box key={product.slug + index}>
                                            <CheckoutProductCard data={product} />
                                            <Divider />
                                        </Box>
                                    )
                            }
                        </Box>
                    </Box>
                </Paper>
                <Box marginTop='1rem' display='flex' justifyContent='end'>
                    <Paper elevation={3}>
                        <Link to='/tienda'>
                            <Button variant='outlined'>
                                <KeyboardBackspaceIcon fontSize='small' />
                                Volver a la Tienda
                            </Button>
                        </Link>
                    </Paper>
                </Box>
            </Box>
        </Slide>
    );
}

export default CheckoutCart;
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FC, FormEvent, useState } from 'react';
import { useAppDispatch, useAppSelector, useForm } from '../../hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { resetPassword } from '../../redux/slices/account';
import { Notification } from '../ui';

interface Props {
    name: string;
}

const PasswordResetForm: FC<Props> = ({ name }) => {
    const [hasError, setError] = useState(false);
    const [hasApiError, setApiError] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const dispatch = useAppDispatch();
    const { loading } = useAppSelector(state => state.ui);
    const { data, handleInputChange } = useForm({
        password: '',
        repeatPassword: '',
    });
    const { password, repeatPassword } = data;

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setError(false);

        if (password.trim().length < 8 || password !== repeatPassword)
            return setError(true);

        dispatch(resetPassword(searchParams.get('token') ?? '', password, () => {
            navigate('/auth?opt=login');
        }, () => {
            setApiError(true);
        }));

    }

    return (
        <Box>
            <Notification open={hasApiError} onClose={() => setApiError(false)} message='Ha ocurrido un error al intentar restablecer su contraseña.' />
            <Box marginTop='0.5rem'>
                <Typography>
                    Hola {name},
                </Typography>
                <Typography align='justify'>
                    Para completar tu proceso de restablecimiento de contraseña, por favor escribe una nueva contraseña segura y fácil de recordar.
                </Typography>
            </Box>
            <Box marginTop='1rem' component='form' onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            size='small'
                            label='Nueva Contraseña'
                            name='password'
                            type='password'
                            value={password}
                            onChange={handleInputChange}
                            error={hasError && password.length < 8}
                            helperText={(hasError && password.length < 8) && 'La contraseña no es válida. Debe tener al menos 8 caracteres.'}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            size='small'
                            label='Repetir Contraseña'
                            name='repeatPassword'
                            type='password'
                            value={repeatPassword}
                            onChange={handleInputChange}
                            error={hasError && repeatPassword.length < 8}
                            helperText={(hasError && repeatPassword.length < 8) && 'La contraseña no es válida. Debe tener al menos 8 caracteres.'}
                            fullWidth
                            required
                        />
                    </Grid>
                    {
                        hasError && password !== repeatPassword
                            ? <Grid item xs={12}>
                                <Box marginY='0.5rem'>
                                    <Alert severity='error'>
                                        Las contraseñas no coinciden.
                                    </Alert>
                                </Box>
                            </Grid>
                            : null
                    }
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='end'>
                            <Button variant='contained' type='submit' disabled={loading}>
                                Restablecer
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default PasswordResetForm;
import { FC } from 'react';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

interface Props {
}

const wpColor = '#25D366';

const WhatsAppButton: FC<Props> = () => {
    const buttonStyle = {
        position: 'fixed',
        bottom: '1rem',
        right: '1rem',
        backgroundColor: wpColor,
        '&:hover': {
            backgroundColor: wpColor,
        }
    }

    return (
        <Tooltip title='¿Tienes alguna duda para tu proyecto?'>
            <Fab sx={buttonStyle} aria-label="WhatsApp" href='https://bit.ly/3KBc2Mp' target='_blank' rel='noopener noreferrer'>
                <WhatsAppIcon sx={{ color: 'whitesmoke' }} />
            </Fab>
        </Tooltip>
    );

}

export default WhatsAppButton;
import Alert from '@mui/material/Alert';
import { FC } from 'react';

interface Props {
}

const EmptyOrdersList: FC<Props> = () => {
    return (
        <Alert severity='info'>
            No hemos encontrado ningún pedido que coincida con el criterio seleccionado. Recuerda que puedes buscar por nombre de producto, número de pedido o nombre del destinatario.
        </Alert>
    );
}

export default EmptyOrdersList;
import { FC, useMemo } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { useAppSelector } from '../../hooks';
import { Parser } from '../../utils';
import SummaryProductCard from './SummaryProductCard';

interface Props {
}

const OrderSummary: FC<Props> = () => {
    const { cart } = useAppSelector(state => state.cart);
    const { subtotal, promotion, shipping, total, step, isNewClient } = useAppSelector(state => state.checkout);

    const shippingFee = useMemo(() => {
        if (total > 3000 || shipping === 0) 
            return 'GRATIS';
        if (!shipping)
            return 'N/A';
        return Parser.parseToPrice(shipping)
    }, [total, shipping]);

    return (
        <Box>
            <Fade in={true} timeout={500}>
                <Paper elevation={3}>
                    <Box padding='1rem'>
                        <Typography>Resumen del Pedido</Typography>
                        <Divider />
                        {
                            step > 0
                                ? <Box marginY='0.5rem' paddingTop='0.5rem'>
                                    {
                                        cart.map(product =>
                                            <SummaryProductCard key={product.variant.key} data={product} />
                                        )
                                    }
                                </Box>
                                : null
                        }
                        <Divider />
                        <Box marginTop='1rem'>
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <Typography variant='body2'>Subtotal:</Typography>
                                <Typography>
                                    {Parser.parseToPrice(subtotal)}
                                </Typography>
                            </Box>
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <Typography variant='body2'>Envío:</Typography>
                                <Typography>
                                    {shippingFee}
                                </Typography>
                            </Box>
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <Typography variant='body2'>Promoción:</Typography>
                                <Typography>
                                    {promotion ? `-${promotion.discountPercentage}%` : 'N/A'}
                                </Typography>
                            </Box>
                            {
                                isNewClient
                                    ? <Box marginTop='0.5rem'>
                                        <Alert severity='success' sx={{ textAlign: 'justify' }}>
                                            Cuentas con un descuento del 10% adicional como bienvenida a la comunidad #SoyFamoso.
                                        </Alert>
                                    </Box>
                                    : null
                            }
                        </Box>
                    </Box>
                </Paper>
            </Fade>
        </Box>
    );
}

export default OrderSummary;
import { FC, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';

import { useAppDispatch, useAppSelector, useOnView } from '../../hooks';
import { setSelectedZone } from '../../redux/slices/inspiration';

interface Props {
}

const zones = ['Recámara', 'Baño', 'Cocina', 'Estudio', 'Sala'];

const ZoneSelector: FC<Props> = () => {
    const ref = useRef<HTMLElement>(null)
    
    const isVisible = useOnView(ref);
    const dispatch = useAppDispatch();
    const { selectedZone } = useAppSelector(state => state.inspiration);

    return (
        <Box ref={ref} marginTop={{ xs: '1rem', md: 0 }}>
            <Slide in={isVisible} timeout={500} direction='right'>
                <Grid container justifyContent='center'>
                    {
                        zones.map((zone, index) =>
                            <Grid item sx={{ color: 'gray' }} key={`zone_${index}`}>
                                <Button
                                    variant='text'
                                    color='inherit'
                                    sx={{
                                        margin: '0.5rem 0.75rem',
                                        backgroundColor: selectedZone === zone ? '#d9d9d9' : 'transparent',
                                        color: selectedZone === zone ? '#282828' : undefined,
                                    }}
                                    onClick={() => dispatch(setSelectedZone(zone))}
                                >
                                    {zone}
                                </Button>
                            </Grid>
                        )
                    }
                </Grid>
            </Slide>
        </Box>
    );
}

export default ZoneSelector;